import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import "@fontsource/montserrat";
import { Error404, Privacy } from "./components/CommonComponents";
import {
  MainComponent,
  OrderList,
  AddDriver,
  RouteList,
  AddPharmacy,
  AddFacilityTeam,
  DriverList,
  PharmacyList,
  UserList,
  BoroughList,
  ZoneList,
  RouteDetail,
  RouteUpdate,
  AddPharmacyEmployee,
  FacilityList,
  PharmacyOrderListCount,
  PharmacyReport,
  OrdersUpdate,
  EditPharmacy,
  EditDriver,
  EditFacilityTeam,
  EditPharmacyEmployee,
  TwilioCalls,
  TwilioSMSList,
  CallList,
} from "./components/MainComponents";
import Chat from "./components/ChatComponents/Chat";
import DriverScan from './components/MainComponents/DriverScan';
import PrivateRoutes from "./routes/PrivateRoutes";
import PublicRoutes from "./routes/PublicRoutes";
import {
  FullComponent,
  AddOrder,
  DetailOrder,
  EditOrder,
  RescheduleOrder
} from "./components/FullComponents";
import RouteReturnDetail from "./components/MainComponents/RouteDetail/RouteReturnDetail"
import {
  Login,
  AuthComponent,
  ForgetPassword,
  ResetPassword,
  SignaturePad
} from "./components/AuthComponents";
import {
  PharmacyOrderList,
  PharmPharmacyReport,
} from "./components/PharmacyComponents";
import RouteReturnUpdate from "./components/MainComponents/RouteUpdate/RouteReturnUpdate"

import CancelNotes from "./components/MainComponents/CancelNotes/CancelNotes";
import CustomerSupport from "./components/MainComponents/CustomerSupport/CustomerSupport";
import OrdersListing from "./components/FullComponents/OrdersListing/OrdersListing";
// import OrdersUpdate from "./components/MainComponents/OrdersUpdate/OrdersUpdate";
import TicketList from "./components/MainComponents/TicketList/TicketList";
import RouteStatus from "./components/MainComponents/RouteStatus/RouteStatus";
import PharmacyEmployeeList from "./components/MainComponents/PharmacyEmployeeList/PharmacyEmployeeList";
import Dashboard from "./components/FullComponents/Dashboard/Dashboard";
import DriverReport from "./components/MainComponents/DriverReport/DriverReport";
import RouteHereDetail from "./components/MainComponents/RouteDetail/RouteHereDetail";
import RouteReturnHereDetail from "./components/MainComponents/RouteDetail/RouteReturnHereDetail";
import RouteHereDetailMobile from "./components/MainComponents/RouteDetailMobile";
import RouteReturnHereDetailMobile from "./components/MainComponents/RouteReturnDetailMobile";
import PrivacyPolicy from "./components/CommonComponents/PrivacyPolicy/PrivacyPolicy";
import Terms from "./components/CommonComponents/TermsCondition/Terms";
import VerbalInteraction from "./components/CommonComponents/VerbalInteraction/VerbalInteraction";
import AddExpense from "./components/MainComponents/AddExpense";
import ExpenseList from "./components/MainComponents/ExpenseList";
import EditExpense from "./components/MainComponents/EditExpense";

function App() {
  const userType = localStorage.getItem("userType");
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("userType") === "PharmacyEmployee" ||
    localStorage.getItem("userType") === "PharmacyOwner"
  );

return (
  <BrowserRouter>
    <Routes>
      <Route path="/login">
        <Route path="/login" element={<Navigate to="/" />} />
      </Route>
      {isAuthenticated ? (
        <Route element={<PrivateRoutes />}>
          <Route element={<MainComponent />}>
            <Route
              path="/pharmacy/:pid/list/order"
              element={<PharmacyOrderList />}
            />
            <Route
              path="/pharmacy/:pid/list/order/:Urgent"
              element={<PharmacyOrderList />}
            />
            <Route
              path="/pharmacy/:pid/list/order/Status_Id/:Status_Id"
              element={<PharmacyOrderList />}
            />
            <Route
              path="/pharmacy/:pid/pharmacy/report"
              element={<PharmPharmacyReport />}
            />
            <Route
              path="/pharmacy/:pid/ticketlisting"
              element={<TicketList />}
            />
            <Route
              path="/pharmacy/:pid/cancelnotes"
              element={<CancelNotes />}
            />
            <Route
              path="/pharmacy/:pid/ordersupdate"
              element={<OrdersUpdate />}
            />

            <Route path="/pharmacy/:pid/add/order" element={<AddOrder />} />
            <Route
              path="/pharmacy/:pid/add/employee"
              element={<AddPharmacyEmployee />}
            />
            <Route
              path="/pharmacy/:pid/list/employee"
              element={<PharmacyEmployeeList />}
            />
            <Route
              path="/pharmacy/:pid/edit/employee/:eid"
              element={<EditPharmacyEmployee />}
            />
          </Route>
          <Route element={<FullComponent />}>
            <Route
              path="/pharmacy/:pid/detail/order/:oid"
              element={<DetailOrder />}
            />
            <Route
              path="/pharmacy/:pid/update/order/:oid"
              element={<EditOrder />}
            />
          </Route>
        </Route>
      ) : (
        <Route element={<PrivateRoutes />}>
          <Route element={<MainComponent />}>
            <Route path="/list/order" element={<OrderList />} />
            <Route path="/list/order/:Urgent" element={<OrderList />} />
            <Route
              path="/list/order/Status_Id/:Status_Id"
              element={<OrderList />}
            />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/list/facility" element={<FacilityList />} />
            <Route
              path="/pharmacy/order/count"
              element={<PharmacyOrderListCount />}
            />

            <Route path="/pharmacy/report" element={<PharmacyReport />} />
            <Route path="/pharmacy/driverscan" element={<DriverScan />} />
            <Route path="/pharmacy/driverreport" element={<DriverReport />} />


            <Route path="/add/driver" element={<AddDriver />} />
            <Route path="/add/expense" element={<AddExpense />} />
            <Route path="/edit/driver/:did" element={<EditDriver />} />
            <Route path="/edit/expense/:eid" element={<EditExpense />} />
            <Route path="/list/route" element={<RouteList />} />
            <Route path="/list/routes-status" element={<RouteStatus />} />
            <Route path="/list/users" element={<UserList />} />
            <Route path="/add/facility-team" element={<AddFacilityTeam />} />
            <Route
              path="/edit/facility-team/:fid"
              element={<EditFacilityTeam />}
            />
            <Route path="/list/cities" element={<BoroughList />} />
            <Route path="/list/zone/:zid" element={<ZoneList />} />
            <Route path="/list/driver" element={<DriverList />} />
            <Route path="/list/expense" element={<ExpenseList />} />
            <Route path="/list/pharmacy" element={<PharmacyList />} />
            <Route path="/add/pharmacy" element={<AddPharmacy />} />
            <Route path="/edit/pharmacy/:pid" element={<EditPharmacy />} />

            <Route path="/detailHere/route/:rid" element={<RouteHereDetail />} />
            <Route path="/detailreturnHere/route/:rid" element={<RouteReturnHereDetail />} />
            <Route path="/detail/route/:rid" element={<RouteDetail />} />
            <Route path="/detailreturn/route/:rid" element={<RouteReturnDetail />} />
            <Route
              path="/detail/route/:rid/:copay"
              element={<RouteDetail />}
            />
            <Route path="/update/route/:rid" element={<RouteUpdate />} />
            <Route path="/updatereturn/route/:rid" element={<RouteReturnUpdate />} />
            <Route path="/cancelnotes" element={<CancelNotes />} />
            <Route path="/customersupport" element={<CustomerSupport />} />
            <Route path="/ticketlisting" element={<TicketList />} />
            <Route path="/twilio/sms" element={<TwilioSMSList />} />
            <Route path="/call/list" element={<CallList />} />
            <Route path="/orderslisting" element={<OrdersListing />} />
            <Route path="/ordersupdate" element={<OrdersUpdate />} />

            <Route
              path="/voice/calls/"
              element={<TwilioCalls />}
            />
          </Route>
          <Route element={<FullComponent />}>
            <Route path="/detail/order/:oid" element={<DetailOrder />} />
            <Route path="/update/order/:oid" element={<EditOrder />} />
            <Route
              path="/reschedule/order/:oid"
              element={<RescheduleOrder />}
            />
          </Route>
        </Route>
      )}
      <Route element={<AuthComponent />}>
        <Route
          path="/"
          element={
            <Login
              userType={userType}
              isAuthenticated={isAuthenticated}
              setIsAuthenticated={setIsAuthenticated}
            />
          }
        />

        <Route path="/forgetPassword" element={<ForgetPassword />} />
        {/* <Route path="resetPassword" element={<ResetPassword />} />
          <Route path="reset/:id/:resetToken" element={<ResetPasswordConfirm />} /> */}
      </Route>
      <Route element={<PublicRoutes />}>
        <Route element={<AuthComponent />}>
          <Route path="/" element={<Login />} />
          <Route path="/forgetPassword" element={<ForgetPassword />} />
          {/* <Route path="resetPassword" element={<ResetPassword />} /> */}
          <Route path="/reset/:id/:resetToken" element={<ResetPassword />} />
        </Route>
      </Route>

      <Route path="/mobile/route/:rid/:token" element={<RouteHereDetailMobile />} />
      <Route path="/mobile/routereturn/:rid/:token" element={<RouteReturnHereDetailMobile />} />

      <Route
        path="/signature/:token"
        element={<SignaturePad />}
      />
      <Route path="*" element={<Error404 />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-condition" element={<Terms />} />
      <Route path="/verbal-communication-consent-transcript" element={<VerbalInteraction />} />
    </Routes>
  </BrowserRouter>
);
}

export default App;
