import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { useNavigate } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import { getApiWithAuth, deleteApiWithAuth } from "../../../utils/api";
import { 
  Table,
  // TextField,
  // Button,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';

import "./expenseObject.css";
import { useReactToPrint } from 'react-to-print';

import { updateExpense } from "../../../utils/apiUrls";

const useStyles = makeStyles(() => ({
  page: {
    flexDirection: 'row',
    backgroundColor: '#ffffff'
  },
  section: {
    margin: 10,
    padding: 10,
    fontWeight: '700',
    flexGrow: 1
  },
  header: {
    fontSize: 20,
    marginBottom: 10,
    marginTop: '0px',
    fontWeight: '700',
    textAlign: 'center',
    border: '1px solid black',
    minWidth: '30%',
    padding: '2px 10px'
  },
  driverName: {
    fontSize: 18,
    marginBottom: 0,
    fontWeight: '700',
    textAlign: 'center',
  },
  header2: {
    fontSize: 18,
    marginBottom: 0,
    fontWeight: '700',
    textAlign: 'center',
    border: '1px solid black',
    padding: '5px 10px',
  },
  Total: {
    fontSize: 24,
    float: 'right',
    marginBottom: 8,
    marginRight: 0,
    marginTop: '0px',
    fontWeight: '700',
    textAlign: 'center'
  },
  table: {
    width: '100%',
    border: '1px solid black',
    marginBottom: 0,
    fontWeight: '700',
    marginTop: "15px"
  }
}));

const handleDelete = async (eid, onDelete) => {
  console.log('work')
  const response = await deleteApiWithAuth(`${updateExpense}/${eid}/delete/`);
  console.log(response)
  if (response.status !== 400) {
    toast.success('Expense Delete Successfully!', {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      });
      onDelete()
  } 
  else {
    toast.error('Backend Error!', {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      });
  }
};

function DriverObject({ driver, onDelete, route }) {
  const navigate = useNavigate();
  const componentRef = useRef();
  return (
    <div className="driver-div">
      <div className="driver-first">
        <h3 className="driver-date">Name</h3>
        <h2 className="cust-name-driver">{driver.name}</h2>
      </div>
      <div className="driver-second">
        <h3 className="driver-detail">Expense Type: {driver.expense_type}</h3>
        <h3 className="driver-detail">Amount:{driver.amount} </h3>
      </div>
      <div className="driver-third">
        <h2 className="driver-detail">Date Filled: {driver.date}</h2>
        <h2 className="driver-detail">Payment Type: {driver.payment_type}</h2>
      </div>
      <div className="driver-six">
        <button
          className="driver-action"
          onClick={() => navigate(`/edit/expense/${driver.id}`)}
        >
          Edit Expense
        </button>
        <h3 className="driver-detail-date">From {moment(driver.createdAt).format('MM-DD-YYYY')}</h3>
      </div>
      <div>
      <DeleteIcon style={{color:"red", cursor: 'pointer'}} onClick={() => (handleDelete(driver.id, onDelete))} />
      </div>
    </div>
  );
}

export default DriverObject;
