import React, { useRef, useState, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import { StyledEngineProvider } from "@mui/material/styles";
import "react-toastify/ReactToastify.min.css";

import { useParams } from 'react-router';
import {
  mainlogo,
  sign
} from '../../../assests';
import {
  WEB_SIGN,
} from "../../../utils/apiUrls";
import {
  getApiWithoutAuth,
  patchApiWithOutAuth
} from "../../../utils/api";
import './SignaturePad.css';

const SignaturePad = () => {

  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const [checked, setChecked] = useState(true);
  const [sent, setSent] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const sigCanvas = useRef({});

  const { token } = useParams();

  const clear = () => sigCanvas.current.clear();


  const save = async () => {
    if (!saved) {
      const image = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png').split(',')[1];
      try {
        const response = await patchApiWithOutAuth(WEB_SIGN + `${token}/`, {
          token,
          image
        });
        console.log(response)
        if (response.data.code === 200) {
          setSent(true);
        } else {
          toast.error(response.data.status, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } catch (error) {
        console.error('Error saving signature:', error);
        toast.error('Error saving signature: ' + error, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await getApiWithoutAuth(WEB_SIGN + `get-by-token/${token}/`);
        if (response.status === 404) {
          setOrderDetails(null);
          setError(true);
          toast.error('Wrong Signature Token', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (response.data !== undefined) {
          setOrderDetails(response.data);
        } else {
          setOrderDetails(null);
          setError(true);
          toast.error('Wrong Signature Token', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        console.log('Order Details: ', response);
      } catch (error) {
        console.error('Error fetching order details:', error);
        setError(true);
        toast.error('Error fetching order details: ' + error.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    };

    if (token) {
      fetchOrderDetails();
    }
  }, [token]);

  return (
    <div className="signature-container">
      <div className="logo-container">
        <img src={mainlogo} alt="Pill Drop" className="logo" />
      </div>
      <div className="header">
        <h2>E-Signature Request</h2>
      </div>

      {error ?
        <h3 style={{ fontWeight: '700', fontSize: '18px' }}>You provided wrong signature token. Please try again.</h3>
        :
        <>
          {
            ( orderDetails?.signature_image || sent )
            &&
            <h3 style={{ fontWeight: '700', fontSize: '18px' }}>Thank you for your signature! Your confirmation has been received.</h3>
          }

          <div className="statement-box">
            <h2 style={{ textAlign: "center" }}>Statement</h2>
            <input type="checkbox" id="consentCheckbox" checked={checked} onChange={(e) => setChecked(e.target.checked)} />
            <label htmlFor="consentCheckbox" style={{ fontSize: "14px", marginLeft: "10px" }}>
              I hereby consent to <strong>Pill Drop</strong> delivering my package with
              <strong> Order ID {orderDetails?.order?.slug}</strong> from <strong>{orderDetails?.order?.pharmacy_name} </strong>
              and authorize it to be left at the door or in the mailbox.
              <br />
              By providing this consent, I acknowledge that it serves as my electronic
              signature for this delivery.
            </label>
          </div>

          {(!orderDetails?.signature_image || sent ) ?
            <div className="signature-section">
              <div className='sign-header'>
                <p>Please Sign Here:</p>
                <img src={sign} alt="Sign" className="sign" />
              </div>
              <SignatureCanvas
                ref={sigCanvas}
                penColor="black"
                canvasProps={{ className: 'signature-canvas' }}
              />
              <div className="signature-buttons">
                <button disabled={!checked} onClick={clear} className="btn retake-btn">Retake</button>
                <button disabled={!checked} onClick={save} className="btn save-btn">Save</button>
              </div>
            </div>
            :
            <div className="signature-section">
              <div className='sign-header'>
                <p>Youe Sign Here:</p>
                <img src={sign} alt="Sign" className="sign" />
              </div>

              <div className='signpad-image'>
                <img src={orderDetails?.signature_image} alt="Signature" className="signature" />
              </div>
            </div>}

          <StyledEngineProvider injectFirst>
            <ToastContainer position="top-right" newestOnTop />
          </StyledEngineProvider>
        </>
      }
    </div>
  );
};

export default SignaturePad;
