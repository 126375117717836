import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import "./EditExpense.css";
import { getExpense, updateExpense } from "../../../utils/apiUrls"; // Adjust this import based on your API functions
import {
  getApiWithAuth,
  patchApiWithAuth,
  putApiWithAuth
} from "../../../utils/api";

function EditExpense() {
  const navigate = useNavigate();
  const { eid } = useParams();

  const [expenseDetail, setExpenseDetail] = useState({
    expense_type: "",
    payment_type: "",
    name: "",
    amount: "",
    expense_file: null,
    existing_file: "", // URL or path to the existing file
  });

  const [fieldErrors, setFieldErrors] = useState({
    expense_type: '',
    payment_type:'',
    name: '',
    amount: '',
    expense_file: '',
  });

  useEffect(() => {
    // Fetch the existing expense details when the component mounts
    const fetchExpense = async () => {
      try {
        const response = await getApiWithAuth(`${getExpense}/${eid}/`);
        setExpenseDetail({
          expense_type: response.data.data.expense_type,
          payment_type: response.data.data.payment_type,
          name: response.data.data.name,
          amount: response.data.data.amount,
          existing_file: response.data.data.expense_file || "", // Set existing file URL/path
        });
      } catch (error) {
        console.error("Error fetching expense details:", error);
      }
    };

    fetchExpense();
  }, [eid]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setExpenseDetail({
      ...expenseDetail,
      [name]: value,
    });
    setFieldErrors({ ...fieldErrors, [name]: '' });
  };

  const handleFileChange = (event) => {
    setExpenseDetail({
      ...expenseDetail,
      expense_file: event.target.files[0], // Store the selected file
    });
    setFieldErrors({ ...fieldErrors, expense_file: '' });
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!expenseDetail.expense_type) {
      errors.expense_type = 'Expense Type is required';
      isValid = false;
    }
    if (!expenseDetail.payment_type) {
      errors.payment_type = 'Payment Type is required';
      isValid = false;
    }
    if (!expenseDetail.name) {
      errors.name = 'Name is required';
      isValid = false;
    }
    if (!expenseDetail.amount) {
      errors.amount = 'Amount is required';
      isValid = false;
    }

    setFieldErrors(errors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      const formData = new FormData();
      formData.append('expense_type', expenseDetail.expense_type);
      formData.append('payment_type', expenseDetail.payment_type);
      formData.append('name', expenseDetail.name);
      formData.append('amount', expenseDetail.amount);

      // Append the new file if a file is selected
      if (expenseDetail.expense_file) {
        formData.append('expense_file', expenseDetail.expense_file);
      }

      try {
        const response = await putApiWithAuth(`${updateExpense}/${eid}/update/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response) {
          navigate("/list/expense");
        }
      } catch (error) {
        console.error("Error updating expense:", error);
      }
    }
  };

  return (
    <div>
      <Grid container className="form-grid">
        <Grid item className="drive-grid" xs={2}>
          <div className="form-sec-head-driver margin-top-heading">
            Edit Expense
          </div>
        </Grid>
        <Grid container className="form-grid" spacing={2}>
          <Grid item className="drive-grid" xs={4}>
            <label className="driver-field-label">Expense Type</label>
            <select
              className="driver-field"
              name="expense_type"
              value={expenseDetail.expense_type}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Type</option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
            </select>
            {fieldErrors.expense_type && <span className="error-message">{fieldErrors.expense_type}</span>}
          </Grid>
          <Grid item className="drive-grid" xs={4}>
            <label className="driver-field-label">Payment Type</label>
            <select
              className="driver-field"
              name="payment_type"
              value={expenseDetail.payment_type}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Type</option>
              <option value="Payment In">Payment In</option>
              <option value="Payment Out">Payment Out</option>
            </select>
            {fieldErrors.payment_type && <span className="error-message">{fieldErrors.payment_type}</span>}
          </Grid>
          <Grid item className="drive-grid" xs={4}>
            <label className="driver-field-label">Name</label>
            <input
              type="text"
              className="driver-field"
              name="name"
              placeholder="Name"
              value={expenseDetail.name}
              onChange={handleInputChange}
              required
            />
            {fieldErrors.name && <span className="error-message">{fieldErrors.name}</span>}
          </Grid>
          <Grid item className="drive-grid" xs={4}>
            <label className="driver-field-label">Amount</label>
            <input
              type="number"
              className="driver-field"
              name="amount"
              placeholder="Amount"
              value={expenseDetail.amount}
              onChange={handleInputChange}
              required
            />
            {fieldErrors.amount && <span className="error-message">{fieldErrors.amount}</span>}
          </Grid>
          <Grid item className="drive-grid" xs={4}>
            <label className="driver-field-label">Expense File</label>
            <input
              type="file"
              className="driver-field"
              name="expense_file"
              onChange={handleFileChange}
            />
           {expenseDetail.existing_file && (
  <div>
    <a 
      href={expenseDetail.existing_file} 
      target="_blank" 
      rel="noopener noreferrer"
      style={{ fontSize: '12px' }} // Adjust the font size as needed
    >
      View Existing File
    </a>
  </div>
)}

            {fieldErrors.expense_file && <span className="error-message">{fieldErrors.expense_file}</span>}
          </Grid>
        </Grid>
      </Grid>
      <div className="driver-btn">
        <button type="submit" onClick={handleSubmit} className="save-driver">
          Update Expense
        </button>
      </div>
    </div>
  );
}

export default EditExpense;
