import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import { Checkbox } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import InputMask from "react-input-mask";
import {
  ALL_PHARMACY_LIST,
  ALL_USERS,
} from "../../../utils/apiUrls";
import {
  putApiWithAuth,
  getApiWithAuth
} from "../../../utils/api";

import "./EditPharmacyEmployee.css";

function EditPharmacyEmployee() {
  const navigate = useNavigate();
  const {pid, eid} = useParams();

  const [backendimg, setbackendimg] = useState(
    "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
  );

  const [Fronts, setFronts] = useState("");
  const [isChecked, setIsChecked] = useState(true);
  const [pharmacyDetail, setPharmacyDetail] = useState({});
  const [isChecked2, setIsChecked2] = useState(false);
  const [personalDetail, setPersonalDetail] = useState({
    firstName: "",
    lastName: "",
    address: "",
    dateOfBirth: "",
    phoneNumber: "",
    cellNumber: "",
    isActive: true,
    logo: "",
    email: "",
    type: "PharmacyEmployee",
    username: "",
    password: "",
  });
  const getPharmacyEmployee = async () => {
    const response = await getApiWithAuth(ALL_USERS+`all?user_id=${eid}`);
    if (response.data !== undefined) {
      setPersonalDetail((prevState) => ({
        ...prevState, // Spread the previous state to maintain other key-value pairs
        firstName: response.data.data.first_name,
        lastName: response.data.data.last_name,
        address: response.data.data.address,
        phoneNumber: response.data.data.contact,
        cellNumber: response.data.data.contact,
        email: response.data.data.email,
        type: response.data.data.type.type,
        username: response.data.data.username,
        isActive: response.data.data.is_active,
        logo: response.data.data.profile_pic
      }));
      setFronts(response.data.data.profile_pic);
      if (response.data.data?.is_active === true) {
        setIsChecked(true);
        setIsChecked2(false);
      } else if (response.data.data?.is_active === false) {
        setIsChecked2(true);
        setIsChecked(false);

      }
    }
  };

  const getPharmacy = async () => {
    const response = await getApiWithAuth(ALL_PHARMACY_LIST+`/${pid}`);
    if (response.data !== undefined) {
      setPharmacyDetail(response.data.data)
    } else {
      console.log("Error")
    }
  };

  useEffect(() => {
    getPharmacyEmployee();
    getPharmacy()
  }, []);

  const handlePersonalInputChange = (event) => {
    const { name, value } = event.target;
    setPersonalDetail({
      ...personalDetail,
      [name]: value,
    });
  };
  const handlePersonalInputChangeChecked = (event) => {
    const { name, checked } = event.target;
    console.log("namevalue",name,checked)
    setPersonalDetail(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };
  
  useEffect(() => {
    const pharmacyId = localStorage.getItem('pharmacyId')
    if (pid !== undefined) {
      if (parseInt(pid) !== parseInt(pharmacyId)) {
        navigate('/404')
      }
    }
  }, [pid])


  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("personalDetail", personalDetail);
    const updatedPersonalDetail = new FormData();
    updatedPersonalDetail.append("first_name", personalDetail.firstName);
    updatedPersonalDetail.append("last_name", personalDetail.lastName);
    updatedPersonalDetail.append("address", personalDetail.address);
    updatedPersonalDetail.append("contact", personalDetail.cellNumber);
    updatedPersonalDetail.append("email", personalDetail.email);
    updatedPersonalDetail.append("username", personalDetail.username);
    updatedPersonalDetail.append("pharmacy_id", pid);
    updatedPersonalDetail.append("show_report", personalDetail.show_report);
    updatedPersonalDetail.append("show_team", personalDetail.show_team);
    updatedPersonalDetail.append("type", personalDetail.type);
    updatedPersonalDetail.append("password", personalDetail.password);
    console.log(personalDetail.logo)
    if (personalDetail.logo && personalDetail.logo !== null) {
      updatedPersonalDetail.append("profile_pic", personalDetail.logo);
    }
    updatedPersonalDetail.append("is_active", personalDetail.isActive);
    updatedPersonalDetail.append("user_id", eid);
    
    updatedPersonalDetail.append("action", "update_details");
    
    const responsePersonal = await putApiWithAuth(
      ALL_USERS + `all`,
      updatedPersonalDetail
    );
    if (responsePersonal.data !== undefined) {
      navigate(`/pharmacy/${pid}/list/employee`);
    } else {
      console.log("something error")
    }
  };

  const handleEditClickk = () => {
    document.getElementById("imageInputt").click();
  };

  const handleImageUpload3 = (event) => {
    // const file = event.target.files[0];
    // setFrontImage(file);
    const fileObject = event.target.files[0];

    setPersonalDetail({
      ...personalDetail,
      logo: fileObject,
    });
    // Extract the file data from the object
    const { name, size, type, lastModified } = fileObject;

    // Create a Blob from the file data
    const fileBlob = new Blob([fileObject], { type });

    // Create a File instance
    const file = new File([fileBlob], name, { lastModified });

    if (file) {
      const reader = new FileReader();
      // Read the contents of the image file as a data URL
      reader.onloadend = () => {
        // Set the image URL in the state
        setFronts(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const updateIsActive = () => {
    setIsChecked2(false)
    setIsChecked(true)
    setPersonalDetail({
      ...personalDetail,
      isActive: true,
    });
  }
  const updateIsActive2 = () => {
    setIsChecked(false)
    setIsChecked2(true)
    setPersonalDetail({
      ...personalDetail,
      isActive: false,
    });
  }

  return (
    <div>
      
      <Grid container className="form-grid">
        <Grid item xs={3}>
        <div className="form-sec-head-facility">Account Credentials</div>
        </Grid>{" "}
        <Grid item className="drive-grid ml-5" xs={4}></Grid>
        <Grid item className="drive-grid " xs={3}>
          <button className="activate" color="apple" variant="contained">
            Activate
          </button>{" "}
          <Checkbox
            name="bussAddress"
            checked={isChecked}
            onClick={() =>
              updateIsActive()
            }
          />
          {/* </Grid>
        <Grid item className="drive-grid " > */}
          <button className="deactivate">Deactivate</button>{" "}
          <Checkbox
            name="bussAddress2"
            checked={isChecked2}
            onChange={() =>
              updateIsActive2()
            }
          />
        </Grid>
        <Grid item className="drive-grid " xs={2}>
          {/* Hidden file input triggered by the EditIcon click */}
          <input
            autoComplete="off"
            type="file"
            id="imageInputt"
            style={{ display: "none" }}
            accept="image/*"
            onChange={handleImageUpload3}
          />
          {Fronts ? (
            <img
              src={Fronts}
              style={{ borderRadius: "100%", height: "50px", width: "50px" }}
              className="id-image"
              alt="Image 1"
            />
          ) : (
            <img
              src={backendimg}
              style={{ borderRadius: "100%", height: "50px", width: "50px" }}
              className="id-image"
              alt="Image 1"
            />
          )}{" "}
          <div className="img-icon" onClick={handleEditClickk}>
            {/* Render your EditIcon component here */}
            <EditIcon />
          </div>
        </Grid>
        <Grid item className="drive-grid "></Grid>
      </Grid>
      <Grid container className="form-grid" spacing={2}>
        {/* First Row */}

        <Grid item className="drive-grid" xs={3}>
          <label className="driver-field-label">Type</label>
          <select
            className="driver-field"
            name="type"
            value={personalDetail.type || ""} // Added a fallback to empty string to handle null or undefined
            onChange={handlePersonalInputChange}
          >
            <option value="PharmacyOwner">PharmacyOwner</option>
            <option value="PharmacyEmployee">PharmacyEmployee</option>
          </select>
        </Grid>

        <Grid item className="drive-grid" xs={3}>
          <label className="driver-field-label">Pharmacy Name</label>
          <input
            autoComplete="off"
            type="text"
            className="facility-field"
            name="type"
            placeholder="Pharmacy Type"
            value={pharmacyDetail.name}
            disabled
          />
        </Grid>
        <Grid item className="drive-grid" xs={6}>
          <label className="driver-field-label">Pharmacy Address</label>
          <input
            autoComplete="off"
            type="text"
            className="facility-field"
            name="type"
            placeholder="Pharmacy Type"
            value={pharmacyDetail.location}
            disabled
          />
        </Grid>
        <Grid item className="drive-grid" xs={3}>
          <label className="facility-field-label">User Name</label>
          <input
            autoComplete="off"
            type="text"
            className="facility-field"
            name="username"
            placeholder="User Name"
            value={personalDetail.username}
            onChange={handlePersonalInputChange}
          />
        </Grid>
        {/* <Grid item className="drive-grid" xs={3}>
          <label className="facility-field-label">Phone Number</label>
          <input
            autoComplete="off"
            type="number"
            className="facility-field"
            name="cellNumber"
            placeholder="Phone Number"
            value={personalDetail.cellNumber}
            onChange={handlePersonalInputChange}
          />
        </Grid> */}
        <Grid item className="drive-grid" xs={3}>
  <label className="facility-field-label">Phone Number</label>
  <InputMask
    mask="(999) 999-9999" // Adjust this mask as needed
    value={personalDetail.cellNumber}
    onChange={handlePersonalInputChange}
  >
    {() => (
      <input
        autoComplete="off"
        type="text" // Change to text to accommodate the mask
        className="facility-field"
        name="cellNumber"
        placeholder="Cell Number"
      />
    )}
  </InputMask>
</Grid>
        
        <Grid item className="drive-grid" xs={3}>
          <label className="facility-field-label">Email</label>
          <input
            autoComplete="off"
            type="email"
            className="facility-field"
            name="email"
            placeholder="Email"
            value={personalDetail.email}
            onChange={handlePersonalInputChange}
          />
        </Grid>
        <Grid item className="drive-grid" xs={3} md={3}>
          <label className="facility-field-label">Password</label>
          <input
            autoComplete="off"
            type="text"
            className="facility-field"
            style={{borderRadius: '13px'}}
            name="password"
            placeholder="Password"
            value={personalDetail.password}
            onChange={handlePersonalInputChange}
          />
        </Grid>
        <Grid item className="drive-grid" xs={3}>
        <label className="facility-field-label">Show Team</label>
        <input
          autoComplete="off"
          type="checkbox"
          className="facility-field small-checkbox"
          name="show_team"
          checked={personalDetail.show_team}
          onChange={handlePersonalInputChangeChecked}
        />
      </Grid>
      <Grid item className="drive-grid" xs={3}>
        <label className="facility-field-label">Show Report</label>
        <input
          autoComplete="off"
          type="checkbox"
          className="facility-field small-checkbox"
          name="show_report"
          checked={personalDetail.show_report}
          onChange={handlePersonalInputChangeChecked}
        />
      </Grid>
        {/* <Grid item className="drive-grid" xs={6}>
          <label className="facility-field-label">Address</label>
          <input
            autoComplete="off"
            type="text"
            className="facility-field"
            name="address"
            placeholder="Address"
            value={personalDetail.address}
            onChange={handlePersonalInputChange}
          />
        </Grid> */}

        {/* Second Row */}
        {/* <Grid item className="drive-grid" xs={3}>
          <label className="facility-field-label">Date of Birth</label>
          <input
            autoComplete="off"
            type="date"
            className="facility-field"
            name="dateOfBirth"
            placeholder="YYYY/MM/DD"
            value={personalDetail.dateOfBirth}
            onChange={handlePersonalInputChange}
          />
        </Grid> */}
      </Grid>
      <div className="facility-btn">
        {/* <button type="button" className="del-facility">Delete Account</button> */}
        <button type="submit" onClick={handleSubmit} className="save-facility">
          Save Details
        </button>
      </div>
    </div>
  );
}

export default EditPharmacyEmployee;
