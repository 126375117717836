import React from "react";
import { ToastContainer } from 'react-toastify';
import { StyledEngineProvider } from "@mui/material/styles";
import "react-toastify/ReactToastify.min.css";

import "./MainComponent.css";
import Navbar from "../CommonComponents/Navbar/Navbar";
import VoiceComponent from "./TwilioCalls/TwilioCalls";

const MainComponent = () => {

  return (
    <>
      <Navbar />
      <VoiceComponent />
      <StyledEngineProvider injectFirst>
        <ToastContainer position="top-right" newestOnTop />
      </StyledEngineProvider>
    </>
  );
};

export default MainComponent;
