import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import moment from "moment";
import {
  Drawer,
  IconButton,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  Autocomplete
} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import ForwardIcon from "@mui/icons-material/Forward";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import RouteSlipRoute from "../../CommonComponents/PrintDoc/RouteSlipRoute";
import { useReactToPrint } from 'react-to-print';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { DateRange } from "react-date-range";
import {
  landline,
  iphone,
  route_report,
  pharmacyCallBlack,
  pdfLogo,
  registered,
  photoId,
  buildings
} from '../../../assests';

import { toast } from "react-toastify";
import { ArrowDropDownCircle } from "@mui/icons-material";
import {
  ALL_ROUTES_WEB,
  MERGE_ROUTE,
  MERGE_ROUTE_RETURN,
  ALL_ZONES_LIST,
  DRIVERS_ROUTE
} from "../../../utils/apiUrls";
import { getApiWithAuth, patchApiWithAuth, postApiWithAuth } from "../../../utils/api";

import RouteObject from "../../MiniComponents/RouteObject";
import "./routeList.css";

function RouteList() {
  const [loading, setLoading] = useState(false);
  const [loadingRoute, setLoadingRoute] = useState(false);
  const [mergeRoute, setMergerRoute] = useState(false);
  const [mergeRouteReturn, setMergerRouteReturn] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [selectedRouteIds, setSelectedRouteIds] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [zip, setZip] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [activeDriverList, setActiveDriverList] = useState([]);
  const [borough, setBorough] = useState([]);
  const [driver, setDriver] = useState(null);
  const [modalCreateRouteOpen, setModalCreateRouteOpen] = useState(false);
  const [zone, setZone] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [isCalendarVisible, setCalendarVisible] = useState(false);
  const [hasMore, setHasMore] = useState(null);
  const [search, setSearch] = useState("");
  const [slug, setSlug] = useState("");
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(new Date().getTime() - 10 * 24 * 60 * 60 * 1000),
      endDate: new Date(new Date().getTime() + 4 * 24 * 60 * 60 * 1000),
      key: "selection",
    },
  ]);
  const [selectedRoutes, setSelectedRoutes] = useState(null);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [formData, setFormData] = useState({
    optimizeFor: 'DISTANCE',
    routeOption: 'startToEnd',
    clusterOption: 'none',
    traffic: 'enabled',
    extraMinutes: 0,
    driving_distance_value: 800,
    departureDateTime: new Date(),
  });

  const componentRef2 = useRef();
  const routeObjRef = useRef(null);

  const handleIconClick = (routes) => {
    console.log("selectedroutes66", routes)
    setSelectedRoutes(routes);
  };
  useEffect(() => {
    handlePrintSlip();
  }, [selectedRoutes]);


  let isFetching = false;

  const getRoutes = async (pageIndexData = pageIndex, loadMore = false) => {
    if (loadingRoute || isFetching) return;
    setLoadingRoute(true);
    isFetching = true;

    if (!loadMore) {
      routeObjRef.current.scrollTop = 0;
      setPageIndex(1);
    }

    const queryParams = { zip, driver };
    if (search === "" && slug === "") {
      queryParams.from = moment(dateRange[0].startDate).format("MM-DD-YYYY");
      queryParams.to = moment(dateRange[0].endDate).format("MM-DD-YYYY");
    }

    const response = await getApiWithAuth(
      `${ALL_ROUTES_WEB}?page=${!loadMore ? 1 : pageIndexData}&pageSize=${pageSize}&dated_response=true&search=${search}&slug=${slug}`,
      queryParams
    );

    if (response.data !== undefined) {
      let groupedRoutes = {};
      response.data.data.data.forEach(route => {
        const createdAtDate = new Date(route.createdAt).toLocaleDateString();
        if (!groupedRoutes[createdAtDate]) {
          groupedRoutes[createdAtDate] = [route];
        } else {
          groupedRoutes[createdAtDate].push(route);
        }
      });

      const newGroupedRoutesArray = Object.keys(groupedRoutes).map(date => ({ date, data: groupedRoutes[date] }));

      let newFilteredRoutes = newGroupedRoutesArray;
      if (selectedDate) {
        const selectedDateString = selectedDate.toLocaleDateString();
        newFilteredRoutes = newGroupedRoutesArray.filter(group => group.date === selectedDateString);
      }

      if (loadMore) {
        setRoutes(prevRoutes => {
          const updatedRoutes = [...prevRoutes];
          newFilteredRoutes.forEach(newGroup => {
            const existingGroupIndex = updatedRoutes.findIndex(group => group.date === newGroup.date);
            if (existingGroupIndex !== -1) {
              updatedRoutes[existingGroupIndex].data = [
                ...updatedRoutes[existingGroupIndex].data,
                ...newGroup.data
              ];
            } else {
              updatedRoutes.push(newGroup);
            }
          });
          return updatedRoutes;
        });
      } else {
        setRoutes(newFilteredRoutes);
      }

      setPageIndex(prevPageIndex => prevPageIndex + 1);
      setPageSize(response.data.data.pagination.pageSize);
      setTotalPages(response.data.data.pagination.totalPages);
      setTotalItems(response.data.data.pagination.totalItems);
      setHasMore(response.data.data.pagination.hasMore);
      setTotalLength(prevPageIndex => prevPageIndex + response.data.data.length);
    } else {
      setHasMore(false);
      setTotalLength(0);
    }

    setLoading(false);
    setLoadingRoute(false);
    isFetching = false;
  };


  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const inputValue = e.target.value;
      if (inputValue.includes("-")) {
        setSlug(inputValue);
      } else {
        setSearch(inputValue);
      }
    }
  };

  const getDrivers = async () => {
    try {
      const Response = await getApiWithAuth(DRIVERS_ROUTE, { "justList": true });;
      setDriverList(Response.data.data);
      const filteredDrivers = Response.data.data.filter(item => item.isactive);
      setActiveDriverList(filteredDrivers);
    } catch {
      toast.error(`Backend Error for get driver list!`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // setMergerRoute(false);
    }
  };

  useEffect(() => {
    getRoutes();
  }, [search, slug, zip, driver, dateRange]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = routeObjRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 250 && hasMore) {
        getRoutes(pageIndex, true);
      }
    };

    const div = routeObjRef.current;
    if (div) {
      div.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (div) {
        div.removeEventListener('scroll', handleScroll);
      }
    };
  }, [hasMore, loadingRoute, pageIndex]);

  useEffect(() => {
    fetchBorough();
    getDrivers();
  }, []);

  const toggleCalendar = () => {
    setCalendarVisible(!isCalendarVisible);
  };

  const handleSelect = (ranges) => {
    if (ranges.selection.endDate) {
      setDateRange([ranges.selection]);
      setCalendarVisible(false);
    }
  };

  const startItem = pageIndex * pageSize + 1;
  const endItem = Math.min(startItem + pageSize - 1, totalItems);

  const handlePrintSlip = useReactToPrint({
    content: () => componentRef2.current,
  });
  const handleRouteSelection = (routeId) => {
    if (selectedRouteIds.includes(routeId)) {
      setSelectedRouteIds(selectedRouteIds.filter((id) => id !== routeId));
    } else {
      setSelectedRouteIds([...selectedRouteIds, routeId]);
    }
  };

  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await getApiWithAuth('/api/route/settings/');
        if (response.data) {
          setFormData({
            ...response.data.data,
            driving_distance_value: response.data.data.driving_distance_value || 800,
            departureDateTime: response.data.data.departureDateTime ? new Date(response.data.data.departureDateTime) : new Date(),
          });
        }
      } catch (error) {
        console.error('Failed to fetch settings', error);
      }
    };
    fetchSettings();
  }, []);


  useEffect(() => {
    const inputElement = document.getElementById("customDatePicker");

    if (inputElement) {
      inputElement.addEventListener("click", () => {
        inputElement.click();
      });
    }
  }, []);

  const handleMergeRoute = async () => {
    setLoading(true);
    const routeOrderResponse = await postApiWithAuth(MERGE_ROUTE, {
      routeIds: selectedRouteIds,
    });

    if (routeOrderResponse.data.status === 201) {
      toast.success(`Route merge successfully!`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // setModalCreateRouteOpen(false);
      getRoutes();
      setMergerRoute(false);
    } else {
      toast.error(`Backend Error!`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      getRoutes();
      setMergerRoute(false);
    }
    setLoading(false);
    setSelectedRouteIds([])
  };
  const handleMergeRouteReturn = async () => {
    setLoading(true);
    const routeOrderResponse = await postApiWithAuth(MERGE_ROUTE_RETURN, {
      routeIds: selectedRouteIds,
    });

    if (routeOrderResponse.data.status === 201) {
      toast.success(`Route merge successfully!`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      getRoutes();
      setMergerRouteReturn(false);
    } else {
      toast.error(`Backend Error!`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      getRoutes();
      setMergerRouteReturn(false);
    }
    setLoading(false);
    setSelectedRouteIds([])
  };

  const fetchBorough = async () => {
    try {
      const response = await getApiWithAuth(ALL_ZONES_LIST + "borros/", { "justList": true });
      console.log(response);
      if (response.data !== undefined) {
        setBorough(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleZipChange = (e, value) => {
    console.log(value);
    if (value.length > 0) {
      const selectedZones = value?.map((selectedOption) =>
        zone.find((zone) => zone.id === selectedOption.id)
      );
      const zipcodes = selectedZones.flatMap((selectedZone) =>
        selectedZone ? selectedZone.zipcode.map((item) => item.zipcode) : []
      );
      setZip(zipcodes);
      console.log(e);
    } else {
      setZip("");
    }
  };

  const handleBoroughChange = async (e, value) => {
    console.log(value);
    if (value !== null) {
      const selectedBoroughs = value;
      const response = await getApiWithAuth(ALL_ZONES_LIST + `borros/${selectedBoroughs.id}/`);
      if (response.data !== undefined) {
        setZone(response.data.data.zones);
      }

      const zones = response.data.data.zones

      // Use flatMap to extract zipcodes from selected zones within selected boroughs
      const zipcodes = zones
        .filter((zone) => zone.zipcode.length > 0)
        .flatMap((zone) => zone.zipcode.map((zipcode) => zipcode.zipcode));
      console.log(zipcodes);
      setZip(zipcodes);
    } else {
      setZip([]);
      setZone([]);
    }
  };

  const handleDriverChange = (e, value) => {
    if (value !== null) {
      setDriver(value.id);
    } else {
      setDriver(null);
    }
  };

  // const sortedDates = Object.keys(groupedRoutes).sort((a, b) => {
  //   const dateA = new Date(a).toLocaleDateString('en-US');
  //   const dateB = new Date(b).toLocaleDateString('en-US');
  //   return new Date(dateB) - new Date(dateA);
  // });

  const handleSaveSettings = async () => {
    try {
      const payload = {
        ...formData,
        departureDateTime: formData.departureDateTime.toISOString(),
      };
      const response = await patchApiWithAuth('/api/route/settings/', payload);
      if (response.data.status === 200) {
        toast.success('Settings saved successfully', {
          position: "top-right",
          autoClose: 500,
        });
        setSettingsOpen(false);
      } else {
        toast.error('Failed to save settings', {
          position: "top-right",
          autoClose: 500,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error('An error occurred while saving settings', {
        position: "top-right",
        autoClose: 500,
      });
    }
  };

  return (
    <div>
      <div className="second-row">
        <div className="filter-container">
          <div className="route-search-container">
            <label htmlFor="search">Search:</label>
            <input
              type="text"
              id="slug"
              autoComplete="off"
              onKeyDown={handleSearchKeyDown}
              placeholder="Route Name, Order Name"
              className="search-input"
            />
          </div>
          <div className="search-filter-container">
            <Autocomplete
              style={{ padding: "0" }}
              disablePortal
              id="combo-box-demo"
              options={borough}
              className="filter-input"
              sx={{
                width: 300,
                "& .MuiOutlinedInput-root": {
                  p: 0,
                },
                "& .MuiAutocomplete-tag": {
                  bgcolor: "primary.lighter",
                  border: "0px solid",
                  borderColor: "transparent",
                },
              }}
              renderOption={(props, option) => (
                <p {...props} style={{ fontSize: '13px', fontFamily: "Saira Extra Condensed" }}>
                  {option.name}
                </p>
              )}
              renderInput={(params) => (
                <TextField {...params} fullWidth placeholder="Select Borough" sx={{
                  "& input::placeholder": {
                    fontSize: "14px",
                    opacity: 1,
                    fontFamily: "Saira Extra Condensed !important"
                  },
                  "& input": {
                    fontSize: '14px !important',
                    fontFamily: "Saira Extra Condensed !important"
                  }
                }} />
              )}
              getOptionLabel={(option) => option.name}
              onChange={handleBoroughChange}
              // IconComponent={ExpandCircleDownIcon}
              popupIcon={<ArrowDropDownCircle sx={{ color: 'black', width: 20 }} />}
            />

            {/* <ExpandCircleDownIcon /> */}
          </div>
          <div className="search-filter-container">
            <Autocomplete
              style={{ padding: "0" }}
              disablePortal
              multiple
              id="combo-box-demo"
              options={zone}
              className="filter-input"
              renderOption={(props, option) => (
                <p {...props} style={{ fontSize: '13px', fontFamily: "Saira Extra Condensed" }}>
                  {option.name}
                </p>
              )}
              sx={{
                width: 300,
                "& .MuiOutlinedInput-root": {
                  p: 0,
                },
                "& .MuiAutocomplete-tag": {
                  bgcolor: "primary.lighter",
                  border: "0px solid",
                  borderColor: "transparent",
                },
              }}
              renderInput={(params) => (
                <TextField {...params} fullWidth placeholder="Select Zone" sx={{
                  "& input::placeholder": {
                    fontSize: "14px",
                    opacity: 1,
                    fontFamily: "Saira Extra Condensed !important"
                  },
                  "& input": {
                    fontSize: '14px !important',
                    fontFamily: "Saira Extra Condensed !important"
                  }
                }} />
              )}
              getOptionLabel={(option) => option.name}
              onChange={handleZipChange}
              popupIcon={<ArrowDropDownCircle sx={{ color: 'black', width: 20 }} />}
            />
          </div>
          <div className="search-filter-container">
            <Autocomplete
              style={{ padding: "0", fontFamily: "Saira Extra Condensed !important" }}
              disablePortal
              id="combo-box-demo"
              options={driverList}
              className="filter-input"
              renderOption={(props, option) => (
                <p {...props} style={{ fontSize: '13px', fontFamily: "Saira Extra Condensed" }}>
                  {option.name}
                </p>
              )}
              sx={{
                // width: 300,
                "& .MuiOutlinedInput-root": {
                  p: 0,
                },
                "& .MuiAutocomplete-tag": {
                  bgcolor: "primary.lighter",
                  border: "0px solid",
                  borderColor: "transparent",
                },
              }}
              renderInput={(params) => (
                <TextField {...params} fullWidth placeholder="Select Driver" sx={{
                  "& input::placeholder": {
                    fontSize: "14px",
                    opacity: 1,
                    fontFamily: "Saira Extra Condensed !important"
                  },
                  "& input": {
                    fontSize: '14px !important',
                    fontFamily: "Saira Extra Condensed !important"
                  }
                }} />
              )}
              getOptionLabel={(option) => option.name}
              onChange={handleDriverChange}
              popupIcon={<ArrowDropDownCircle sx={{ color: 'black', width: 20 }} />}
            />
          </div>
        </div>
        {mergeRoute && (
          <>
            <Button
              variant="contained"
              color="success"
              disabled={loading}
              onClick={handleMergeRoute}
            >
              Merge Routes
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => setMergerRoute(false)}
            >
              Cancel
            </Button>
          </>
        )}
        {mergeRouteReturn && (
          <>
            <Button
              variant="contained"
              color="success"
              disabled={loading}
              onClick={handleMergeRouteReturn}
            >
              Merge Routes
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => setMergerRouteReturn(false)}
            >
              Cancel
            </Button>
          </>
        )}
        <div className="route-date-input-container">
          <IconButton
            onClick={() => setSettingsOpen(true)}
            style={{ marginRight: 10 }}
          >
            <SettingsIcon />
          </IconButton>
          <div type="text" className="date-input">
            <CalendarMonthIcon
              style={{ fontSize: "28px", cursor: "pointer" }}
              onClick={toggleCalendar}
            />
            {moment(dateRange[0].startDate).format("MM-DD-YYYY")}
            <ForwardIcon style={{ fontSize: "32px" }} />
            {dateRange[0].endDate
              ? moment(dateRange[0].endDate).format("MM-DD-YYYY")
              : ""}
          </div>
          {isCalendarVisible && (
            <div className="calendar-container">
              <DateRange
                ranges={dateRange}
                onChange={handleSelect}
                retainEndDateOnFirstSelection={true}
              />
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="route-obj" ref={routeObjRef}>
          {routes.map((date, i) => (
            <div key={i} className="daily-route-list">
              <div className="route-obj-top">
                <h3 className="route-top-day">{moment(date.date).format("MM-DD-YYYY")}</h3>
                <img
                  src={route_report}
                  onClick={() => handleIconClick(date.data)}
                  style={{ marginRight: '30px', marginBottom: '3px' }}
                  alt="print"
                />
              </div>
              {date.data.map((route) => (
                <div key={route.id} style={{ display: "flex" }}>
                  {mergeRoute && route.type === "Order" && (
                    <input
                      autoComplete="off"
                      type="checkbox"
                      checked={selectedRouteIds.includes(route.id)}
                      onChange={() => handleRouteSelection(route.id)}
                    />
                  )}
                  {mergeRouteReturn && route.type === "Pharmacy" && (
                    <input
                      autoComplete="off"
                      type="checkbox"
                      checked={selectedRouteIds.includes(route.id)}
                      onChange={() => handleRouteSelection(route.id)}
                    />
                  )}
                  <RouteObject
                    route={route}
                    setMergerRoute={setMergerRoute}
                    setMergerRouteReturn={setMergerRouteReturn}
                    getRoutes={getRoutes}
                    driverList={activeDriverList}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
        <div style={{ display: 'none' }}>
          {selectedRoutes && (
            <div ref={componentRef2}>
              <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', paddingTop: '10px', pageBreakBefore: 'always', pageBreakAfter: 'always', pageBreakInside: 'avoid' }} >
                <RouteSlipRoute routes={selectedRoutes} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="footer-container">
        <div />
        <div className="pagination">
          <button className="page-row">Route Count{totalLength !== 0 && ' 1'} - {totalLength}</button>
          {/* <button className="page-row">Row per page - {pageSize}</button> */}
          {/* <button className="pag-row" style={{backgroundColor: '#e2e2e2'}}>Row per page - {pageSize}</button> */}
          {/* <div className="page-row">
            {pageIndex > 0 && (
              <ExpandCircleDownIcon
                className="pag-icon-prev"
                onClick={() => setPageIndex(pageIndex - 1)}
              />
            )}
            
            <p>
              Page {startItem} - {endItem}
            </p>
            {pageIndex < totalPages - 1 && (
              <ExpandCircleDownIcon
                className="pag-icon"
                onClick={() => setPageIndex(pageIndex + 1)}
              />
            )}
          </div> */}
        </div>
      </div>
      <Drawer
        anchor="right"
        open={settingsOpen}
        onClose={() => setSettingsOpen(false)}
      >
        <div style={{ width: 300, padding: 20 }}>
          <div style={{ width: 300, padding: 20 }}>
            {/* Optimize For */}
            <FormControl component="fieldset" style={{ marginBottom: 20 }}>
              <FormLabel component="legend">Optimize For</FormLabel>
              <RadioGroup
                aria-label="optimizeFor"
                name="optimizeFor"
                value={formData.optimizeFor}
                onChange={(e) => setFormData({ ...formData, optimizeFor: e.target.value })}
              >
                <FormControlLabel value="TIME" control={<Radio />} label="Optimize by Time" />
                <FormControlLabel value="DISTANCE" control={<Radio />} label="Optimize by Mile" />
              </RadioGroup>
            </FormControl>

            {/* Route Option */}
            <FormControl component="fieldset" style={{ marginBottom: 20 }}>
              <FormLabel component="legend">Route Option</FormLabel>
              <RadioGroup
                aria-label="routeOption"
                name="routeOption"
                value={formData.routeOption}
                onChange={(e) => setFormData({ ...formData, routeOption: e.target.value })}
              >
                <FormControlLabel value="startToEnd" control={<Radio />} label="Start & End At Same Location" />
                <FormControlLabel value="endAnywhere" control={<Radio />} label="End At Any Location" />
              </RadioGroup>
            </FormControl>

            {/* Clustering Option */}
            <FormControl component="fieldset" style={{ marginBottom: 20 }}>
              <FormLabel component="legend">Clustering Option</FormLabel>
              <RadioGroup
                aria-label="clusterOption"
                name="clusterOption"
                value={formData.clusterOption}
                onChange={(e) => setFormData({ ...formData, clusterOption: e.target.value })}
              >
                <FormControlLabel value="none" control={<Radio />} label="None" />
                <FormControlLabel value="topologySegment" control={<Radio />} label="Topology Segment" />
                <FormControlLabel value="drivingDistance" control={<Radio />} label="Driving Distance" />
              </RadioGroup>
              {formData.clusterOption === 'drivingDistance' && (
                <TextField
                  label="Driving Distance (meters)"
                  type="number"
                  value={formData.driving_distance_value}
                  onChange={(e) => setFormData({ ...formData, driving_distance_value: e.target.value })}
                  fullWidth
                  style={{ marginTop: 10 }}
                />
              )}
            </FormControl>

            {/* Traffic Option */}
            <FormControl component="fieldset" style={{ marginBottom: 20 }}>
              <FormLabel component="legend">Traffic</FormLabel>
              <RadioGroup
                aria-label="traffic"
                name="traffic"
                value={formData.traffic}
                onChange={(e) => setFormData({ ...formData, traffic: e.target.value })}
              >
                <FormControlLabel value="enabled" control={<Radio />} label="Enable" />
                <FormControlLabel value="disabled" control={<Radio />} label="Disable" />
              </RadioGroup>
            </FormControl>

            {/* Extra Minutes */}
            <TextField
              label="Each Stop Extra Minutes"
              type="number"
              value={formData.extraMinutes}
              onChange={(e) => setFormData({ ...formData, extraMinutes: e.target.value })}
              fullWidth
              style={{ marginBottom: 20 }}
            />

            {/* Date Time For Departure */}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label="Departure Date & Time"
                value={formData.departureDateTime}
                onChange={(newValue) => {
                  setFormData({ ...formData, departureDateTime: newValue });
                }}
                renderInput={(params) => <TextField {...params} fullWidth style={{ marginBottom: 20 }} />}
              />
            </LocalizationProvider>

            {/* Save Button */}
            <Button variant="contained" color="primary" style={{ marginTop: 20 }} onClick={handleSaveSettings}>
              Save Settings
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default RouteList;