import React, { useState, useEffect, useRef } from 'react';
import { Device } from 'twilio-client';
import CallSharpIcon from '@mui/icons-material/CallSharp';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallMadeIcon from '@mui/icons-material/CallMade';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import { getApiWithAuth, patchApiWithAuth } from '../../../utils/api';
import { AGENTS, ORDER_MISSED_CALLS, VOCIE_CONNECT } from '../../../utils/apiUrls';
import CallEndIcon from '@mui/icons-material/CallEnd';
import Phone from "@mui/icons-material/Phone";
import CustomModel from '../../CommonComponents/CustomModel/CustomModel';
import moment from 'moment';
import './CallList.css'
import { act } from 'react';
import VoiceComponent from '../TwilioCalls/TwilioCalls';
import { red } from '@material-ui/core/colors';

const renderCallIcon = (call) => {
    if (call.type === "incoming") {
        return <CallReceivedIcon />;
    } else if (call.type === "outgoing") {
        return <CallMadeIcon />;
    }
    return <CallSharpIcon />;
};

const getCallStatusColor = (status) => {
    if (status === "completed") {
        return "green";  // Green for completed
    } else if (status === "no-answer") {
        return 'red';  // Red for no answer
    } else if (status === "busy") {
        return '#FFA500';  // Yellow for busy
    }
    return "inherit";  // Default color if no status matches
};


function formatDuration(durationInSeconds) {
    // Parse the string to an integer
    const totalSeconds = parseInt(durationInSeconds, 10);
  
    // Calculate hours, minutes, and seconds
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
  
    // Pad with zeros if necessary
    const paddedHours = String(hours).padStart(2, '0');
    const paddedMinutes = String(minutes).padStart(2, '0');
    const paddedSeconds = String(seconds).padStart(2, '0');
  
    // Return the formatted time string
    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
}



function CallList() {
    const [loading, setLoading] = useState(null);
    const [callingOrder, setCallingOrder] = useState({});

    const [activeTab, setActiveTab] = useState('missed');
    const [calls, setCalls] = useState([]);

    const [hasMore, setHasMore] = useState(true);
    const [initiateCall, setInitiateCall] = useState(false);
    const [page, setPage] = useState(1);
    const callListRef = useRef();


    const loadCalls = async (loadpage) => {
        if (loading) return; // Prevents multiple requests when already loading
        setLoading(true);
        try {
          const response = await getApiWithAuth(ORDER_MISSED_CALLS, { missed: activeTab === 'missed'? 'true' : 'false', 'list': 'true', page: loadpage });
          const newCalls = response.data.data.results;
          // Append new calls to the existing list
          
          setCalls((prevCalls) => [...prevCalls, ...newCalls]);
          setHasMore(response.data.next !== null);
          setLoading(false);
        } catch (error) {
          console.error('Error loading calls', error);
          setLoading(false);
        }
    };

    useEffect(() => {
        setCalls([]);
        setPage(1);
        loadCalls(1);
    }, [activeTab]);
    
    const handleScroll = () => {
        if (callListRef.current) {
          const { scrollTop, scrollHeight, clientHeight } = callListRef.current;
          if (scrollTop + clientHeight >= scrollHeight - 5 && hasMore && !loading) {
            // Reaching the bottom, load more data
            setPage((prevPage) => prevPage + 1);
            loadCalls(page + 1);
          }
        }
    };

    const makeCall = (call) => {
        setInitiateCall(true);
        setCallingOrder({
            "number": call.clientNumber,
            "id": call?.order?.id,
            "name": call?.order?.name,
          });
    };

    useEffect(() => {
        const currentRef = callListRef.current;
        if (currentRef) {
          currentRef.addEventListener('scroll', handleScroll);
        }
    
        return () => {
          if (currentRef) {
            currentRef.removeEventListener('scroll', handleScroll);
          }
        };
      }, [callListRef, handleScroll]);

    const formatCallDetails = (call) => {
        const type = call.type === 'incoming' ? 'Incoming' : 'Outgoing';
        const formattedDate = moment(call.created_at).format('MMMM Do YYYY, h:mm:ss a');
        return `Client Number: ${call.clientNumber} | Agent: ${call.agent_name} | Own Number: ${call.callingNumber} | ${type} | ${formattedDate}`;
      };

    const handleTab = (tab) => {
        setActiveTab(tab);
    }


    return (
        <>
            {initiateCall && <VoiceComponent orderCall={callingOrder} setSelectedOrder={setCallingOrder} />}
            
            <div className="calls_tabs">
                <button disabled={loading} onClick={() => handleTab('calls')} className={activeTab === 'calls' ? 'active' : ''}>
                Calls
                </button>
                <button disabled={loading} onClick={() => handleTab('missed')} className={activeTab === 'missed' ? 'active' : ''}>
                Missed Calls
                </button>
            </div>
            
            <div className="tab-content">
                {activeTab === 'calls' && (
                <div className="call-list-container" ref={callListRef}>
                    {calls?.length > 0 ? (
                    <ul className='call-list'>
                        {calls?.map((call, index) => (
                        <li key={index} className="call-item">
                            <div>
                                <p><strong>{call?.order?.name ? call?.order?.name : "UnKnown"}</strong></p>
                                <p>{formatCallDetails(call)}</p>
                            </div>
                            {call.recording_file && (
                                <div className="audio-player">
                                    <audio controls>
                                        <source src={call.recording_file} type="audio/mp3" />
                                        Your browser does not support the audio element.
                                    </audio>
                                </div>
                            )}
                            <div>
                                <p style={{ color: getCallStatusColor(call.status) }}>
                                    {call.duration ? formatDuration(call.duration) : "00:00:00"}
                                </p>
                                {renderCallIcon(call)}
                                <CallSharpIcon style={{cursor: "pointer"}} onClick={() => makeCall(call)} />
                            </div>
                        </li>
                        ))}
                    </ul>
                    ) : (
                    <p>No calls available.</p>
                    )}
                </div>
                )}

                {activeTab === 'missed' && (
                <div className="call-list-container" ref={callListRef}>
                    {calls?.length > 0 ? (
                    <ul>
                        {calls?.map((call, index) => (
                        <li key={index} className="call-item">
                            <div>
                                <p><strong>{call?.order?.name ? call?.order?.name : "UnKnown"}</strong> {call.missed && !call.attend && (
                                    <span style={{ color: red[500], fontWeight: 'bold' }}>(Return Call Needed)</span>
                                )}</p>
                                <p>{formatCallDetails(call)}</p>
                            </div>
                            <div>
                                <p style={{ color: getCallStatusColor(call.status) }}>
                                    {call.duration ? formatDuration(call.duration) : "00:00:00"}
                                </p>
                                <PhoneMissedIcon style={{ color: getCallStatusColor(call.status) }} />
                                <CallSharpIcon style={{cursor: "pointer"}} onClick={() => makeCall(call)} />
                            </div>
                        </li>
                        ))}
                    </ul>
                    ) : (
                    <p>No missed calls available.</p>
                    )}
                </div>
                )}
            </div>
        </>
    );
}

export default CallList;
