import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import ForwardIcon from '@mui/icons-material/Forward';

import { 
  EXPENSE_LIST,

 } from "../../../utils/apiUrls";
import {
  getApiWithAuth,
} from "../../../utils/api";

import ExpenseObject from '../../MiniComponents/ExpenseObject';
import './expenseList.css'

function ExpenseList() {
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [search, setSearch] = useState("");

  const navigate = useNavigate();
  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const inputValue = e.target.value;
      setSearch(inputValue);
      }
    }
  const getExpenses = async () => {
    try {
      setLoading(true);
  
      const response = await getApiWithAuth(EXPENSE_LIST + `?page=${1}&search=${search}`);
  
      if (response.data !== undefined) {
        const driversData = response.data.data;
        setDrivers(driversData)
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
 
  useEffect(() => {
    getExpenses();
  }, [search]);


  useEffect(() => {
    const inputElement = document.getElementById('customDatePicker');

    if (inputElement) {
      inputElement.addEventListener('click', () => {
        inputElement.click();
      });
    }
  }, []);

  const handleDelete = () => {
    getExpenses();
  };
  
  return (
    <div >
      <div className="second-row">
        <div className="filter-container">
          <div className="route-search-container">
            <label htmlFor="search">Search:</label>
            <input
              type="text"
              id="slug"
              autoComplete="off"
              onKeyDown={handleSearchKeyDown}
              placeholder="Name, Email, Contact"
              className="search-input"
            />
          </div>
        </div>
        </div>
      <div className='list-head'>
        <h2 className='listHeader'>Expenses List</h2>
        <button className='driver-add-btn' onClick={() =>{
           navigate("/add/expense")
           }}>Add Expense</button>
      </div>
      <div className='driver-obj'>
        {drivers?.length > 0 &&
          drivers.map((driver) => (
            <ExpenseObject driver={driver} onDelete={handleDelete} />
          ))
        }
      </div>
    </div>
  )
}

export default ExpenseList