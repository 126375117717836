import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router";
import axios from "axios";
import "./addExpense.css";
import { ADD_EXPENSE } from "../../../utils/apiUrls";
import { postApiWithAuth } from "../../../utils/api";

function AddExpense() {
  const navigate = useNavigate();
  const [expenseDetail, setExpenseDetail] = useState({
    expense_type: "",
    payment_type: "",
    name: "",
    amount: "",
    expense_file: null, // Add file field
  });

  const [fieldErrors, setFieldErrors] = useState({
    expense_type: '',
    payment_type: '',
    name: '',
    amount: '',
    expense_file: '', // Add file field error
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setExpenseDetail({
      ...expenseDetail,
      [name]: value,
    });
    setFieldErrors({ ...fieldErrors, [name]: '' });
  };

  const handleFileChange = (event) => {
    setExpenseDetail({
      ...expenseDetail,
      expense_file: event.target.files[0], // Set selected file
    });
    setFieldErrors({ ...fieldErrors, expense_file: '' });
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!expenseDetail.expense_type) {
      errors.expense_type = 'Expense Type is required';
      isValid = false;
    }
    if (!expenseDetail.payment_type) {
      errors.payment_type = 'Payment Type is required';
      isValid = false;
    }
    if (!expenseDetail.name) {
      errors.name = 'Name is required';
      isValid = false;
    }
    if (!expenseDetail.amount) {
      errors.amount = 'Amount is required';
      isValid = false;
    }
    if (!expenseDetail.expense_file) {
      errors.expense_file = 'Expense file is required';
      isValid = false;
    }

    setFieldErrors(errors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      const formData = new FormData();
      formData.append('expense_type', expenseDetail.expense_type);
      formData.append('payment_type', expenseDetail.payment_type);
      formData.append('name', expenseDetail.name);
      formData.append('amount', expenseDetail.amount);
      formData.append('expense_file', expenseDetail.expense_file);

      try {
        const response = await postApiWithAuth(ADD_EXPENSE, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response) {
          navigate("/list/expense");
        }
      } catch (error) {
        console.error("Error adding expense:", error);
      }
    }
  };

  return (
    <div>
      <Grid container className="form-grid">
        <Grid item className="drive-grid" xs={2}>
          <div className="form-sec-head-driver margin-top-heading">
            Expense Information
          </div>
        </Grid>
        <Grid container className="form-grid" spacing={2}>
          <Grid item className="drive-grid" xs={4}>
            <label className="driver-field-label">Expense Type</label>
            <select
              className="driver-field"
              name="expense_type"
              value={expenseDetail.expense_type}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Type</option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
            </select>
            {fieldErrors.expense_type && <span className="error-message">{fieldErrors.expense_type}</span>}
          </Grid>
          <Grid item className="drive-grid" xs={4}>
            <label className="driver-field-label">Payment Type</label>
            <select
              className="driver-field"
              name="payment_type"
              value={expenseDetail.payment_type}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Type</option>
              <option value="Payment In">Payment In</option>
              <option value="Payment Out">Payment Out</option>
            </select>
            {fieldErrors.payment_type && <span className="error-message">{fieldErrors.payment_type}</span>}
          </Grid>
          <Grid item className="drive-grid" xs={4}>
            <label className="driver-field-label">Name</label>
            <input
              type="text"
              className="driver-field"
              name="name"
              placeholder="Name"
              value={expenseDetail.name}
              onChange={handleInputChange}
              required
            />
            {fieldErrors.name && <span className="error-message">{fieldErrors.name}</span>}
          </Grid>
          <Grid item className="drive-grid" xs={4}>
            <label className="driver-field-label">Amount</label>
            <input
              type="number"
              className="driver-field"
              name="amount"
              placeholder="Amount"
              value={expenseDetail.amount}
              onChange={handleInputChange}
              required
            />
            {fieldErrors.amount && <span className="error-message">{fieldErrors.amount}</span>}
          </Grid>
          <Grid item className="drive-grid" xs={4}>
            <label className="driver-field-label">Expense File</label>
            <input
              type="file"
              className="driver-field"
              name="expense_file"
              onChange={handleFileChange}
              required
            />
            {fieldErrors.expense_file && <span className="error-message">{fieldErrors.expense_file}</span>}
          </Grid>
        </Grid>
      </Grid>
      <div className="driver-btn">
        <button type="submit" onClick={handleSubmit} className="save-driver">
          Save Expense
        </button>
      </div>
    </div>
  );
}

export default AddExpense;
