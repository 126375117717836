import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router";
import {
  Toolbar,
  Box,
  IconButton,
  Typography,
  Avatar,
  Button,
  Badge,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
  Modal, Fade, Backdrop
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import { getApiWithAuth, postApiWithAuth } from "../../../utils/api";

import {
  AccountCircle,
  Notifications,
  Message,
  Flag,
  NotificationsActive,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, Outlet } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";

import { removeToken } from "../../../utils/localStorage";

import {
  chatTop,
  notification,
  bell,
  text,
  flag,
  announce,
} from "../../../assests";
import "./navbar.css";
import NotificationDropdown from "./NotificationDropdown/NotificationDropdown";
import MessagesDropdown from "./MessagesDropdown/MessagesDropdown";
import NewUpdatesDropdown from "./NewUpdatesDropdown/NewUpdatesDropdown"
import NewOrdersDropdown from "./NewOrdersDropdown/NewOrdersDropdown";
import TicketDropdown from "./TicketDropdown/TicketDropdown";
import NewSpeakDropdown from "./NewSpeakDropdown/NewSpeakDropdown";
import moment from "moment";
import { UPDATE_PROFILE_PIC } from "../../../utils/apiUrls";
import TwilioSMSDropDown from "./TwilioSMSDropDown/TwilioSMSDropDown";
import TwilloMissedCalls from "./TwilloMissedCalls/TwilloMissedCalls";

const drawerWidth = 240;
const userType = localStorage.getItem("userType");
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer - 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  height: "84px",
}));

const Navbar = () => {
  // Define state and functions for the dropdown menu
  const [currentDate, setCurrentDate] = useState(moment(new Date()).format("MM-DD-YYYY"));
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedProfilePic, setSelectedProfilePic] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNavDropdownsOpen, setIsNavDropdownsOpen] = useState({
    missedCalls: false,
    twilioSMS: false,
    tickets: false,
    newOrders: false,
    newUpdates: false,
    messages: false,
    notifications: false,
  })

  const navigate = useNavigate();


  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const handleOpenModal = () => {
    // Close all other dropdowns or modals
    setIsNavDropdownsOpen({
      missedCalls: false,
      twilioSMS: false,
      tickets: false,
      newOrders: false,
      newUpdates: false,
      messages: false,
      notifications: false,
    });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    setSelectedProfilePic(profilePic);
  }, []); // Depend on profilePic changes
  
  const handleProfilePicChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      console.log("Selected file:", file);

      // Create FormData object to send file
      const formData = new FormData();
      formData.append("profile_pic", file); 
      handleCloseModal();
      try {
        // Call API to update profile picture
        const response = await postApiWithAuth(UPDATE_PROFILE_PIC, formData);
        
        console.log("Response:", response);

        if (response.data ) {
          // Update local storage or state with new profile picture URL
          const newProfilePicUrl = response.data.data.user.profile_pic; // Replace with actual key from response
          setSelectedProfilePic(newProfilePicUrl);
          localStorage.setItem("profilePic", response.data.data.user.profile_pic);
          console.log("Profile picture updated successfully");
        } else {
          console.log("Failed to update profile picture:", response);
        }
      } catch (error) {
        console.error("Error updating profile picture:", error);
      }
    }
  };
  const handleLogoutMenu = () => {
    removeToken();
    localStorage.removeItem("userType");
    localStorage.removeItem("route_startaddress");
    localStorage.removeItem("pharmacyId");
    localStorage.removeItem("userDetail");
    localStorage.removeItem("profilePic");
    localStorage.removeItem("deviceId");
    localStorage.removeItem("tokenAdded");
    localStorage.removeItem("show_report");
    localStorage.removeItem("show_team");
    navigate("/");
  };

  const name = localStorage.getItem("userDetail")
  const profilePic = localStorage.getItem("profilePic")

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        className="appBar"
        open={isSidebarOpen}
        position="fixed"
        style={{ backgroundColor: "#0B6699" , justifyContent: 'center', height: 50}}
      >
        <Toolbar>
          <div style={{ flex: 1, textAlign: "left", color:'white',marginLeft:'70px' }}>
            <p className="subtitle1">{currentDate}</p>
          </div>
          
          {/* {(userType === "Admin" || userType === "Staff" || userType === "SubAdmin") && <TwilloMissedCalls isNavDropdownsOpen={isNavDropdownsOpen} setIsNavDropdownsOpen={setIsNavDropdownsOpen}/> }
          {(userType === "Admin" || userType === "Staff" || userType === "SubAdmin") && <TwilioSMSDropDown isNavDropdownsOpen={isNavDropdownsOpen} setIsNavDropdownsOpen={setIsNavDropdownsOpen}/> } */}
          <TicketDropdown isNavDropdownsOpen={isNavDropdownsOpen} setIsNavDropdownsOpen={setIsNavDropdownsOpen}/>
          <NewSpeakDropdown isNavDropdownsOpen={isNavDropdownsOpen} setIsNavDropdownsOpen={setIsNavDropdownsOpen}/> 
          <NewUpdatesDropdown isNavDropdownsOpen={isNavDropdownsOpen} setIsNavDropdownsOpen={setIsNavDropdownsOpen}/>
          <NotificationDropdown isNavDropdownsOpen={isNavDropdownsOpen} setIsNavDropdownsOpen={setIsNavDropdownsOpen}/> 
          <Button
          onClick={handleOpenModal}
            color="inherit"
            startIcon={<img src={profilePic} style={{height: "25px", width: "25px", borderRadius: '50%', margin: '5px'}} />}
          >
            {name}
          </Button>
        </Toolbar>
      </AppBar>

      <nav>
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      </nav>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
     
      >
        <Box
          sx={{
            backgroundColor: "white",
            boxShadow: 24,
            padding: 2,
            maxWidth: 300,
            marginLeft: "80%",
            marginTop: "03%",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h6" id="transition-modal-title" align="center">
            Select Profile Picture
          </Typography>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="contained-button-file"
            multiple
            type="file"
            onChange={handleProfilePicChange}
          />
          <label htmlFor="contained-button-file">
            <Button variant="contained" component="span" fullWidth>
              Upload Picture
            </Button>
          </label>
        </Box>
      </Modal>
      <Box
        component="main"
        sx={{ flexGrow: 1, pt: 3, pl: 3, pr: 3 }}
        style={{ marginTop: "55px", maxWidth: "100%", paddingTop: "10px" }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default Navbar;
