import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import useMediaQuery from "@mui/material/useMediaQuery";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { useTheme } from "@mui/material/styles";
import { Checkbox } from "@mui/material";
import InputMask from "react-input-mask";
import {
  DRIVERS_API_2,
  vehicle_API_2,
  Bank_API_2,
  Doc_API_2,
  VEHICLE_API,
} from "../../../utils/apiUrls";
import {
  postApiWithAuth,
  getApiWithAuth,
  postFormDataAPI,
} from "../../../utils/api";
import { useNavigate } from "react-router";

import "./addDriver.css";

function AddDriver() {
  const [backendimg, setbackendimg] = useState(
    "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
  );
  const [idImage, setIdImage] = useState(
    "https://cdn2.vectorstock.com/i/1000x1000/37/61/id-card-icon-male-user-person-profile-avatar-vector-23703761.jpg"
  );
  const [idImage3, setIdImage3] = useState(
    "https://cdn2.vectorstock.com/i/1000x1000/37/61/id-card-icon-male-user-person-profile-avatar-vector-23703761.jpg"
  );
  const [Front, setFront] = useState("");
  const [Fronts, setFronts] = useState("");
  const [Back, setBack] = useState("");
  const [loading, setLoading] = useState(false);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [personalDetail, setPersonalDetail] = useState({
    firstName: "",
    lastName: "",
    address: "",
    // "createdAt": "2023-11-08",
    date: "",
    phoneNumber: "",
    cellNumber: "",
    email: "",
    socialSecurityNo: "",
    decument: "",
    additionInfo: "",
    licenseId: "",
    licenseIssued: "",
    licenseExpired: "",
    isactive: true,
    shift1Price: 0,
    shift2Price: 0,
    shift3Price: 0,
    failedDeliveryPrice: 0,
    urgentDeliveryPrice: 0,
    addtionalNote: "",
    name: "",
    profilefile: null,
    driver: null,
    // createdby: null,
    password: "",
    confirmPassword: "",
    salaryType: null,
    id1card: "",
    id2card: "",
    allow_reordering : true,
  });

  const [bankDetails, setBankDetails] = useState({
    id: 0,
    bankname: "",
    accountholdername: "",
    accountno: "",
    achRouting: "",
  });

  const [vehicleDetail, setVehicleDetail] = useState({
    id: 0,
    make: "",
    model: "",
    color: "",
    modelYear: "",
    plateNo: "",
    price: 53454.0,
    documentfile: null,
    vehicleType: null,
  });

  const [fieldErrors, setFieldErrors] = useState({
    address: '',
    phoneNumber: '',
    cellNumber: '',
    email: '',
    firstName: '',
    lastName: '',
    password:"",
    confirmPassword:"",
  });

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const getVehicle = async () => {
    setLoading(true);

    const response = await getApiWithAuth(VEHICLE_API);

    if (response.data !== undefined) {
      setVehicleTypes(response.data.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handlePersonalInputChange = (event) => {
    const { name, value } = event.target;
    setPersonalDetail({
      ...personalDetail,
      [name]: value,
    });

    setFieldErrors({ ...fieldErrors, [name]: '' });
  };

  const handleBankInputChange = (event) => {
    const { name, value } = event.target;
    setBankDetails({
      ...bankDetails,
      [name]: value,
    });
  };

  const handleVehicleInputChange = (event) => {
    const { name, value } = event.target;
    setVehicleDetail({
      ...vehicleDetail,
      [name]: value,
    });
  };
  const handlePersonalInputChangeChecked = (event) => {
    const { name, checked } = event.target;
    console.log("namevalue",name,checked)
    setPersonalDetail(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    getVehicle();
  }, []);

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    // Check if fields are empty
    if (!personalDetail.address) {
      errors.address = 'Address is required';
      isValid = false;
    }
    if (!personalDetail.phoneNumber) {
      errors.phoneNumber = 'Phone Number is required';
      isValid = false;
    }
    if (!personalDetail.cellNumber) {
      errors.cellNumber = 'Cell Number is required';
      isValid = false;
    }
    if (!personalDetail.email) {
      errors.email = 'Email is required';
      isValid = false;
    }
    if (!personalDetail.firstName) {
      errors.firstName = 'First name is required';
      isValid = false;
    }
    if (!personalDetail.lastName) {
      errors.lastName = 'Last name is required';
      isValid = false;
    }
    if (!personalDetail.password) {
      errors.password = 'Password is required';
      isValid = false;
    }
    if (!personalDetail.confirmPassword) {
      errors.confirmPassword = 'Confirm Password is required';
      isValid = false;
    }
    if (!personalDetail.shift1Price) {
      errors.shift1Price = '9:00 AM To 3:00 PM Price is required';
      isValid = false;
    }
    if (!personalDetail.shift2Price) {
      errors.shift2Price = '2:00 PM To 6:00 PM Price is required';
      isValid = false;
    }
    if (!personalDetail.shift3Price) {
      errors.shift3Price = '5:00 PM To 9:00 PM Price is required';
      isValid = false;
    }
    if (!personalDetail.failedDeliveryPrice) {
      errors.failedDeliveryPrice = 'Failed Delivery Price is required';
      isValid = false;
    }
    if (!personalDetail.urgentDeliveryPrice) {
      errors.urgentDeliveryPrice = 'Urgent Delivery Price is required';
      isValid = false;
    }
    setFieldErrors(errors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      const updatedPersonalDetail = {
        ...personalDetail,
        name: personalDetail.firstName + " " + personalDetail.lastName,
      };

      let payload = {
        ...updatedPersonalDetail,
      };
      const responsePersonal = await postFormDataAPI(DRIVERS_API_2, payload);
      
      if (responsePersonal !== undefined) {
        if ( vehicleDetail.make !== ""){
          let vehicle_payload = new FormData();
          vehicle_payload.append("make", vehicleDetail.make);
          vehicle_payload.append("model", vehicleDetail.model);
          vehicle_payload.append("color", vehicleDetail.color);
          vehicle_payload.append("modelYear", vehicleDetail.modelYear);
          vehicle_payload.append("plateNo", vehicleDetail.plateNo);
          vehicle_payload.append("price", vehicleDetail.price);
          // vehicle_payload.append("documentfile", vehicleDetail.documentfile);
          // vehicle_payload.append("vehicleType", vehicleDetail.vehicleType);
          // vehicle_payload.append("createdby", responsePersonal.data.data.id);
          vehicle_payload.append("driver", responsePersonal.id);
          const vehiclePersonal = await postApiWithAuth(
            vehicle_API_2,
            vehicle_payload
          );
        }
        if ( bankDetails.bankname !== ""){
          let payloadBank = new FormData();
          payloadBank.append("bankname", bankDetails.bankname);
          payloadBank.append("accountholdername", bankDetails.accountholdername);
          payloadBank.append("accountno", bankDetails.accountno);
          payloadBank.append("achRouting", bankDetails.achRouting);
          payloadBank.append("driver", responsePersonal.id);
          const responseBank = await postApiWithAuth(Bank_API_2, payloadBank);
        }
        let document_payload = new FormData();
        document_payload.append("description", "descriptions");

        document_payload.append("driver", responsePersonal.id);
        const docBank = await postApiWithAuth(Doc_API_2, document_payload);
        navigate("/list/driver");
      } else {
        console.log("Backend Error");
      }
    }
  };

  const handleEditClickkk = () => {
    document.getElementById("imageInput").click();
  };

  const setFrontImage = (imageData) => {
    setPersonalDetail({
      ...personalDetail,
      id1card: imageData,
    });
  };

  const setBackImage = (imageData) => {
    setPersonalDetail({
      ...personalDetail,
      id2card: imageData,
    });
  };

  const handleImageUploaddd = (event) => {
    const fileObject = event.target.files[0];
    setFrontImage(fileObject);

    // Extract the file data from the object
    const { name, size, type, lastModified } = fileObject;

    // Create a Blob from the file data
    const fileBlob = new Blob([fileObject], { type });

    // Create a File instance
    const file = new File([fileBlob], name, { lastModified });
    // setFrontImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFront(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEditClickkk2 = () => {
    document.getElementById("imageInput2").click();
  };

  const handleImageUploaddd2 = (event) => {
    const fileObject = event.target.files[0];
    setBackImage(fileObject);
    // Extract the file data from the object
    const { name, size, type, lastModified } = fileObject;
    const fileBlob = new Blob([fileObject], { type });
    const file = new File([fileBlob], name, { lastModified });
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBack(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEditClickk = () => {
    document.getElementById("imageInputt").click();
  };

  const handleImageUploadds = (event) => {
    const fileObject = event.target.files[0];
    setPersonalDetail({
      ...personalDetail,
      profilefile: fileObject,
    });
    const { name, size, type, lastModified } = fileObject;
    const fileBlob = new Blob([fileObject], { type });
    const file = new File([fileBlob], name, { lastModified });

    if (file) {
      const reader = new FileReader();
      // Read the contents of the image file as a data URL
      reader.onloadend = () => {
        // Set the image URL in the state
        setFronts(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <Grid container className="form-grid">
        <Grid item className="drive-grid" xs={3}>
          <div className="form-sec-head-driver margin-top-heading">
            Driver Information
          </div>
        </Grid>{" "}
        <Grid item className="drive-grid ml-5" xs={3}></Grid>
        <Grid item className="drive-grid " xs={1.5}>
          {/* Hidden file input triggered by the EditIcon click */}
          <input
            autoComplete="off"
            type="file"
            id="imageInputt"
            style={{ display: "none" }}
            accept="image/*"
            onChange={handleImageUploadds}
          />

          {Fronts ? (
            <img
              src={Fronts}
              style={{ borderRadius: "100%", height: "50px", width: "50px" }}
              className="id-image"
              alt="Image 1"
            />
          ) : (
            <img
              src={backendimg}
              style={{ borderRadius: "100%", height: "50px", width: "50px" }}
              className="id-image"
              alt="Image 1"
            />
          )}
          <div className="img-icon" onClick={handleEditClickk}>
            {/* Render your EditIcon component here */}
            <EditIcon />
          </div>
        </Grid>
        <Grid item className="drive-grid " style={{ marginLeft: "-30px" }}>
          <div className="img-icon" onClick={handleEditClickk}>
            {/* Render your EditIcon component here */}
          </div>
        </Grid>
      </Grid>
      <Grid container className="form-grid" spacing={2}>
        {/* First Row */}
        <Grid item className="drive-grid" xs={3}>
          <label className="driver-field-label">First Name</label>
          <input
            autoComplete="off"
            type="text"
            className="driver-field"
            name="firstName"
            placeholder="First Name"
            value={personalDetail.firstName}
            onChange={handlePersonalInputChange}
            required
          />
          {fieldErrors.firstName && <span className="error-message">{fieldErrors.firstName}</span>}
        </Grid>
        <Grid item className="drive-grid" xs={3}>
          <label className="driver-field-label">Last Name</label>
          <input
            autoComplete="off"
            type="text"
            className="driver-field"
            name="lastName"
            placeholder="Last Name"
            value={personalDetail.lastName}
            onChange={handlePersonalInputChange}
            required
          />
          {fieldErrors.lastName && <span className="error-message">{fieldErrors.lastName}</span>}
        </Grid>
        <Grid item className="drive-grid" xs={6}>
          <label className="driver-field-label">Address</label>
          <input
            autoComplete="off"
            type="text"
            className="driver-field"
            name="address"
            placeholder="Address"
            value={personalDetail.address}
            onChange={handlePersonalInputChange}
          />
          {fieldErrors.address && <span className="error-message">{fieldErrors.address}</span>}
        </Grid>

        {/* Second Row */}
        <Grid item className="drive-grid" xs={3}>
          <label className="driver-field-label">Date of Birth</label>
          <input
            autoComplete="off"
            type="date"
            className="driver-field"
            name="date"
            placeholder="YYYY/MM/DD"
            value={personalDetail.date}
            onChange={handlePersonalInputChange}
          />
        </Grid>

<Grid item className="drive-grid" xs={3}>
  <label className="driver-field-label">Phone Number</label>
  <InputMask
    mask="(999) 999-9999"
    value={personalDetail.phoneNumber}
    onChange={handlePersonalInputChange}
  >
    {() => (
      <input
        autoComplete="off"
        type="text"
        className="driver-field"
        name="phoneNumber"
        placeholder="Phone Number"
      />
    )}
  </InputMask>
  {fieldErrors.phoneNumber && <span className="error-message">{fieldErrors.phoneNumber}</span>}
</Grid>

<Grid item className="drive-grid" xs={3}>
  <label className="driver-field-label">Cell Number</label>
  <InputMask
    mask="(999) 999-9999"
    value={personalDetail.cellNumber}
    onChange={handlePersonalInputChange}
  >
    {() => (
      <input
        autoComplete="off"
        type="text"
        className="driver-field"
        name="cellNumber"
        placeholder="Cell Number"
      />
    )}
  </InputMask>
  {fieldErrors.cellNumber && <span className="error-message">{fieldErrors.cellNumber}</span>}
</Grid>

        <Grid item className="drive-grid" xs={3}>
          <label className="driver-field-label">Email</label>
          <input
            autoComplete="off"
            type="email"
            className="driver-field"
            name="email"
            placeholder="Email"
            value={personalDetail.email}
            onChange={handlePersonalInputChange}
            required
          />
           {fieldErrors.email && <span className="error-message">{fieldErrors.email}</span>}
        </Grid>

        {/* Third& Forth Row */}
        <Grid item className="drive-grid" xs={6} md={6}>
          <Grid container spacing={1}>
            {/* Four Fields in a 3-3-3-3 Configuration */}
            <Grid item className="drive-in-grid drive-sec-grid" xs={6} md={6}>
              <label className="driver-field-label">
                Social Security Number
              </label>
              <input
                autoComplete="off"
                type="text"
                className="driver-field"
                name="socialSecurityNo"
                placeholder="Number"
                value={personalDetail.socialSecurityNo}
                onChange={handlePersonalInputChange}
              />
            </Grid>
            <Grid item className="drive-grid-2" xs={6} md={6}>
              <label className="driver-field-label">Driver License ID</label>
              <input
                autoComplete="off"
                type="text"
                className="driver-field"
                name="licenseId"
                placeholder="Driver License ID"
                value={personalDetail.licenseId}
                onChange={handlePersonalInputChange}
              />
            </Grid>
            <Grid item className="drive-in-grid drive-sec-grid" xs={6} md={6}>
              <label className="driver-field-label">License Issued</label>
              <input
                autoComplete="off"
                type="text"
                className="driver-field"
                name="licenseIssued"
                placeholder="License Issued"
                value={personalDetail.licenseIssued}
                onChange={handlePersonalInputChange}
              />
            </Grid>
            <Grid item className="drive-grid-2" xs={6} md={6}>
              <label className="driver-field-label">License Expires</label>
              <input
                autoComplete="off"
                type="text"
                className="driver-field"
                name="licenseExpired"
                placeholder="License Expired"
                value={personalDetail.licenseExpired}
                onChange={handlePersonalInputChange}
              />
            </Grid>
            <Grid item className="drive-in-grid drive-sec-grid" xs={6} md={6}>
              <label className="driver-field-label">Password</label>
              <input
                autoComplete="off"
                type={showPassword ? "text" : "password"}
                className="driver-field"
                name="password"
                // placeholder="Password"
                value={personalDetail.password}
                onChange={handlePersonalInputChange}
              />
              {fieldErrors.password && <span className="error-message">{fieldErrors.password}</span>}
            </Grid>
            <Grid item className="drive-grid-2" xs={6} md={6}>
              <label className="driver-field-label">Confirm Password</label>
              <div style={{display: 'flex'}}>
                <input
                  autoComplete="off"
                  type={showPassword ? "text" : "password"}
                  className="driver-field"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={personalDetail.confirmPassword}
                  onChange={handlePersonalInputChange}
                />
                {fieldErrors.confirmPassword && <span className="error-message">{fieldErrors.confirmPassword}</span>}
                <button
                // className="show-hide-password"
                onClick={togglePasswordVisibility}
                >
                {showPassword 
                ? 
                  <VisibilityOffIcon />
                :
                  <RemoveRedEyeIcon />
                }
              </button>
              </div>
            </Grid>
          </Grid>
        </Grid>

        {/* Right Grid */}
        <Grid item className="drive-grid" xs={6} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className="img-sec-driver">
                <p>License Front</p>
                <div className="img-icon" onClick={handleEditClickkk}>
                  <EditIcon />
                </div>
                {/* Hidden file input triggered by the EditIcon click */}
                <input
                  autoComplete="off"
                  type="file"
                  id="imageInput"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={handleImageUploaddd}
                />
              </div>
              {Front ? (
                <img src={Front} className="id-image" alt="Image 1" />
              ) : (
                <img src={idImage} className="id-image" alt="Image 1" />
              )}
            </Grid>
            <Grid item xs={6}>
              <div className="img-sec-driver">
                <p>License Back</p>
                <div className="img-icon" onClick={handleEditClickkk2}>
                  {/* Render your EditIcon component here */}
                  <EditIcon />
                </div>
                {/* Hidden file input triggered by the EditIcon click */}
                <input
                  autoComplete="off"
                  type="file"
                  id="imageInput2"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={handleImageUploaddd2}
                />
              </div>
              {Back ? (
                <img src={Back} className="id-image" alt="Image 1" />
              ) : (
                <img src={idImage3} className="id-image" alt="Image 1" />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div className="form-sec-head-driver">Banking Details</div>
      <Grid container className="form-grid" spacing={2}>
        {/* First Row */}
        <Grid item className="drive-grid" xs={3}>
          <label className="driver-field-label">Bank Name</label>
          <input
            autoComplete="off"
            type="text"
            className="driver-field"
            name="bankname"
            placeholder="Bank Name"
            value={bankDetails.bankname}
            onChange={handleBankInputChange}
          />
        </Grid>
        <Grid item className="drive-grid" xs={3}>
          <label className="driver-field-label">Title on Account</label>
          <input
            autoComplete="off"
            type="text"
            className="driver-field"
            name="accountholdername"
            placeholder="Title on Account"
            value={bankDetails.accountholdername}
            onChange={handleBankInputChange}
          />
        </Grid>

        {/* Second Row */}
        <Grid item className="drive-grid" xs={3}>
          <label className="driver-field-label">Account Number</label>
          <input
            autoComplete="off"
            type="text"
            className="driver-field"
            name="accountno"
            placeholder="Account Number"
            value={bankDetails.accountno}
            onChange={handleBankInputChange}
          />
        </Grid>
        <Grid item className="drive-grid" xs={3}>
          <label className="driver-field-label">ACH Routing</label>
          <input
            autoComplete="off"
            type="text"
            className="driver-field"
            name="achRouting"
            placeholder="ACH Routing"
            value={bankDetails.achRouting}
            onChange={handleBankInputChange}
          />
        </Grid>
      </Grid>

      <div className="form-sec-head-driver">Vehicle Information</div>
      <Grid container className="form-grid" spacing={2}>
        {/* First Row */}
        {/* <Grid item className="drive-grid" xs={2}>
          <label className="driver-field-label">Type</label>
          <input
                autoComplete="off"
            type="text"
            className="driver-field"
            name="vehicleType"
            placeholder="Type"
            // value={1}
            value={vehicleDetail.vehicleType}
            onChange={handleVehicleInputChange}
          />
        </Grid> */}
        <Grid item className="drive-grid" xs={2}>
          <label className="driver-field-label">Type</label>
          <select
            className="driver-field"
            name="vehicleType"
            value={vehicleDetail.vehicleType || ""} // Added a fallback to empty string to handle null or undefined
            onChange={handleVehicleInputChange}
          >
            {vehicleTypes.map((item, index) => (
              <option key={index} value={item.id}>
                {item.type}
              </option>
            ))}
          </select>
        </Grid>
        <Grid item className="drive-grid" xs={2}>
          <label className="driver-field-label">Make</label>
          <input
            autoComplete="off"
            type="text"
            className="driver-field"
            name="make"
            placeholder="Make"
            value={vehicleDetail.make}
            onChange={handleVehicleInputChange}
          />
        </Grid>
        <Grid item className="drive-grid" xs={2}>
          <label className="driver-field-label">Model</label>
          <input
            autoComplete="off"
            type="text"
            className="driver-field"
            name="model"
            placeholder="Model"
            value={vehicleDetail.model}
            onChange={handleVehicleInputChange}
          />
        </Grid>
        <Grid item className="drive-grid" xs={2}>
          <label className="driver-field-label">Color</label>
          <input
            autoComplete="off"
            type="text"
            className="driver-field"
            name="color"
            placeholder="Color"
            value={vehicleDetail.color}
            onChange={handleVehicleInputChange}
          />
        </Grid>
        <Grid item className="drive-grid" xs={2}>
          <label className="driver-field-label">Model Year</label>
          <input
            autoComplete="off"
            type="text"
            className="driver-field"
            name="modelYear"
            placeholder="Model Year"
            value={vehicleDetail.modelYear}
            onChange={handleVehicleInputChange}
          />
        </Grid>
        <Grid item className="drive-grid" xs={2}>
          <label className="driver-field-label">Plate Number</label>
          <input
            autoComplete="off"
            type="text"
            className="driver-field"
            name="plateNo"
            placeholder="Plate Number"
            value={vehicleDetail.plateNo}
            onChange={handleVehicleInputChange}
          />
        </Grid>
      </Grid>

      <div className="form-sec-head-driver">Pay Rates</div>
      <Grid container className="form-grid" spacing={2}>
        {/* First Row */}
        <Grid item className="drive-grid" xs={3}>
          <label className="driver-field-label">9:00 AM To 3:00 PM</label>
          <input
            autoComplete="off"
            type="number"
            step="0.01"
            className="driver-field"
            name="shift1Price"
            placeholder="price"
            value={personalDetail.shift1Price}
            onChange={handlePersonalInputChange}
            required
          />
             {fieldErrors.shift1Price && <span className="error-message">{fieldErrors.shift1Price}</span>}
        </Grid>
        <Grid item className="drive-grid" xs={3}>
          <label className="driver-field-label">2:00 PM To 6:00 PM</label>
          <input
            autoComplete="off"
            type="number"
            step="0.01"
            className="driver-field"
            name="shift2Price"
            placeholder="price"
            value={personalDetail.shift2Price}
            onChange={handlePersonalInputChange}
            required
          />
          {fieldErrors.shift2Price && <span className="error-message">{fieldErrors.shift2Price}</span>}
        </Grid>

        {/* Second Row */}
        <Grid item className="drive-grid" xs={3}>
          <label className="driver-field-label">5:00 PM To 9:00 PM</label>
          <input
            autoComplete="off"
            type="number"
            step="0.01"
            className="driver-field"
            name="shift3Price"
            placeholder="price"
            value={personalDetail.shift3Price}
            onChange={handlePersonalInputChange}
            required
          />
           {fieldErrors.shift3Price && <span className="error-message">{fieldErrors.shift3Price}</span>}
        </Grid>
        <Grid item className="drive-grid" xs={3}>
          <label className="driver-field-label">Failed Delivery</label>
          <input
            autoComplete="off"
            type="number"
            step="0.01"
            className="driver-field"
            name="failedDeliveryPrice"
            placeholder="Failed Delivery"
            value={personalDetail.failedDeliveryPrice}
            onChange={handlePersonalInputChange}
            required
          />
             {fieldErrors.failedDeliveryPrice && <span className="error-message">{fieldErrors.failedDeliveryPrice}</span>}
        </Grid>
        <Grid item className="drive-grid" xs={3}>
          <label className="driver-field-label">Urgent Delivery</label>
          <input
            autoComplete="off"
            type="number"
            step="0.01"
            className="driver-field"
            name="urgentDeliveryPrice"
            placeholder="Urgent Delivery"
            value={personalDetail.urgentDeliveryPrice}
            onChange={handlePersonalInputChange}
            required
          />
             {fieldErrors.urgentDeliveryPrice && <span className="error-message">{fieldErrors.urgentDeliveryPrice}</span>}
        </Grid>
      </Grid>

      <Grid container className="form-grid" spacing={2}>
        {/* Four Fields in a 3-3-3-3 Configuration */}
        {/* <Grid item className="drive-in-grid" xs={6}>
        <div className="form-sec-head-driver">Addtional Document</div>
          <textarea
            rows="8" 
            className="driver-textarea-field"
            name="socialSecurityNumber"
            value={personalDetail.socialSecurityNumber}
            onChange={handlePersonalInputChange}
          />
        </Grid> */}
        <Grid item className="drive-in-grid" xs={6}>
          <div className="form-sec-head-driver ">Additional Information</div>
          <textarea
            rows="8"
            className="driver-textarea-field"
            name="addtionalNote"
            value={personalDetail.addtionalNote}
            onChange={handlePersonalInputChange}
          />
        </Grid>
      
      </Grid>
      <Grid container className="form-grid" spacing={2}>        
        <div className="form-sec-head-facility">Permissions</div>
      <Grid container className="form-grid" spacing={2}>
      <Grid item className="drive-grid" xs={3}>
        <label className="facility-field-label" style={{marginLeft:"30px"}}>Allow Reordering</label>
        <input
          autoComplete="off"
          type="checkbox"
          className="facility-field small-checkbox"
          name="allow_reordering"
          checked={personalDetail.allow_reordering}
          onChange={handlePersonalInputChangeChecked}
        />
      </Grid>
      </Grid>
      </Grid>
      <div className="driver-btn">
        <button type="submit" onClick={handleSubmit} className="save-driver">
          Save Details
        </button>
      </div>
    </div>
  );
}

export default AddDriver;
