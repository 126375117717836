import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Menu as BaseMenu } from "@mui/base/Menu";
import { MenuButton as BaseMenuButton } from "@mui/base/MenuButton";
import { MenuItem as BaseMenuItem } from "@mui/base/MenuItem";
import { Dropdown } from "@mui/base/Dropdown";
import { notification } from "../../../../assests";
import { Badge, IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { getApiWithAuth } from "../../../../utils/api";
import { CREATE_TICKET } from "../../../../utils/apiUrls";
import moment from 'moment';

export default function TicketDropdown({isNavDropdownsOpen, setIsNavDropdownsOpen}) {
  const navigate = useNavigate();
  const {pid} = useParams();
  const dropdownRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [ticketOrders, setTicketOrders] = useState([]);

  const createHandleMenuClick = (menuItem) => {
    return () => {
      console.log(`Clicked on ${menuItem}`);
    };
  };

  const handleClick = () => {
    setIsNavDropdownsOpen({...isNavDropdownsOpen, tickets: false});
    {userType === "PharmacyOwner" || userType === "PharmacyEmployee" ? 
      navigate(`pharmacy/${pid}/ticketlisting`)
      :
      navigate("/ticketlisting")
    }
  };

  const handleButtonClick = (property) => {
    const updatedDropdowns = {};

    // Set the clicked property to true and others to false
    Object.keys(isNavDropdownsOpen).forEach((key) => {
      updatedDropdowns[key] = key === property;
    });

    setIsNavDropdownsOpen(updatedDropdowns);
  };

  const userType = localStorage.getItem("userType");

  const pendingTickets = ticketOrders?.filter(order => order.ticket_status === 'Pending');
  const totalPendingTickets = pendingTickets?.length;

  const getTicketOrders = async () => {
    setLoading(true);
    const response = await getApiWithAuth(CREATE_TICKET, {"pending": true});
    if (response.data !== undefined) {
      setTicketOrders(response.data.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTicketOrders();
    // const interval = setInterval(() => {
    //   getTicketOrders();
    // }, 5000);

    // return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    function handleOutsideClick(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsNavDropdownsOpen({...isNavDropdownsOpen, tickets: false});
      }
    }
    
    document.body.addEventListener('click', handleOutsideClick);
    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
    };
  }, [isNavDropdownsOpen]); // Ensure effect runs when dropdown state changes
  
  return (
    <div>
      <Dropdown
        open={isNavDropdownsOpen.tickets}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuButton ref={dropdownRef} style={{border: '1px solid white', paddingLeft: '5px', borderRadius: '8px', color:'white' }} onClick={() => handleButtonClick('tickets')}>
          Ticket 
          <IconButton color="inherit">
            <Badge anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }} badgeContent={totalPendingTickets} color="error">
              <img className="top-icon" src={notification} alt="Logo" />
            </Badge>
          </IconButton>
        </MenuButton>
        <Menu className="z-[1500] left-[-11%] bg-gray py-sm px-md max-h-[350px] w-[350px] rounded-sm flex flex-col overflow-y-auto" style={{ maxHeight: '350px' }}>
          <div className="flex flex-col gap-md">
            <p className="text-red text-3xl">Tickets</p>
            {ticketOrders?.length > 0 && ticketOrders?.slice(0, 3).map((ticket, i) => (
              <MenuItem
                key={i}
                className="bg-white py-sm px-md rounded-xs"
                onClick={createHandleMenuClick("Profile")}
              >
                <div className="flex flex-col gap-sm">
                  <div className="flex items-center justify-between">
                    <p className="text-md font-[700]">Order ID {ticket.order.slug}</p>
                    <p className="text-md font-[700]">Client: {ticket.order.name}</p>
                    <p className="text-md font-[700]" style={{ color: ticket.ticket_status === 'completed' || ticket.ticket_status === 'Completed' ? 'green' : 'red' }}>
                      {ticket.ticket_status}
                    </p>
                  </div>
                  <div className="flex items-center justify-between">
                    <p className="text-md font-[400]">
                      by : {ticket.created_by.first_name} {ticket.created_by.last_name}
                    </p>
                    <p className="text-md font-[400]">{moment(ticket.created_at).format('MM-DD-YYYY')} | {moment(ticket.created_at).format('hh:mm:ss a')}</p>
                  </div>
                </div>
              </MenuItem>
            ))}

            <div className="flex justify-end gap-sm">
              <button
                onClick={() => setIsNavDropdownsOpen({...isNavDropdownsOpen, tickets: false})}
                className="w-[106px] mb-2 h-[28px] text-[18px] font-[400] bg-white shadow-sm border border-red rounded-[2px]"
              >
                Clear
              </button>
              <button
                onClick={handleClick}
                className="w-[106px] h-[28px] text-[18px] font-[400] bg-white shadow-sm border border-[#0455A6] rounded-[2px]"
              >
                See All
              </button>
            </div>
          </div>
        </Menu>
      </Dropdown>
    </div>
  );
}

TicketDropdown.propTypes = {
  // You can add propTypes for your props here if needed
};

const Menu = BaseMenu;

Menu.propTypes = {
  open: true,
  defaultOpen: true,
  slotProps: PropTypes.shape({
    listbox: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    root: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  }),
};

const MenuButton = BaseMenuButton;

MenuButton.propTypes = {
  className: PropTypes.string,
};

const MenuItem = BaseMenuItem;

MenuItem.propTypes = {
  className: PropTypes.string,
};
