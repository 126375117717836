import React, { useState, useEffect } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// import { useNavigate } from 'react-router';

import { loginLogo } from "../../../assests";

import { LOGIN, TOKEN_VALIDITY } from "../../../utils/apiUrls";
import { setToken } from "../../../utils/localStorage";
import { postApiWithoutAuth } from "../../../utils/api";
// import { loginBG } from "../../../assests";
import pilldropLogo from "../../../../src/assests/pilldropLogo.png";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { red } from "@mui/material/colors";
import { v4 as uuidv4 } from 'uuid';

const Login = ({ setIsAuthenticated, isAuthenticated, userType }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [pid, setPid] = useState(0);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    console.log("Component mounted. Calling checkTokenValidity.");
  
    const checkTokenValidity = async () => {
      console.log("Checking token validity...");
      const token = localStorage.getItem("access_token");
  
      // Check if the token exists
      if (token) {
        const isValid = await validateToken(token);
        console.log("isValid", isValid);
        if (!isValid) {
          console.log("Invalid token, clearing local storage.");
          localStorage.clear(); // Clear local storage if the token is not valid
        } 
        else {
          const storedUserType = localStorage.getItem("userType");
          const pharmacyId = localStorage.getItem("pharmacyId");
          // Navigate based on user type and pharmacy ID
          if (storedUserType === "PharmacyEmployee" || storedUserType === "PharmacyOwner") {
            if (pharmacyId) {
              console.log("Navigating to pharmacy orders...");
              navigate(`/pharmacy/${pharmacyId}/list/order`);
            }
          } 
        }
      } else {
        console.log("No token found, redirecting to login.");
      }
    };
  
    checkTokenValidity(); // Call the token validation function on mount
  
    // This return function can help with cleanup if needed
    return () => {
      // Cleanup if needed
    };
  }, []); // Empty dependency array to run only once on mount
  
  // Helper function to validate token and return validity
  const validateToken = async (token) => {
    try {
      console.log("validate api is called")
      const response = await postApiWithoutAuth(TOKEN_VALIDITY, { token });
      console.log("responseee", response.data,response.data.valid)
      return response.data.valid; // Return the validity status
    } catch (error) {
      console.error("Error validating token:", error);
      return false; // Return false in case of error
    }
  };
  const togglePasswordVisibility = (e) => {
    setShowPassword(!showPassword);
  };
  const onChangeHandle = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    setError("");
  };

  const handlerSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    const response = await postApiWithoutAuth(LOGIN, data);
    console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response.data.type.type === "Driver") {
        setError("Driver can't access this web panel");
      } else {
        console.log("API RESPONSEEE",response);
        setToken(response.data.access);
        localStorage.setItem("userType", response.data.type.type);
        localStorage.setItem(
          "userDetail",
          response.data.username
        );
        localStorage.setItem("profilePic", response.data.profile_pic);
        localStorage.setItem("sessionId", response.data.session_id);
        localStorage.setItem(
          "route_startaddress",
          response.data.route_startaddress
        );
        localStorage.setItem("UID", response.data.id);
        console.log(response.data.type.type);

        if (
          response.data.type.type === "PharmacyEmployee" ||
          (response.data.type.type === "PharmacyOwner" &&
            isAuthenticated === true)
        ) {
          setPid(response.data.pharmacy_id);
          localStorage.setItem("pharmacyId", response.data.pharmacy_id);
          localStorage.setItem("show_report", response.data.show_report);
          localStorage.setItem("show_team", response.data.show_team);
          let deviceId = localStorage.getItem('deviceId');

          // If device ID doesn't exist, generate a new one
          if (!deviceId) {
              deviceId = uuidv4();
              console.log("deviceId",deviceId)
              localStorage.setItem('deviceId', deviceId);
          }
          navigate(`pharmacy/${response.data.pharmacy_id}/list/order`);
        } else if (
          response.data.type.type === "PharmacyEmployee" ||
          (response.data.type.type === "PharmacyOwner" &&
            isAuthenticated === false)
        ) {
          setPid(response.data.pharmacy_id);
          localStorage.setItem("pharmacyId", response.data.pharmacy_id);
          setIsAuthenticated(true);
        } else if (
          response.data.type.type !== "PharmacyEmployee" &&
          response.data.type.type !== "PharmacyOwner" &&
          isAuthenticated === true
        ) {
          setIsAuthenticated(false);
        } else {
          navigate("/list/order");
        }
      }
      
    } else {
      setLoading(false);
      console.log(response.data.non_field_errors[0]);
      setError(response.data.non_field_errors[0]);
    }
  };

  return (
    <div>
      <div className="login-container">
        <div className="background-image"></div>
        <div className="login-form">
          <form
            onSubmit={handlerSubmit}
            className="form-content"
          >
            <img src={pilldropLogo} alt="Logo" className="logo" />
            <h2 className="login-text">Log In</h2>
            <h3 style={{ color: "rgb(235, 11, 11)", fontSize: "20px" }}>
              {error}
            </h3>
            <div className="input-container">
              <label htmlFor="email">Email</label>
              <input
                autoComplete="off"
                type="email"
                name="email"
                onChange={onChangeHandle}
                id="email"
                placeholder="Enter your email"
              />
            </div>
            <div className="input-container">
              <label htmlFor="password">Password</label>
              <div className="password-input-container">
                <input
                  autoComplete="off"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Enter your password"
                  onChange={onChangeHandle}
                />
                <p
                  className="show-hide-password"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                </p>
              </div>
            </div>
            <div className="remember-forgot">
              <div className="remember-me">
                <label htmlFor="remember">Remember Me</label>
                <input autoComplete="off" type="checkbox" id="remember" />
              </div>
              <div className="forgot-password">
                <Link to="/forgetPassword">Forget Password?</Link>
              </div>
            </div>
            <button
              type="submit"
              className="login-button"
              disabled={loading}
            >
              {loading ? 'Logging in...' : 'Log In'}
            </button>
          </form>
        </div>
        <div className="policy-links">
    <a href="/terms-condition" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
    <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
  </div>
      </div>
    </div>
  );
};

export default Login;
