import React from 'react';
import './CustomModel.css';

const CustomModel = ({ isOpen, top=false, children }) => {
    if (!isOpen) return null;

    return (
        <div className={`${top ? "modal-overlay-top" : "modal-overlay"}`}>
            <div className={`${top ? "modal-content-top" : "modal-content"}`}>
                {children}
            </div>
        </div>
    );
};

export default CustomModel;
