import {
  cellphone,
  userBlack,
  landline,
  iphone,
  closeIconRed,
  history,
  view,
  notes,
  images,
  resecdule,
  action8,
  readyPickOrder,
  pickedOrder,
  recievedFacilityOrder,
  transitOrder,
  rescheduleOrder,
  cancelOrder,
  deliverOrder
} from "../../../../assests";
import { DetailOrder } from "../../../FullComponents";
import {
  CREATE_TICKET,
  CREATE_COMMENT,
  GET_HISTORY,
  CREATE_ADMIN_NOTE,
  CREATE_PHARMACY_NOTE,
  CREATE_ORDER
} from "../../../../utils/apiUrls";
import {
  postApiWithAuth,
  getApiWithAuth,
  putApiWithAuth,
  patchApiWithAuth,
} from "../../../../utils/api";
import {
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Typography,
  Divider,
  Box,  Tooltip,

} from "@mui/material";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from "react";
import moment from "moment";
import { Button } from "@mui/material";
import { TextareaAutosize } from "@mui/base";
import Checkbox from "@mui/material/Checkbox";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import {
  BsBagFill,
  BsFillPersonCheckFill,
  BsFillSendFill,
  BsFillXSquareFill,
  BsBagCheckFill,
} from "react-icons/bs";
import { FiRefreshCw } from "react-icons/fi";
import { toast } from "react-toastify";
function getStatusMessage(statusCode) {
  switch (statusCode) {
    case "1":
      return "Ready to Pick Up";
    case "2":
      return "Picked Up";
    case "3":
      return "Received At Facility";
    case "4":
      return "In Transit";
    case "5":
      return "Delivered";
    case "6":
      return "Cancelled";
    default:
      return "Unknown Status";
  }
}
export default function CancelNoteCard({order, getOrders}) {
  console.log("order, getOrders",order, getOrders)
  const [modalOpen, setModalOpen] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [modalViewOpen, setModalViewOpen] = useState(false);
  const [modalHistoryOpen, setModalHistoryOpen] = useState(false);
  const [modalFacilityNoteOpen, setModalFacilityNoteOpen] = useState(false);
  const [modalPharmacyNoteOpen, setModalPharmacyNoteOpen] = useState(false);
  const [history, setHistory] = useState({});
  const [adminNote, setAdminNote] = useState("");
  const [cancelNote, setCancelNote] = useState("");
  const [deliveredNote, setDelieveredNote] = useState("");
  const [error, setError] = useState("");
  const [formState, setFormState] = useState({
    subject: "",
    comment: "",
  });
const [isModalOpen, setIsModalOpen] = useState(false);
const [selectedFailedComment, setSelectedFailedComment] = useState('');

  const handleModalPharmacyNoteClose = () => {
    setModalPharmacyNoteOpen(false);
    setDelieveredNote("");
  };
  const handleCreateFacilityNote = () => {
    setModalFacilityNoteOpen(true);
    setAdminNote(order.admin_note);
    setOrderId(order.id);
  };

  const handleModalFacilityNoteClose = () => {
    setModalFacilityNoteOpen(false);
    setAdminNote("");
    setOrderId("");
  };

  const handleCreatePharmacyNote = () => {
    setModalPharmacyNoteOpen(true);
    setDelieveredNote(order.delivered_note);
  };
  const handleCreateHistory = async (order) => {
    const response = await getApiWithAuth(GET_HISTORY + `${order}/`);
    if (response.data !== undefined) {
      setHistory(response.data.data);
      setModalHistoryOpen(true);
    } else {
      console.log("error");
    }
  };

  const handleModalHistoryClose = () => {
    setModalHistoryOpen(false);
  };

  const handleViewModalOpen = (id) => {
    setModalViewOpen(true);
    setOrderId(id);
  };

  const handleViewModalClose = () => {
    setModalViewOpen(false);
    setOrderId("");
  };

  const handleCreateTicket = (id) => {
    setModalOpen(true);
    setOrderId(id);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setOrderId("");
  };
  const handleModalClosefailed = () => {
    setIsModalOpen(false); // Set isModalOpen to false to close the modal
  };
  const handleMarkAsRead= async (order) => {
    let response = ""
    console.log("order in handle", order)
    if( userType === "PharmacyEmployee" || userType === "PharmacyOwner" ){
      response = await patchApiWithAuth(CREATE_ORDER + `/${order?.id}`, {cancelnotePharmacyRead: true, driver: order?.driver, driverNote_id: order?.driverNote_id});
    } else {
      response = await patchApiWithAuth(CREATE_ORDER + `/${order?.id}`, {cancelnoteAdminRead: true, driver: order?.driver, driverNote_id: order?.driverNote_id});
    }

    if (response.data !== undefined) {
      console.log(response);
      getOrders()
    } else {
      console.log("error");
    }
  };
  const userType = localStorage.getItem("userType")

  const handleSaveTicket = async (e) => {
    e.preventDefault();
    console.log("Order Details:", formState);

    const formData = new FormData();
    formData.append("name", formState.subject);
    formData.append("ticket_status", "Pending");
    formData.append("order", orderId);

    const responseTicket = await postApiWithAuth(CREATE_TICKET, formData);

    if (responseTicket.data !== undefined) {
      console.log(responseTicket.data);
      const formDataComment = new FormData();
      formDataComment.append("comment", formState.comment);
      formDataComment.append("ticket", responseTicket.data.data.id);
      const responseComment = await postApiWithAuth(
        CREATE_COMMENT,
        formDataComment
      );
      if (responseComment.data !== undefined) {
        console.log(responseComment.data);
        setModalOpen(false);
        toast.success("Ticket Created Successfully!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setOrderId("");
        
      } else {
        console.log("Error");
        setModalOpen(false);
        toast.error("Some Backend Error!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setOrderId("");
      }
    } else {
      console.log("Error");
      setModalOpen(false);
      setOrderId("");
      toast.error("Some Backend Error!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleUpdateStatus = async (ticketId) => {
    // Send a PUT request to update the ticket status to 'Completed'
    const response = await patchApiWithAuth(CREATE_TICKET + `${ticketId}/`, {
      ticket_status: "Completed",
    });
    if (response.data !== undefined) {
      console.log(response.data);
      
      toast.success("Ticket Closed Successfully!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      console.log("Error");
      toast.error("Some Backend Error!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleSaveFacilityNote = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (adminNote !== "") {
      formData.append("note", adminNote);
      const response = await putApiWithAuth(
        CREATE_ADMIN_NOTE + `${orderId}`,
        formData
      );
      if (response.data !== undefined) {
        console.log(response.data);
        setModalFacilityNoteOpen(false);
        setOrderId("");
        setAdminNote("");
        
        setError("");
      } else {
        setModalFacilityNoteOpen(false);
        setOrderId("");
        setAdminNote("");
        setError("");
      }
    } else {
      setError("Add note for save");
    }
  };

  const handleSavePharmacyNote = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (deliveredNote !== "") {
      formData.append("note", deliveredNote);
      const response = await putApiWithAuth(
        CREATE_PHARMACY_NOTE + `${orderId}`,
        formData
      );
      if (response.data !== undefined) {
        console.log(response.data);
        
        setModalPharmacyNoteOpen(false);
        setOrderId("");
        setDelieveredNote("");
        setError("");
        
      } else {
        setModalPharmacyNoteOpen(false);
        setOrderId("");
        setDelieveredNote("");
        setError("");
      }
    } else {
      setError("Add note for save");
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  return (
    <div className="CancelNoteCard rounded-sm border border-[#D9D9D9] p-md mt-2">
      <div className="grid grid-cols-9 gap-4">
        <div className="flex flex-col justify-around gap-md md:col-span-3 lg:col-span-2">
          <div className="flex justify-between">
            <div className="flex items-center gap-sm">
              <img width="14px" height="auto" src={userBlack} alt="user" />
              <p className="text-[16px] font-[700]">{ order.name}</p>
            </div>
            <p className="text-[16px] font-[700]">Order ID {order.slug}</p>
          </div>

          <div className="flex justify-between md:flex-col gap-xs xl:flex-row">
            <div className="flex items-center gap-sm">
              <img width="14px" height="auto" src={cellphone} alt="cellphone" />
              <p className="text-sm font-[400]">{order.contact}</p>
            </div>
            <div className="flex items-center gap-sm">
              <img width="14px" height="auto" src={iphone} alt="iphone" />
              <p className="text-sm font-[400]">{order.cell}</p>
            </div>
            <div className="flex items-center gap-sm">
              <img width="14px" height="auto" src={landline} alt="landline" />
              <p className="text-sm font-[300]">{order.workNumber}</p>
            </div>
          </div>

          <div className="flex justify-between">
            <p>
            {order.address}
            </p>
          </div>
        </div>
        {order.returnFailed.length > 0 && 
  order.returnFailed.map((failed, i) => [
    <div className="flex flex-col gap-xs items-center justify-start md:col-span-2 lg:col-span-1" key={i}>
<button 
        style={{border: '1px solid #D9D9D9', fontSize: '14px', fontWeight: '700', borderRadius: '4px', padding: '4px 12px'}}
        onClick={() => {
          setSelectedFailedComment(failed.comment); // Set selected failed comment
          setIsModalOpen(true); // Open modal
        }}
      >    {i === 0 ? '1st' : i === 1 ? '2nd' : i === 2 && '3rd'} Attempt Failed
  </button>
  <p style={{fontSize: '16px', fontWeight: '400'}}>
    {moment(failed.date).format("MM-DD-YY")}{moment(failed.date).format("hh-mm-ss A")}
  </p>
  <p style={{fontSize: '16px'}}>
    By: {failed.updatedBy.first_name} {failed.updatedBy.last_name}
  </p>
</div>
  ])
}
{
  order.returnFailed.length === 0 && (
    <>
      <div className="flex flex-col gap-xs items-center justify-start md:col-span-2 lg:col-span-1" />
      <div className="flex flex-col gap-xs items-center justify-start md:col-span-2 lg:col-span-1" />
      <div className="flex flex-col gap-xs items-center justify-start md:col-span-2 lg:col-span-1" />
    </>
  )
}
{
  order.returnFailed.length === 1 && (
    <>
      <div className="flex flex-col gap-xs items-center justify-start md:col-span-2 lg:col-span-1" />
      <div className="flex flex-col gap-xs items-center justify-start md:col-span-2 lg:col-span-1" />
    </>
  )
}
{
  order.returnFailed.length === 2 && (
    <>
      <div className="flex flex-col gap-xs items-center justify-start md:col-span-2 lg:col-span-1" />
    </>
  )
}
        <div className="flex flex-col gap-sm justify-start md:col-span-4 lg:col-span-2">
          <p className="text-[16px] font-[700]">Cancellation Reason</p>
          <TextareaAutosize maxRows={5}>
            {order.cancel_note}
          </TextareaAutosize>
        </div>

        <div className="flex flex-col gap-xs items-center justify-start md:col-span-2 lg:col-span-1">
          <button className="border border-[#D9D9D9] text-red text-[16px] font-[700] rounded-xs py-xs px-md">
            <div className="flex items-center gap-sm">
              <p className="text-[12px]">Cancelled At</p>
              <img width="14px" height="auto" src={closeIconRed} alt="cellphone" />
            </div>
          </button>
          <p className="text-[14px] font-[400]"> {moment(order.cancelAt).format("MM-DD-YYYY")}</p>
          <p className="text-[14px] font-[400]">{moment(order.cancelAt).format("hh:mm:ss A")}</p>
          {/* <p className="text-[14px]">By : {history && history?.orderhistory?.map((item) => (item.name === 'cancelled' && item.updatedBy))}</p> */}
          <p className="text-[14px]">By: {order.orderhistory.slice(-1)[0].updatedBy.first_name} {order.orderhistory.slice(-1)[0].updatedBy.last_name}</p>
        </div>

        <div className="flex flex-col gap-xs md:col-span-3 lg:col-span-1">
          <div className="flex items-center gap-sm" style={{cursor:"pointer",textDecoration:"underline"}} onClick={() => handleViewModalOpen(order.id)}>
            <img width="14px" height="auto" src={view} alt="view" />
            <p className="text-[16px]">View order</p>
          </div>
          <div className="flex items-center gap-sm" style={{cursor:"pointer",textDecoration:"underline"}} onClick={handleCreateFacilityNote}>
            <img width="14px" height="auto" src={notes} alt="notes" />
            <p className="text-[16px]">Staff notes</p>
          </div>
          {/* <div className="flex items-center gap-sm">
            <img width="20px" height="auto" src={resecdule} alt="resecdule" />
            <p className="text-md">Reschedule (Count As New)</p>
          </div>
          <div className="flex items-center gap-sm">
            <img width="20px" height="auto" src={images} alt="images" />
            <p className="text-md">Proof Images</p>
          </div> */}
          <div className="flex items-center gap-sm" style={{cursor:"pointer",textDecoration:"underline"}}>
            <img width="14px" height="auto" src={action8} alt="history" />
            <p className="text-[16px]" onClick={() => handleCreateHistory(order.id)}>History</p>
          </div>
          <div className="w-full flex items-center justify-end gap-sm">
      <p className="text-golden text-md font-[400]">Mark As Read</p>
      {userType === "PharmacyEmployee" || userType === "PharmacyOwner" ? (
        <Checkbox
          checked={order?.cancelnotePharmacyRead}
          onClick={() => handleMarkAsRead(order)}
          size="small"
        />
      ) : (
        <Checkbox
          checked={order?.cancelnoteAdminRead}
          onClick={() => handleMarkAsRead(order)}
          size="small"
        />
      )}
    </div>

          {/* <div className="w-full flex items-center justify-end gap-sm">
            <p className="text-golden text-md font-[400]">Mark As Read</p>
            <Checkbox defaultChecked size="small" />
          </div> */}
        </div>
      </div> 
      <Dialog open={isModalOpen} onClose={handleModalClosefailed}>
      <DialogTitle sx={{backgroundColor: '#D9D9D9', color: '#0455A6', fontWeight: '800', padding: 1, marginRight:'20'}}>
        Failed Comment
  </DialogTitle>
  <DialogContent sx={{backgroundColor: '#D9D9D9', padding: 1}}>
  <p className="failed-field">{selectedFailedComment}</p>
    
    <Box display={'flex'} justifyContent={'flex-end'} gap={'10px'} marginLeft={23}>
    <Button
              onClick={handleModalClosefailed}
              variant="outlined"
              color="inherit"
              sx={{backgroundColor: 'white'}}
            >
              Close
            </Button>
            </Box>
  </DialogContent>
  </Dialog>
      <Dialog
          open={modalViewOpen}
          onClose={handleViewModalClose}
          fullWidth={true}
          maxWidth="xl"
        >
          {/* <DialogContent>
            <label className="pharmacy-field-label">Subject</label>
            <input
              type="text"
              className="modal-field"
              name="subject"
              placeholder="Subject"
              value={formState.subject}
              onChange={handleInputChange}
            />
            <label className="pharmacy-field-label">Comment</label>
            <textarea
              type="text"
              className="modal-field"
              name="comment"
              placeholder="Comment"
              value={formState.comment}
              onChange={handleInputChange}
              rows={4}
            />
            <Button
              onClick={handleSaveTicket}
              style={{ marginRight: "10px" }}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
            <Button
              onClick={handleModalClose}
              variant="outlined"
              color="secondary"
            >
              Cancel
            </Button>
          </DialogContent> */}
          <DetailOrder
            orderid={orderId}
            handleViewModalClose={handleViewModalClose}
          />
        </Dialog>
        <Dialog open={modalOpen} onClose={handleModalClose}>
          <DialogTitle>Create Ticket</DialogTitle>
          <DialogContent>
            <label className="pharmacy-field-label">Subject</label>
            <input
              type="text"
              className="modal-field"
              name="subject"
              placeholder="Subject"
              value={formState.subject}
              onChange={handleInputChange}
              autoComplete="off"
            />
            <label className="pharmacy-field-label">Comment</label>
            <textarea
              type="text"
              className="modal-field"
              name="comment"
              placeholder="Comment"
              value={formState.comment}
              onChange={handleInputChange}
              rows={4}
            />
            <Button
              onClick={handleSaveTicket}
              style={{ marginRight: "10px" }}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
            <Button
              onClick={handleModalClose}
              variant="outlined"
              color="secondary"
            >
              Cancel
            </Button>
          </DialogContent>
        </Dialog>

        <Dialog
          open={modalFacilityNoteOpen}
          onClose={handleModalFacilityNoteClose}
        >
          <DialogTitle>Facility Note</DialogTitle>
          <DialogContent>
            <label className="pharmacy-field-label">Note</label>
            <p style={{ color: "red", fontSize: "12px" }}>{error}</p>
            <textarea
              type="text"
              className="modal-field"
              name="note"
              placeholder="Write your note here!!!"
              value={adminNote}
              onChange={(e) => setAdminNote(e.target.value)}
              rows={4}
            />
            <Button
              onClick={handleSaveFacilityNote}
              style={{ marginRight: "10px" }}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
            <Button
              onClick={handleModalFacilityNoteClose}
              variant="outlined"
              color="secondary"
            >
              Cancel
            </Button>
          </DialogContent>
        </Dialog>

        <Dialog
          open={modalPharmacyNoteOpen}
          onClose={handleModalPharmacyNoteClose}
        >
          <DialogTitle>Pharmacy Note</DialogTitle>
          <DialogContent>
            <label className="pharmacy-field-label">Note</label>
            <p style={{ color: "red", fontSize: "12px" }}>{error}</p>
            <textarea
              type="text"
              className="modal-field"
              name="note"
              placeholder="Write your note here!!!"
              value={deliveredNote}
              onChange={(e) => setDelieveredNote(e.target.value)}
              rows={4}
            />
            <h4>Select Default Message</h4>
            <div
              className="search-filter-container"
              style={{ marginBottom: "15px" }}
            >
              <Select
                id="search"
                className="filter-input"
                defaultValue="0"
                onChange={(e) => setDelieveredNote(e.target.value)}
                style={{
                  width: "300px",
                  padding: "0",
                  backgroundColor: "primary.lighter", // Adjust the background color as needed
                  border: "0px solid",
                  borderColor: "transparent",
                }}
                // IconComponent={() => null}
              >
                <MenuItem>
                  <em>Select Default Message</em>
                </MenuItem>
                <MenuItem value="Text 1">Text 1</MenuItem>
                {/* <MenuItem value="2">Picked Up</MenuItem> */}
                <MenuItem value="Text 2">Text 2</MenuItem>
                <MenuItem value="Text 3">Text 3</MenuItem>
              </Select>
            </div>
            <Button
              onClick={handleSavePharmacyNote}
              style={{ marginRight: "10px" }}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
            <Button
              onClick={handleModalPharmacyNoteClose}
              variant="outlined"
              color="secondary"
            >
              Cancel
            </Button>
          </DialogContent>
        </Dialog>

        <Dialog
          // minWidth="lg"
          className="hisotryModal"
          open={modalHistoryOpen}
          onClose={handleModalHistoryClose}
          // style={{minWidth: "600px"}}
          // sx={{ '& .MuiDialog-paper': { p: 0 }, '& .MuiBackdrop-root': { opacity: '0.5 !important' } }}
          fullWidth
          scroll="body"
          PaperProps={{
            sx: {
              verticalAlign: "bottom",
              position: "fixed",
              right: 0,
              bottom: 0,
            },
          }}
        >
          <DialogTitle>
            <div className="orderHistoryline1">
              <h5 className="historyLine1">
                <span>{history?.orderDetail?.pharmacy?.name}</span>
              </h5>
              <h5 className="historyLine1" style={{ display: "flex" }}>
                <span style={{ marginLeft: 10, display: "flex" }}>
                  <img
                    width="16px"
                    height="auto"
                    style={{ marginRight: 10, display: "flex" }}
                    src={cellphone}
                    alt="phone"
                  />{" "}
                  {history?.orderDetail?.pharmacy?.contact_no}
                </span>
                <span style={{ marginLeft: 10, display: "flex" }}>
                  <img
                    width="16px"
                    height="auto"
                    style={{ marginRight: 10, display: "flex" }}
                    src={cellphone}
                    alt="phone"
                  />{" "}
                  {history?.orderDetail?.pharmacy?.emergency_contact}
                </span>
              </h5>
            </div>
            <Box className="history-icon-and-text-container">
              <img src={action8} className="history-icon" />
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography className="history-text">History</Typography>
              </Stack>
            </Box>
            <div className="orderHistoryline1">
              <h5 className="historyLine1">
                Order ID:
                <span style={{ marginLeft: 10, textDecoration: "underline" }}>
                  {history?.orderDetail?.slug}
                </span>
              </h5>
              <h5 className="historyLine1">
                CLIENT:
                <span style={{ marginLeft: 10, textDecoration: "underline" }}>
                  {history?.orderDetail?.name}
                </span>
              </h5>
            </div>
            <div className="orderHistoryline1">
              <p className="historyLine2">
                Delivery Date:{" "}
                {moment(history?.orderDetail?.deliverAt).format("MM-DD-YY")}
              </p>
              <p className="historyLine2">
                Time Window: {history?.orderDetail?.deliverydate}
              </p>
            </div>
            <div className="orderHistoryline1">
              <p className="historyLine2">
                Delivery Preference: {history?.orderDetail?.parcelPlace}
              </p>
              <p className="historyLine2">
                Signatures: &nbsp;
                {history?.orderDetail?.signature ? "Required" : "Not Required"}
              </p>
            </div>
            <div className="orderHistoryline1">
              {(order.hippaform || order.lineform || order.nfaqbform) && (
                <>
                  <p>Get Forms Signed:</p>
                  <p
                    className="historyLine2"
                    style={{ margin: "0", fontWeight: 700 }}
                  >
                    {order.hippaform && <span>Hipaa Form</span>}
                    {order.lineform && (
                      <span
                        style={{
                          marginLeft: "12px",
                          fontFamily: "Saira, sans-serif",
                        }}
                      >
                        Lien Form
                      </span>
                    )}
                    {order.nfaqbform && (
                      <span
                        style={{
                          marginLeft: "12px",
                          fontFamily: "Saira, sans-serif",
                        }}
                      >
                        NF AOB Form
                      </span>
                    )}
                  </p>
                </>
              )}
              <p className="historyLine2">
                {order.refrigrated && order.photoid
                  ? "REFRIGERATED / Photo ID"
                  : order.photoid
                    ? "Photo ID"
                    : order.refrigrated && "Refrigerated"}
              </p>
            </div>
            <div className="orderHistoryline3">
              {history?.orderDetail?.notes !== "" &&
                history?.orderDetail?.notes !== null ? (
                <p className="historyLine3">
                  Note:{" "}
                  <span style={{ fontWeight: "400" }}>
                    {history?.orderDetail?.notes}
                  </span>{" "}
                  :
                </p>
              ) : (
                <p></p>
              )}
              {history?.orderDetail?.totalcopay !== 0 && (
                <>
                  <p className="historyLine4">
                    Copay: &nbsp; &nbsp; $
                    {history?.orderDetail?.totalcopay.toFixed(2)}
                  </p>
                  {order.status === "5" && order.iscollected && (
                    <p className="collect-text">
                      Collected By:{" "}
                      {history?.orderDetail?.driver?.firstName +
                        " " +
                        history?.orderDetail?.driver?.lastName}
                    </p>
                  )}
                  {order.status === "5" && order.ispaidpharmacy && (
                    <p className="collect-text">
                      Received By: {history?.orderDetail?.receiverName}
                    </p>
                  )}
                  {order.status === "5" && order.iswaivedpharmacy && (
                    <p className="collect-text">
                      Waived By: {history?.orderDetail?.waivedBy}
                    </p>
                  )}
                </>
              )}
            </div>
            <div className="orderHistoryline1">
              {history?.orderDetail?.orderticket ? (
                <>
                  <p className="historyLine5">
                    Ticket:{" "}
                    <span style={{ color: "grey", fontWeight: "400" }}>
                      {
                        history?.orderDetail?.orderticket?.ticketcomment_set[0]
                          .comment
                      }
                    </span>
                  </p>
                  <div style={{ textAlign: "right" }}>
                    <p className="historyLine2">
                      Created At: &nbsp;
                      {moment(
                        history?.orderDetail?.orderticket?.created_at
                      ).format("MM-DD-YY")}{" "}
                      &nbsp;
                      {moment(
                        history?.orderDetail?.orderticket?.created_at
                      ).format("hh:mm:ssa")}
                    </p>
                    <p className="historyLine2">
                      By: &nbsp;
                      {history?.orderDetail?.orderticket?.created_by?.username}
                    </p>
                    {history?.orderDetail?.orderticket?.ticket_status ===
                      "Completed" && (
                        <>
                          <p className="historyLine6">
                            <span style={{ color: "red" }}>Closed At: </span>
                            &nbsp;
                            {moment(
                              history?.orderDetail?.orderticket?.updated_at
                            ).format("MM-DD-YY")}{" "}
                            &nbsp;
                            {moment(
                              history?.orderDetail?.orderticket?.updated_at
                            ).format("hh:mm:ssa")}
                          </p>
                          <p className="historyLine2">
                            By: &nbsp;
                            {history?.orderDetail?.orderticket?.updated_by
                              ?.first_name +
                              history?.orderDetail?.orderticket?.updated_by
                                ?.last_name}
                          </p>
                        </>
                      )}
                  </div>
                </>
              ) : (
                <p></p>
              )}
            </div>
          </DialogTitle>
          {/* <Divider /> */}
          <div style={{ height: "400px", overflowY: "auto" }}>
            {history?.orderDetail?.cancelAt === "" ||
              history?.orderDetail?.cancelAt === null ? (
              <Timeline
                sx={{
                  "& .MuiTimelineItem-root": { minHeight: 35 },
                  "& .MuiTimelineOppositeContent-root": { mt: 0.5 },
                  "& .MuiTimelineDot-root": {
                    borderRadius: 1.25,
                    boxShadow: "none",
                    margin: 0,
                    ml: 1.25,
                    mr: 1.25,
                    p: 1,
                    "& .MuiSvgIcon-root": { fontSize: "1.2rem" },
                  },
                  "& .MuiTimelineContent-root": {
                    borderRadius: 1,
                    bgcolor: "secondary.lighter",
                  },
                  "& .MuiTimelineConnector-root": {
                    border: "1px dashed",
                    borderColor: "secondary.light",
                    bgcolor: "transparent",
                  },
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "8px",
                    backgroundColor: "#E2E2E2",
                  }}
                ></div>
                <TimelineItem>
                  <TimelineContent>
                    <Typography
                      variant="h6"
                      style={{ fontSize: "12px", fontWeight: "700" }}
                      component="span"
                    >
                      Created By: {history?.orderDetail?.createdby?.username}
                    </Typography>
                  </TimelineContent>
                  <TimelineOppositeContent
                    align="right"
                    style={{ fontSize: "12px", fontWeight: "400" }}
                  >
                    At :{" "}
                    {moment(history?.orderDetail?.createdAt).format("MM-DD-YY")}{" "}
                    &nbsp;
                    {moment(history?.orderDetail?.createdAt).format(
                      "hh:mm:ssa"
                    )}
                  </TimelineOppositeContent>
                </TimelineItem>
                <div
                  style={{
                    width: "100%",
                    height: "8px",
                    backgroundColor: "#E2E2E2",
                  }}
                ></div>
                <TimelineItem>
                  <TimelineContent>
                    <Typography
                      variant="h6"
                      style={{ fontSize: "12px", fontWeight: "700" }}
                      component="span"
                    >
                      At Ready For Pick Up
                    </Typography>
                  </TimelineContent>
                  <TimelineSeparator>
                    <div
                      style={{
                        backgroundColor: "#E4F2FF",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "30px",
                        width: "30px",
                      }}
                    >
                      <img
                        src={readyPickOrder}
                        style={{ fontSize: "1rem", color: "#30abf8" }}
                      />
                    </div>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineOppositeContent
                    align="right"
                    style={{ fontSize: "12px", fontWeight: "400" }}
                  >
                    At :{" "}
                    {moment(history?.orderDetail?.createdAt).format("MM-DD-YY")}{" "}
                    &nbsp;
                    {moment(history?.orderDetail?.createdAt).format(
                      "hh:mm:ssa"
                    )}
                    {history?.orderhistory?.length > 0 &&
                      history.orderhistory.map((item, i) => {
                        if (item.name === "update_Order") {
                          return (
                            <span>
                              <br />
                              Updated At: {moment(item.date).format(
                                "MM-DD-YY"
                              )}{" "}
                              &nbsp;
                              {moment(item.date).format("hh:mm:ssa")}
                              <br />
                              By: {item.updatedBy}
                            </span>
                          );
                        }
                      })}
                  </TimelineOppositeContent>
                </TimelineItem>
                {history?.orderhistory?.length > 0 ? (
                  history.orderhistory.map((item, i, array) => {
                    if (item.name === "update_Order") {
                      // Skip this iteration if the name is "update_Order"
                      return null;
                    }

                    const isOutForDelivery = item.name === "out_For_Delivery";
                    const isDelivered = item.name === "Delivered";
                    const isReturned = item.name === "returned_Failed";
                    // Count the occurrences of "returned_Failed" before the current item
                    const attempt = array
                      .slice(0, i + 1)
                      .filter((obj) => obj.name === "returned_Failed" && 
                      !obj.comment.startsWith("Failed by Admin")).length;
                    console.log("attemot", attempt);
                    // Construct the attempt message if it's a returned failed status and attempt number exists
                    const attemptMessage = isReturned && attempt > 0
  ? ` (Attempt ${attempt})`
  : "";

                    return (
                      <React.Fragment key={i}>
                        <div
                          style={{
                            width: "100%",
                            height: "8px",
                            backgroundColor: "#E2E2E2",
                          }}
                        ></div>
                        <TimelineItem>
                          <TimelineContent>
                            <Typography
                              variant="h6"
                              component="span"
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                                textTransform: "capitalize",
                              }}
                            >
                              {item.display_name}
                              {attemptMessage}
                            </Typography>
                            {isOutForDelivery && (
                              <>
                                <Typography
                                  variant="h1"
                                  component="span"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                    marginLeft: "20px",
                                  }}
                                >
                                  ETD {item.comment}
                                </Typography>

                                {order?.out_delivery_sms && (
                                  <Typography
                                    color="textSecondary"
                                    style={{ fontSize: "12px", fontWeight: "400" }}
                                  >
                                    Delivery SMS At: {moment(order?.out_delivery_at).format("MM-DD-YY")} &nbsp;{" "}
                                    {moment(order?.out_delivery_at).format("hh:mm:ssa")}
                                    <br />
                                    <Tooltip title={order?.out_delivery_sms || "No SMS available"} arrow>
                                      <span style={{ textDecoration: "underline", cursor: "pointer" }}>
                                        {order?.out_delivery_sms ? "View SMS" : "No SMS"}
                                      </span>
                                    </Tooltip>
                                  </Typography>
                                )}
                                {order?.navigate_sms && (
                                  <Typography
                                    color="textSecondary"
                                    style={{ fontSize: "12px", fontWeight: "400" }}
                                  >
                                    Navigate At: {moment(order?.navigate_at).format("MM-DD-YY")} &nbsp;{" "}
                                    {moment(order?.navigate_at).format("hh:mm:ssa")}
                                    <br />
                                    <Tooltip title={order?.navigate_sms || "No SMS available"} arrow>
                                      <span style={{ textDecoration: "underline", cursor: "pointer" }}>
                                        {order?.navigate_sms ? "View SMS" : "No SMS"}
                                      </span>
                                    </Tooltip>
                                  </Typography>
                                )}
                                {order?.e_sign_sms && (
                                  <Typography
                                    color="textSecondary"
                                    style={{ fontSize: "12px", fontWeight: "400" }}
                                  >
                                    E-Sign At: {moment(order?.e_sign_at).format("MM-DD-YY")} &nbsp;{" "}
                                    {moment(order?.e_sign_at).format("hh:mm:ssa")}
                                    <br />
                                    <Tooltip title={order?.e_sign_sms || "No SMS available"} arrow>
                                      <span style={{ textDecoration: "underline", cursor: "pointer" }}>
                                        {order?.e_sign_sms ? "View SMS" : "No SMS"}
                                      </span>
                                    </Tooltip>
                                  </Typography>
                                )}
                              </>
                            )}
                            {isDelivered && (
                              <Typography
                                color="textSecondary"
                                style={{ fontSize: "12px", fontWeight: "400" }}
                              >
                                {item.comment}
                              </Typography>
                            )}
                            {isReturned && (
                                      <Typography
                                      color="textSecondary"
                                      style={{ fontSize: "12px", fontWeight: "400" }}
                                    >
                                      {item.comment.replace(/Failed by Admin./g, '').trim()}  {/* Remove the phrase and trim whitespace */}
                                    </Typography>
                            )}
                          </TimelineContent>
                          <TimelineSeparator>
                            <TimelineConnector />
                            <div
                              style={{
                                backgroundColor: item.icon_bg,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "30px",
                                width: "30px",
                              }}
                            >
                              <img
                                src={item.icon_url}
                                style={{ fontSize: "1rem" }}
                              />
                            </div>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineOppositeContent
                            align="right"
                            style={{ fontSize: "12px", fontWeight: "400" }}
                          >
                            At: {moment(item.date).format("MM-DD-YY")} &nbsp;{" "}
                            {moment(item.date).format("hh:mm:ssa")}
                            <br />
                            By: {item.updatedBy}
                          </TimelineOppositeContent>
                        </TimelineItem>
                      </React.Fragment>
                    );
                  })
                ) : history?.orderDetail?.recievedFaciltyAt !== null ? (
                  <TimelineItem>
                    <TimelineContent>
                      <Typography
                        variant="h6"
                        component="span"
                        style={{ fontSize: "12px", fontWeight: "700" }}
                      >
                        Received at Facility
                      </Typography>
                    </TimelineContent>

                    <TimelineSeparator>
                      <TimelineConnector />
                      <div
                        style={{
                          backgroundColor: "#ECF7FF",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "30px",
                          width: "30px",
                        }}
                      >
                        <img
                          src={recievedFacilityOrder}
                          style={{ fontSize: "1rem" }}
                        />
                      </div>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineOppositeContent
                      align="right"
                      style={{ fontSize: "12px", fontWeight: "400" }}
                    >
                      At:{" "}
                      {moment(history?.orderDetail?.recievedFaciltyAt).format(
                        "MM-DD-YY"
                      )}{" "}
                      &nbsp;
                      {moment(history?.orderDetail?.recievedFaciltyAt).format(
                        "hh:mm:ssa"
                      )}
                    </TimelineOppositeContent>
                  </TimelineItem>
                ) : (
                  history?.orderDetail?.trasitAt !== null && (
                    <>
                      <TimelineItem>
                        <TimelineContent>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ fontSize: "12px", fontWeight: "700" }}
                          >
                            At Transit
                          </Typography>
                        </TimelineContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <div
                            style={{
                              backgroundColor: "#FFF8EE",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "30px",
                              width: "30px",
                            }}
                          >
                            <img
                              src={transitOrder}
                              style={{ fontSize: "1rem" }}
                            />
                          </div>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineOppositeContent
                          align="right"
                          style={{ fontSize: "12px", fontWeight: "400" }}
                        >
                          At:{" "}
                          {moment(history?.orderDetail?.trasitAt).format(
                            "MM-DD-YY"
                          )}
                          {moment(history?.orderDetail?.trasitAt).format(
                            "hh:mm:ssa"
                          )}
                        </TimelineOppositeContent>
                      </TimelineItem>
                    </>
                  )
                )}
                <div
                  style={{
                    width: "100%",
                    height: "8px",
                    backgroundColor: "#E2E2E2",
                  }}
                ></div>
              </Timeline>
            ) : (
              <Timeline
                sx={{
                  "& .MuiTimelineItem-root": { minHeight: 35 },
                  "& .MuiTimelineOppositeContent-root": { mt: 0.5 },
                  "& .MuiTimelineDot-root": {
                    borderRadius: 1.25,
                    boxShadow: "none",
                    margin: 0,
                    ml: 1.25,
                    mr: 1.25,
                    p: 1,
                    "& .MuiSvgIcon-root": { fontSize: "1.2rem" },
                  },
                  "& .MuiTimelineContent-root": {
                    borderRadius: 1,
                    bgcolor: "secondary.lighter",
                  },
                  "& .MuiTimelineConnector-root": {
                    border: "1px dashed",
                    borderColor: "secondary.light",
                    bgcolor: "transparent",
                  },
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "8px",
                    backgroundColor: "#E2E2E2",
                  }}
                ></div>
                <TimelineItem>
                  <TimelineContent>
                    <Typography
                      variant="h6"
                      style={{ fontSize: "12px", fontWeight: "700" }}
                      component="span"
                    >
                      Created By: {history?.orderDetail?.createdby?.first_name}{" "}
                      {history?.orderDetail?.createdby?.last_name}
                    </Typography>
                  </TimelineContent>
                  <TimelineOppositeContent
                    align="right"
                    style={{ fontSize: "12px", fontWeight: "400" }}
                  >
                    At :{" "}
                    {moment(history?.orderDetail?.createdAt).format("MM-DD-YY")}{" "}
                    &nbsp;
                    {moment(history?.orderDetail?.createdAt).format(
                      "hh:mm:ssa"
                    )}
                  </TimelineOppositeContent>
                </TimelineItem>
                <div
                  style={{
                    width: "100%",
                    height: "8px",
                    backgroundColor: "#E2E2E2",
                  }}
                ></div>
                <TimelineItem>
                  <TimelineContent>
                    <Typography
                      variant="h6"
                      style={{ fontSize: "12px", fontWeight: "700" }}
                      component="span"
                    >
                      At Ready For Pick Up
                    </Typography>
                  </TimelineContent>
                  <TimelineSeparator>
                    <div
                      style={{
                        backgroundColor: "#E4F2FF",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "30px",
                        width: "30px",
                      }}
                    >
                      <img src={readyPickOrder} style={{ fontSize: "1rem" }} />
                    </div>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineOppositeContent
                    align="right"
                    style={{ fontSize: "12px", fontWeight: "400" }}
                  >
                    At :{" "}
                    {moment(history?.orderDetail?.createdAt).format("MM-DD-YY")}{" "}
                    &nbsp;
                    {moment(history?.orderDetail?.createdAt).format(
                      "hh:mm:ssa"
                    )}
                  </TimelineOppositeContent>
                  {history?.orderhistory?.length > 0 &&
                    history.orderhistory.map((item, i) => {
                      if (item.name === "update_Order") {
                        <TimelineOppositeContent
                          align="right"
                          style={{ fontSize: "12px", fontWeight: "400" }}
                        >
                          Updated At: {moment(item.date).format("MM-DD-YY")}{" "}
                          &nbsp;
                          {moment(item.date).format("hh:mm:ssa")}
                          <br />
                          By: {item.updatedBy}
                        </TimelineOppositeContent>;
                      }
                    })}
                </TimelineItem>

                {history?.orderhistory?.length > 0 ? (
                  history.orderhistory.map((item, i, array) => {
                    if (item.name === "update_Order") {
                      // Skip this iteration if the name is "update_Order"
                      return null;
                    }

                    const isOutForDelivery = item.name === "out_For_Delivery";
                    const isCancelled = item.name === "cancelled";
                    const isReturned = item.name === "returned_Failed";
                    // Count the occurrences of "returned_Failed" before the current item
                    const attempt = array
                      .slice(0, i + 1)
                      .filter((obj) => obj.name === "returned_Failed" && 
                      !obj.comment.startsWith("Failed by Admin")).length;
                    console.log("attemot", attempt);
                    // Construct the attempt message if it's a returned failed status and attempt number exists
                    const attemptMessage = isReturned
                      ? ` (Attempt ${attempt})`
                      : "";

                    return (
                      <React.Fragment key={i}>
                        <div
                          style={{
                            width: "100%",
                            height: "8px",
                            backgroundColor: "#E2E2E2",
                          }}
                        ></div>
                        <TimelineItem>
                          <TimelineContent>
                            <Typography
                              variant="h6"
                              component="span"
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                                textTransform: "capitalize",
                              }}
                            >
                              {item.display_name}
                              {attemptMessage}
                            </Typography>
                            {isOutForDelivery && (
                              <>
                                <Typography
                                  variant="h1"
                                  component="span"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                    marginLeft: "20px",
                                  }}
                                >
                                  ETD {item.comment}
                                </Typography>
                                {isOutForDelivery && (
                                  <>
                                    <Typography
                                      variant="h1"
                                      component="span"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "700",
                                        marginLeft: "20px",
                                      }}
                                    >
                                      ETD {item.comment}
                                    </Typography>
                                    {order?.out_delivery_sms && (
                                      <Typography
                                        color="textSecondary"
                                        style={{ fontSize: "12px", fontWeight: "400" }}
                                      >
                                        Delivery SMS At: {moment(order?.out_delivery_at).format("MM-DD-YY")} &nbsp;{" "}
                                        {moment(order?.out_delivery_at).format("hh:mm:ssa")}
                                        <br />
                                        <Tooltip title={order?.out_delivery_sms || "No SMS available"} arrow>
                                          <span style={{ textDecoration: "underline", cursor: "pointer" }}>
                                            {order?.out_delivery_sms ? "View SMS" : "No SMS"}
                                          </span>
                                        </Tooltip>
                                      </Typography>
                                    )}
                                    {order?.navigate_sms && (
                                      <Typography
                                        color="textSecondary"
                                        style={{ fontSize: "12px", fontWeight: "400" }}
                                      >
                                        Navigate At: {moment(order?.navigate_at).format("MM-DD-YY")} &nbsp;{" "}
                                        {moment(order?.navigate_at).format("hh:mm:ssa")}
                                        <br />
                                        <Tooltip title={order?.navigate_sms || "No SMS available"} arrow>
                                          <span style={{ textDecoration: "underline", cursor: "pointer" }}>
                                            {order?.navigate_sms ? "View SMS" : "No SMS"}
                                          </span>
                                        </Tooltip>
                                      </Typography>
                                    )}
                                    {order?.e_sign_sms && (
                                      <Typography
                                        color="textSecondary"
                                        style={{ fontSize: "12px", fontWeight: "400" }}
                                      >
                                        E-Sign At: {moment(order?.e_sign_at).format("MM-DD-YY")} &nbsp;{" "}
                                        {moment(order?.e_sign_at).format("hh:mm:ssa")}
                                        <br />
                                        <Tooltip title={order?.e_sign_sms || "No SMS available"} arrow>
                                          <span style={{ textDecoration: "underline", cursor: "pointer" }}>
                                            {order?.e_sign_sms ? "View SMS" : "No SMS"}
                                          </span>
                                        </Tooltip>
                                      </Typography>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                            {isCancelled && (
                              <Typography
                                color="textSecondary"
                                style={{ fontSize: "12px", fontWeight: "400" }}
                              >
                                {history?.orderDetail?.cancel_note}
                              </Typography>
                            )}
                            {isReturned && (
                                      <Typography
                                      color="textSecondary"
                                      style={{ fontSize: "12px", fontWeight: "400" }}
                                    >
                                      {item.comment.replace(/Failed by Admin./g, '').trim()}  {/* Remove the phrase and trim whitespace */}
                                    </Typography>
                            )}
                          </TimelineContent>
                          <TimelineSeparator>
                            <TimelineConnector />
                            <div
                              style={{
                                backgroundColor: item.icon_bg,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "30px",
                                width: "30px",
                              }}
                            >
                              <img
                                src={item.icon_url}
                                style={{ fontSize: "1rem" }}
                              />
                            </div>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineOppositeContent
                            align="right"
                            style={{ fontSize: "12px", fontWeight: "400" }}
                          >
                            At: {moment(item.date).format("MM-DD-YY")} &nbsp;{" "}
                            {moment(item.date).format("hh:mm:ssa")}
                            <br />
                            By: {item.updatedBy}
                          </TimelineOppositeContent>
                        </TimelineItem>
                      </React.Fragment>
                    );
                  })
                ) : history?.orderDetail?.recievedFaciltyAt !== null ? (
                  <TimelineItem>
                    <TimelineContent>
                      <Typography
                        variant="h6"
                        component="span"
                        style={{ fontSize: "12px", fontWeight: "700" }}
                      >
                        Received at Facility
                      </Typography>
                    </TimelineContent>

                    <TimelineSeparator>
                      <TimelineConnector />
                      <div
                        style={{
                          backgroundColor: "#ECF7FF",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "30px",
                          width: "30px",
                        }}
                      >
                        <img
                          src={recievedFacilityOrder}
                          style={{ fontSize: "1rem" }}
                        />
                      </div>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineOppositeContent
                      align="right"
                      style={{ fontSize: "12px", fontWeight: "400" }}
                    >
                      At:{" "}
                      {moment(history?.orderDetail?.recievedFaciltyAt).format(
                        "MM-DD-YY"
                      )}{" "}
                      &nbsp;
                      {moment(history?.orderDetail?.recievedFaciltyAt).format(
                        "hh:mm:ssa"
                      )}
                    </TimelineOppositeContent>
                  </TimelineItem>
                ) : (
                  history?.orderDetail?.trasitAt !== null && (
                    <>
                      <TimelineItem>
                        <TimelineContent>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ fontSize: "12px", fontWeight: "700" }}
                          >
                            At Transit
                          </Typography>
                        </TimelineContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <div
                            style={{
                              backgroundColor: "#FFF8EE",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "30px",
                              width: "30px",
                            }}
                          >
                            <img
                              src={transitOrder}
                              style={{ fontSize: "1rem" }}
                            />
                          </div>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineOppositeContent
                          align="right"
                          style={{ fontSize: "12px", fontWeight: "400" }}
                        >
                          At:{" "}
                          {moment(history?.orderDetail?.trasitAt).format(
                            "MM-DD-YY"
                          )}
                          {moment(history?.orderDetail?.trasitAt).format(
                            "hh:mm:ssa"
                          )}
                        </TimelineOppositeContent>
                      </TimelineItem>
                    </>
                  )
                )}
                <div
                  style={{
                    width: "100%",
                    height: "8px",
                    backgroundColor: "#E2E2E2",
                  }}
                ></div>
                <div
                  style={{
                    width: "100%",
                    height: "8px",
                    backgroundColor: "#E2E2E2",
                  }}
                ></div>
              </Timeline>
            )}
          </div>
          {/* <Divider /> */}
          <DialogActions sx={{ p: 2.5 }}>
            <Button
              onClick={handleModalHistoryClose}
              color="primary"
              variant="contained"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
    </div>
  );
}
