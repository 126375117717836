import React, {useState, useEffect, useRef} from 'react'
import { useParams, useNavigate } from 'react-router';
import { useJsApiLoader, GoogleMap, DirectionsRenderer, Marker, InfoWindow } from '@react-google-maps/api';

import H from '@here/maps-api-for-javascript';
import { Autocomplete  as MUIAutocomplete, TextField, Grid, Badge, IconButton, Dialog, DialogTitle, DialogContent, Button, DialogActions} from "@mui/material";

import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import ForwardIcon from '@mui/icons-material/Forward';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import CallMergeIcon from '@mui/icons-material/CallMerge';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { ArrowDropDownCircle, CheckBox } from "@mui/icons-material";
import CancelIcon from '@mui/icons-material/Cancel';
import moment from "moment";
import CustomLoader from "../../CommonComponents/Loader/CustomLoader";

import { toast } from 'react-toastify';

import { 
  ALL_ROUTES,
  ROUTE_DETAIL,
  SPLIT_ROUTE_RETURN,
  CREATE_ORDER,
  REOPTIMIZED_ROUTE_RETURN,
  CREATE_ROUTE_RETURN_ORDER,
  UPDATE_ROUTE_PHARMACY
 } from "../../../utils/apiUrls";
import {
  deleteApiWithAuth,
  getApiWithAuth,
  patchApiWithAuth,
  putApiWithAuth,
  postApiWithAuth,
} from "../../../utils/api";

import {
  landline,
  iphone,
  cellphone,
  buildings,
  userBlack
} from '../../../assests';

import './routeUpdate.css';

const mapStyles = {
  width: '100%',
  height: '100%'
};

const MAX_WAYPOINTS_PER_REQUEST = 25;

function chunkOrders(orders) {
  const chunks = [];
  for (let i = 0; i < orders.length; i += MAX_WAYPOINTS_PER_REQUEST) {
    chunks.push(orders.slice(i, i + MAX_WAYPOINTS_PER_REQUEST));
  }
  return chunks;
}

function floatToTime(floatValue) {

  const hours = Math.floor(floatValue / 60);
  const remainingMinutes = floatValue % 60;
  const minutes = Math.floor(remainingMinutes);
  const seconds = Math.round((remainingMinutes % 1) * 60);

  const parts = [];
  if (hours > 0) {
    parts.push(`${hours} hour${hours !== 1 ? "s" : ""}`);
  }
  if (minutes > 0) {
    parts.push(`${minutes} minute${minutes !== 1 ? "s" : ""}`);
  }
  if (seconds > 0) {
    parts.push(`${seconds} second${seconds !== 1 ? "s" : ""}`);
  }

  return parts.join(" ");
}

function modifyDateTime(dateTimeString, addedMinutes) {
  const dateTime = new Date(dateTimeString);
  dateTime.setMinutes(dateTime.getMinutes() + Math.floor(addedMinutes));
  return dateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
}

const apikey = process.env.REACT_APP_HERE_API;

function RouteReturnUpdate() {

  const mapRef = useRef(null);
  const map = useRef(null);
  const platform = useRef(null);
  const ui = useRef(null);

  const [loading, setLoading] = useState(false);
  const [routeOrders, setRouteOrders] = useState([]);
  const [routeDetail, setRouteDetails] = useState([]);
  const [allRouteOrders, setAllRouteOrders] = useState([]);
  const [selectedRouteIds, setSelectedRouteIds] = useState([])
  const [orderId, setOrderId] = useState([])
  const [reOrdering, setReOrdering] = useState(false);
  const [userPosition, setUserPosition] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalCopayOpen, setModalCopayOpen] = useState(false);
  const [routeOrderId, setRouteOrderId] = useState(0);
  const [modalAddressOpen, setModalAddressOpen] = useState(false);
  const [route, setRoute] = useState(null);
  const [mergeModal, setMergeModal] = useState(false);
  const [filteredRouteList, setFilteredRouteList] = useState(false);
  const [routeList, setRouteList] = useState(false);
  const [currentBubble, setCurrentBubble] = useState(null)
  const [formState, setFormState] = useState({
    address: "",
  });
  const [formCopayState, setFormCopayState] = useState({
    from_date: '',
    to_date: '',
    total_copay: ''
  });
  const [mapType, setMapType] = useState('StorePolyline');
  const [optimizeFor, setOptimizeFor] = useState('DISTANCE');

  const { rid } = useParams();
  const navigate = useNavigate();


  const handleMapTypeChange = (e) => {
    setMapType(e.target.value);
    setRouteDetails([])
    setRouteOrders([])
    setAllRouteOrders([])
    setReOrdering(false)
  };
  const handleSaveCopay = async () => {
    setLoading(true); // Set loading state if needed

    const url = `${UPDATE_ROUTE_PHARMACY}/${routeOrderId}/update/`;

    try {
      const response = await postApiWithAuth(url, formCopayState);

      if (response.status === 200) {
        toast.success('Copay Details Saved Successfully!', {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      console.error('Error saving copay details:', error);
      toast.error('Backend Error! Please try again.', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      setModalCopayOpen(false); // Close modal regardless of success/failure
      setLoading(false); // Reset loading state
    }
  };
  const handleOptimizeForChange = (e) => {
    setOptimizeFor(e.target.value);
    setReOrdering(false)
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };
  const handleCopayModalOpen = (order) => {
    console.log("details 921", order)
    setFormCopayState({
      from_date: order.from_date || '',  // Pre-fill with existing value or default to empty
      to_date: order.to_date || '',
      total_copay: order.total_copay || ''  // Assuming the amount is also part of the order object
    });
    setRouteOrderId(order.id); 
    setModalCopayOpen(true);  
  };

  const handleCopayModalClose = () => {
    setModalCopayOpen(false);
    setRouteOrderId(0);
    getRouteDetail();
  };
  const handleMergeModalClose = () => {
    setMergeModal(false)
    setRoute("");
  };
  // const handleMergeModalOpen = () => {
  //   setMergeModal(true)
  // };
  const handleMergeModalOpen = (routeType) => {
    console.log("Route Type Selected:", routeType);
    console.log("Full Route List:", routeList);
    
    const filteredRoutes = routeList.filter(route => route.type === routeType);
    console.log("Filtered Route List:", filteredRoutes);
  
    setFilteredRouteList(filteredRoutes);
    setMergeModal(true);
  };
  

  const getRouteDetail = async () => {
    setLoading(true);
    const response = await getApiWithAuth(ROUTE_DETAIL + `?route=${rid}`);
    if (response.data !== undefined) {
      setRouteOrders(response.data.data.orders)
      setRouteDetails(response.data.data.route)
      setUserPosition({ lat: response.data.data.route.latitude, lng: response.data.data.route.longitude })
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const getRoutes = async () => {
    setLoading(true);
    const response = await getApiWithAuth(ALL_ROUTES + `?routeStatus=Ready`);
    if (response.data !== undefined) {
      setRouteList(response.data.data.data)
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleRouteSelection = (routeId) => {
    console.log("routeid",routeId)
    if (selectedRouteIds.includes(routeId)) {
      setSelectedRouteIds(selectedRouteIds.filter((id) => id !== routeId));
    } else {
      setSelectedRouteIds([...selectedRouteIds, routeId]);
    }
  };

  useEffect(() => {
    getRoutes();
  }, []);


  useEffect(() => {
    if (!map.current && routeOrders?.length > 0) {
      platform.current = new H.service.Platform({ apikey });
      const defaultLayers = platform.current.createDefaultLayers({
        pois: true
      });

      map.current = new H.Map(
        mapRef.current,
        defaultLayers.vector.normal.map, {
          zoom: 14,
          center: userPosition,
        }
      );

      ui.current = H.ui.UI.createDefault(map.current, defaultLayers);
      const behavior = new H.mapevents.Behavior(
        new H.mapevents.MapEvents(map.current)
      );

    }
    if (routeOrders?.length > 0) {
      if ((mapType === "LiveMap" && !reOrdering)) {
        setLoading(true)
        findAndDisplayRoute(platform.current, map.current, userPosition, routeOrders, optimizeFor);
      } else if(mapType === "StorePolyline") {
        routeResponseHandler(map.current, routeOrders, null);
      }
    }

    if (routeOrders?.length < 1) {
      getRouteDetail();
    }
  }, [apikey, routeOrders, optimizeFor, mapType]);

  // Function to find and display the best route,
  async function findAndDisplayRoute(platform, map, start, orderList, optimizeFor) {
    const destination = orderList[orderList.length - 1]; // Assuming last restaurant as the final destination
    // const viaPoints = orderList.slice(0, -1); // All except the last point

    const bestRoute = await findBestRouteSequence(apikey, start, orderList, destination, optimizeFor);

    if (bestRoute) {
      const waypoints = bestRoute[0].waypoints
      // calculateRoute(platform, map, start, destination, bestRoute);
      const allSections = [];

      for (let i = 0; i < waypoints.length - 1; i++) {
        const start = waypoints[i];
        const end = waypoints[i + 1];

        // Fetch route details between each waypoint
        const routeDetails = await fetchRouteDetails(apikey, start, end);
        if (routeDetails && routeDetails.routes[0] && routeDetails.routes[0].sections) {
          allSections.push(...routeDetails.routes[0].sections);
        }
      }
      // Once all sections are collected, display them on the map
      const reorderList = await reorderOrderList(routeOrders, allSections);
      if (reorderList) {
        routeResponseHandler(map, allSections, reorderList);
      }
    }
  }

  async function reorderOrderList(orderList, sections) {
    const locationToOrder = orderList.reduce((acc, order) => ({
      ...acc,
      [`${parseFloat(order.pharmacy_details.latitude).toFixed(5)},${parseFloat(order.pharmacy_details.longitude).toFixed(5)}`]: order
    }), {});

    const newOrderList = sections.reduce((acc, section) => {
      const arrivalLocation = section.arrival.place.originalLocation;
      const latLngKey = `${arrivalLocation.lat.toFixed(5)},${arrivalLocation.lng.toFixed(5)}`;
      if (latLngKey in locationToOrder) {
        const order = { ...locationToOrder[latLngKey], polyline: section.polyline };
        acc.push(order);
      }
      return acc;
    }, []);

    setRouteOrders(newOrderList);
    setAllRouteOrders(newOrderList);
    setReOrdering(true);
    setLoading(false)
    return newOrderList
  };

  async function findBestRouteSequence(apikey, start, waypoints, end, optimizeFor) {
    const baseUrl = "https://wps.hereapi.com/v8/findsequence2";
    const departureTime = encodeURIComponent("2021-10-15T10:30:00+05:00"); // Correctly encoding the time string
    let url = `${baseUrl}?mode=fastest;car;traffic:enabled&start=${start.lat},${start.lng}&apikey=${apikey}&departure=${departureTime}&improveFor=${optimizeFor}`;

    waypoints.forEach((point, index) => {
      url += `&destination${index + 1}=${point.pharmacy_details.latitude},${point.pharmacy_details.longitude}`;
    });

    try {
      const response = await fetch(url);
      const data = await response.json();
      return data.results; // Assuming 'results' contains the ordered list of waypoints
    } catch (error) {
      console.error('Error finding best route sequence:', error);
    }
  }

  async function fetchRouteDetails(apikey, origin, destination) {
    const baseUrl = 'https://router.hereapi.com/v8/routes';
    const url = `${baseUrl}?apikey=${apikey}&origin=${origin.lat},${origin.lng}&destination=${destination.lat},${destination.lng}&routingMode=fast&transportMode=car&return=polyline,summary`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching route details:', error);
    }
  }

  async function routeResponseHandler(map, response, reorderList) {
    const sections = response;
    if (reorderList === null) {
      async function fetchPolyline(url) {
        const response = await fetch(url);
        if (!response.ok) throw new Error('Failed to fetch polyline');
        const data = await response.text();
        return H.geo.LineString.fromFlexiblePolyline(data);
      }

      // Map over sections and convert polylines
      const promises = sections.map(section => {
        return fetchPolyline(section.polyline);
      });

      const lineStrings = await Promise.all(promises);
      const multiLineString = new H.geo.MultiLineString(lineStrings);
      const bounds = multiLineString.getBoundingBox();
      const routePolyline = new H.map.Polyline(multiLineString, { style: { lineWidth: 5 } });
      map.removeObjects(map.getObjects());
      map.addObject(routePolyline);

      const startMarker = createMarker({ pharmacy_details: { latitude: routeDetail?.latitude, longitude: routeDetail?.longitude, route: routeDetail } }, '#0b6699', "S", true);
      // const endMarker = createMarker(restaurantList[restaurantList.length - 1], 'green');

      response?.forEach((waypoint, i) => {
        const waypointMarker = createMarker(waypoint, '#FF7733', i + 1, false);
        map.addObject(waypointMarker);
      });
      map.addObjects([startMarker]);
      map.getViewModel().setLookAtData({ bounds });
    } else {
      const lineStrings = sections.map(section => H.geo.LineString.fromFlexiblePolyline(section.polyline));
      const multiLineString = new H.geo.MultiLineString(lineStrings);
      const bounds = multiLineString.getBoundingBox();

      const routePolyline = new H.map.Polyline(multiLineString, { style: { lineWidth: 5 } });
      map.removeObjects(map.getObjects());
      map.addObject(routePolyline);

      const startMarker = createMarker({ pharmacy_details: { latitude: routeDetail?.latitude, longitude: routeDetail?.longitude }, route: routeDetail }, '#0b6699', "S", true);
      // const endMarker = createMarker(restaurantList[restaurantList.length - 1], 'green');
      reorderList?.forEach((waypoint, i) => {
        const waypointMarker = createMarker(waypoint, '#FF7733', i + 1, false);
        map.addObject(waypointMarker);
      });
      map.addObjects([startMarker]);
      map.getViewModel().setLookAtData({ bounds });
    }
  }

  function createMarker(coords, color, number, home) {
    if (!home) {
      if (coords?.status === 'failed') {
        color = 'red';
      } else if (coords?.status === 'delivered') {
        color = 'green';
      } else {
        color = '#FF7733'
      }
    }
    const icon = getMarkerIcon(color, number);
    const marker = new H.map.Marker({ lat: coords.pharmacy_details.latitude, lng: coords.pharmacy_details.longitude }, { icon });
    const infoContent = `
    <div style="width: 400px; font-size: 12px;">
      ${home ? (
        `<div>
          <p>
            <span style="font-weight: 700;">Address: </span>
            ${routeDetail?.address}
          </p>
          ${routeDetail?.routeStatus === "In Progress" ? (
            `<p>
              <span style="font-weight: 700;">
                Planned Arrival Time: 
              </span>
              ${modifyDateTime(routeDetail?.startTime, 0.0)}
            </p>`
          ) : (
            `<p>
              <span style="font-weight: 700;">
                Planned Arrival Time: 
              </span>
              Not Started Yet
            </p>`
          )}
        </div>`
      ) : (
        `<div>
          <p>
            <span style="font-weight: 700;">Name: </span>
            ${coords.pharmacy_details.name}
          </p>
          <p>
            <span style="font-weight: 700;">Address: </span>
            ${coords.pharmacy_details.location}
          </p>
          ${routeDetail?.routeStatus === "In Progress" ? (
            `<p>
              <span style="font-weight: 700;">
                Planned Arrival Time: 
              </span>
              ${modifyDateTime(routeDetail?.startTime, coords.estimatedTimeCal)}
            </p>`
          ) : routeDetail?.routeStatus === "Done" ? (
            `<div>
              <p>
                <span style="font-weight: 700;">
                  Planned Arrival Time: 
                </span>
                ${modifyDateTime(routeDetail?.startTime, coords.estimatedTimeCal)}
              </p>
              <p>
                <span style="font-weight: 700;">
                  Delivery Time: 
                </span>
                ${moment(coords.order.deliverAt).format("hh:mm A")}
              </p>
            </div>`
          ) : (
            `<p>
              <span style="font-weight: 700;">
                Planned Arrival Time: 
              </span>
              ${floatToTime(coords.estimatedTimeCal)} (after start)
            </p>`
          )}
        </div>`
      )}
    </div>
    `;

    if(currentBubble) {
      ui.current.removeBubble(currentBubble);
      setCurrentBubble(null);
    }

    marker.addEventListener('tap', () => showInfoBubble(marker, infoContent));
    return marker;
  }

  function getMarkerIcon(color, number) {
    return new H.map.Icon(
      `<svg width="30" height="20" xmlns="http://www.w3.org/2000/svg">
        <rect x="0" y="0" width="30" height="20" fill="${color}" stroke="${color}" stroke-width="2" />
        <path d="M189.5 350L111.441 257.726L267.559 257.726L189.5 350Z" fill="${color}"/>
        <text x="50%" y="50%" font-family="Arial" font-size="10" font-weight="bold" text-anchor="middle" alignment-baseline="middle" fill="white">${number}</text>
      </svg>`, { anchor: { x: 15, y: 15 } }
      );
  }

  function showInfoBubble(marker, text) {
    const bubble = new H.ui.InfoBubble(marker.getGeometry(), { content: text });
    ui.current.addBubble(bubble);
    setCurrentBubble(bubble);
  }


  const getMarkerIconWithNumber = (number) => {
    const svg = `
      <svg width="438" height="350" viewBox="0 0 438 350" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="35"  width="379" height="227.394" fill="#FF7733" />
        <path d="M189.5 350L111.441 257.726L267.559 257.726L189.5 350Z" fill="#FF7733"/>
        <text x="40%" y="50%" text-anchor="middle" dominant-baseline="middle" font-size="129" fill="white">${number}</text>
      </svg>
    `;

    return `data:image/svg+xml;base64,${btoa(svg)}`;
  }

  const handleDeleteRouteOrder = async () => {
   console.log("Delete")
    setLoading(true);
    try {
      for (const id of selectedRouteIds) {
        const response = await deleteApiWithAuth(CREATE_ROUTE_RETURN_ORDER + `${id}/`);
        if (response.data !== undefined) {
          // setRouteOrders(response.data.data)
          console.log(response);
        }
      }
    } catch (error) {
      console.error('Error deleting routes:', error);
    } finally {
      const response = await getApiWithAuth(REOPTIMIZED_ROUTE_RETURN + `${rid}/`);
      if (response.data !== undefined) {
        navigate(`/detailreturnHere/route/${rid}`);
        setLoading(false);
      } else {
        console.log("Failed");
        setLoading(false);
      }
    }
  }

  const handleCustomSplitRouteOrder = async () => {

    setLoading(true);
    try {
      const response = await putApiWithAuth(SPLIT_ROUTE_RETURN + `custom/${rid}/`, {routeOrderIds: selectedRouteIds});
      if (response.data !== undefined) {
        // setRouteOrders(response.data.data)
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.log(response);
      } else {
        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          });
      }
    } catch (error) {
      console.error('Error deleting routes:', error);
    } finally {
      const response = await getApiWithAuth(REOPTIMIZED_ROUTE_RETURN + `${rid}/`);
      if (response.data !== undefined) {
        navigate(`/detailreturnHere/route/${rid}`);
      } else {
        console.log("Failed");
      }
      setLoading(false);
    }
  }

  const handleUpdateRouteOrder = async (id, address) => {
    setOrderId(id);
    setModalOpen(true);
    setFormState({
      ...formState,
      'address': address,
    })
  }

  const handleUpdateClose = () => {
    setModalOpen(false);
    setOrderId("");
  };

  const handleSaveOrder = async () => {
    setLoading(true);
    const response = await patchApiWithAuth(CREATE_ORDER+ `/${orderId}`, formState);
    if (response.status !== 400) {
      toast.success('Order Updated Successfully!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    } 
    else {
      toast.error('Backend Error!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
    setModalOpen(false);
    setLoading(false);
    setFormState({
      address: "",
    });
    
    getRouteDetail();
  };

  const handleUpdateRoute = async () => {
    const formState = {
      movingTo: route,
      routeOrders: selectedRouteIds
    };
    const response = await patchApiWithAuth(CREATE_ROUTE_RETURN_ORDER+ `${rid}/change_route_order/`, formState);
    if (response.status !== 400) {
      toast.success('Route Orders Transfer Successfully!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    } 
    else {
      toast.error('Backend Error!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
    setMergeModal(false);
    setLoading(false);
    setRoute("");
    
    getRouteDetail();
  };

  const handleRouteAddressModalOpen = async (id, address) => {
    setModalAddressOpen(true);
    setFormState({
      ...formState,
      'address': routeDetail?.address,
    })
  }

  const handleRouteAddressModalClose = () => {
    setModalAddressOpen(false);
  };

  const handleSaveRouteAddress = async () => {
    setLoading(true);
    const response = await patchApiWithAuth(ALL_ROUTES+ `${routeDetail?.id}/update_address/`, formState);
    if (response.status !== 400) {
      toast.success('Route Address Updated Successfully!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    } 
    else {
      toast.error('Backend Error!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
    setModalAddressOpen(false);
    setFormState({
      address: "",
    });
    getRouteDetail();
  };

  const handleRouteSelect = (e, value) => {
    if (value !== null) {
      setRoute(value.id);
    } else {
      setRoute("")
    }
  };

  return (
    <div >
      {loading && <CustomLoader />}
      <Grid container spacing={2}>
        <Grid item xs={4}>
        <div className='routesAction'>
  <h3>Route Stops</h3>
  <div>
    <Button color='primary' onClick={handleCustomSplitRouteOrder}>
      <p>Create New Route</p>
    </Button>
    {routeDetail.type === "Order" && (
    <IconButton color='primary' onClick={() => handleMergeModalOpen("Order")}>
      <CallMergeIcon style={{ color: 'blue' }} />
    </IconButton>
    )}
    {routeDetail.type === "Pharmacy" && (
    <IconButton color='primary' onClick={() => handleMergeModalOpen("Pharmacy")}>
      <CallMergeIcon style={{ color: 'blue' }} />
    </IconButton>
    )}
    <IconButton color='error' onClick={handleDeleteRouteOrder}>
      <DeleteIcon style={{ color: 'red' }} />
    </IconButton>
    <IconButton color='default' onClick={() => navigate(`/detailreturnHere/route/${rid}`)}>
      <CloseIcon style={{ color: 'grey' }} />
    </IconButton>
  </div>
</div>
          <div className='routesOrderList'>
            <div className="routePharmacy">
                <div
                  style={{
                    position: 'absolute',
                    top: '-10px',   // Adjust the top position as needed
                    left: '-10px',  // Adjust the left position as needed
                    width: '35px',
                    height: '35px',
                    backgroundColor: '#0B6699',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                >
                <i className="fa fa-home" /> 
                </div>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <h4>{routeDetail.detail_name}</h4>
                  <div className="route-order-address" style={{minWidth: "100%"}}>
                    <img className="detail-in-icon" src={buildings} alt="Logo" />
                    <div className="route-addres-div">
                      <h3 className="route-pharmacy-addres-text">
                        {routeDetail.address}
                      </h3>
                        {/* <div className="address-div2">
                          <h3 className="apt-text">
                            Copay: <span className="copay-in">${order.order.totalcopay}</span>
                          </h3>
                        </div>
                        <div className="address-div2">
                          <p className="collect-text">Collected</p>
                        </div> */}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{display: 'flex', justifyContent: 'flex-end', alignItems: "flex-end"}}>
                  <div>
                    <h3 style={{cursor: 'pointer'}} onClick={handleRouteAddressModalOpen}>Edit</h3>
                  </div>
                </Grid>
              </Grid>
            </div>
            {routeOrders.length > 0 && routeOrders.map((order, i) => (
              <div style={{display: "flex", alignItems: 'center'}}>
                <input
                  type="checkbox"
                  autoComplete="off"
                  checked={selectedRouteIds.includes(order.id)}
                  onChange={() => handleRouteSelection(order.id)}
                />
                <div className="routeOrderObject">
                  <div
                    style={{
                      position: 'absolute',
                      top: '0px',   // Adjust the top position as needed
                      left: '0px',  // Adjust the left position as needed
                      width: '30px',
                      height: '30px',
                      backgroundColor: '#0B6699',
                      borderRadius: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    {i + 1}
                  </div>
                  <div
                    style={{
                      position: 'absolute',
                      top: '0px',   // Adjust the top position as needed
                      right: '10px',  // Adjust the left position as needed
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    <IconButton color='primary' onClick={() => handleUpdateRouteOrder(order.order.id, order.order.address)}>
                      <ModeEditIcon color='primary' />
                    </IconButton>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={7}>
                      <div className="route-order-main">
                      <div>
                            <h3
                              className="cust-name"
                              onClick={() => handleCopayModalOpen(order)}
                            >
                              <img
                                className="route-detail-in-icon"
                                src={userBlack}
                                alt="new Icon"
                              />
                              {order.pharmacy_details.name}
                              {order.status === "delivered" ? (
                                <CheckCircleIcon
                                  style={{ color: "green", fontSize: "18px" }}
                                />
                              ) : order.status === "failed" ? (
                                <CancelIcon
                                  style={{ color: "red", fontSize: "18px" }}
                                />
                              ) : null}
                            </h3>
                          </div>
                        <div style={{    
                          display: 'flex',
                          alignItems: 'flex-end',
                          flexDirection: 'column',
                        }}>
                          {order.order.totalcopay !== 0 && (
                            <div
                              className="routeOrderTotalCopay"
                              style={{ width: "100%" }}
                            >
                              <p>Copay: </p>
                              {/* <p>$ {order.order.totalcopay.toFixed(2)}</p> */}
                            </div>
                          )}
                          {order.order.refrigrated && (
                            <div
                              className="route-urgent-tag refri"
                              style={{ fontSize: 13, padding: "0px 22px" }}
                            >
                              Refrigerated
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="route-order-customer">
                        <div className="cust-div">
                          <div className="cust-detail">
                            <div className="detail-div">
                              <img className="detail-in-icon" src={cellphone} alt="Logo" />
                              <h3 className="detail-in-text">{order.pharmacy_details.contact_no}</h3>
                            </div>
                            {/* <div className="detail-div">
                              <img className="detail-in-icon" src={iphone} alt="Logo" />
                              <h3 className="detail-in-text">{order.pharmacy_details.cell}</h3>
                            </div> */}
                            <div className="detail-div">
                              <img className="detail-in-icon" src={landline} alt="Logo" />
                              <h3 className="detail-in-text">{order.pharmacy_details.emergency_contact}</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="route-order-address" style={{minWidth: "100%"}}>
                        <img className="detail-in-icon" src={buildings} alt="Logo" />
                        <div className="addres-div">
                          <h3 className="addres-text" style={{display: "flex"}}>
                            {order.pharmacy_details.location}
                          </h3>
                          {/* <h3 className="apt-text" style={{textAlign: 'right'}}>
                            Apt #: <span className="apt-in">{order.order.apart}</span>
                          </h3> */}
                            {/* <div className="address-div2">
                              <h3 className="apt-text">
                                Copay: <span className="copay-in">${order.order.totalcopay}</span>
                              </h3>
                            </div>
                            <div className="address-div2">
                              <p className="collect-text">Collected</p>
                            </div> */}
                        </div>
                        
                      </div>
                      <div className="route-order-customer" style={{minWidth: "100%"}}>
                          <div className="cust-div">
                            <div className="cust-detail">
                              <div className="detail-div">
                              <h3
                              className="detail-in-text"
                              style={{ display: "flex" }}
                            >
                             <strong>Total Copay: </strong>&nbsp;  {order.total_copay}  
                            </h3>
                              </div>
                            <div className="detail-div">
                              <h3
                              className="detail-in-text"
                              style={{ display: "flex" }}
                            >
                             <strong>From Date: </strong>&nbsp; {order.from_date}
                            </h3>
                              </div>
                              <div className="detail-div">
                              <h3
                              className="detail-in-text"
                              style={{ display: "flex" }}
                            >
                             <strong>To Date: </strong>&nbsp; {order.to_date}
                            </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                    </Grid>
                    <Grid item xs={5}>
                      <div className="route-note-div">
                        <h3 className="route-note-head">Delivery Note</h3>
                        <p className="route-note-text">
                          {order.order.notes}
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            ))}
          </div>
        </Grid>
        <Grid item xs={8}>
          <div style={{display: "flex", justifyContent: "space-around"}}>
            <div>
              <label style={{marginRight: "10px"}}>
                <input
                  type="radio"
                  value="LiveMap"
                  checked={mapType === 'LiveMap'}
                  onChange={handleMapTypeChange}
                />
                Live Request
              </label>
              <label>
                <input
                  type="radio"
                  value="StorePolyline"
                  checked={mapType === 'StorePolyline'}
                  onChange={handleMapTypeChange}
                />
                Stored Map
              </label>
            </div>
            <div>
              <label style={{marginRight: "10px"}}>
                <input
                  type="radio"
                  value="TIME"
                  disabled={mapType === 'StorePolyline'}
                  checked={optimizeFor === 'TIME'}
                  onChange={handleOptimizeForChange}
                />
                Optimize by Time
              </label>
              <label>
                <input
                  type="radio"
                  disabled={mapType === 'StorePolyline'}
                  value="DISTANCE"
                  checked={optimizeFor === 'DISTANCE'}
                  onChange={handleOptimizeForChange}
                />
                Optimize by Mile
              </label>
            </div>
          </div>
          <div style={{ width: "100%", height: "87vh" }} ref={mapRef} />
        </Grid>
      </Grid>
      <Dialog open={mergeModal} onClose={handleMergeModalClose}
      sx={{
        '& .MuiDialog-paper': { minWidth: '600px', height: '50vh' } // Adjust width and height as needed
      }}>
        <DialogTitle>Move to Other Route</DialogTitle>
        <DialogContent style={{ height: '100%'}}>
          <label className="pharmacy-field-label">Route List</label>
          <MUIAutocomplete
            style={{ padding: "0" }}
            disablePortal
            id="combo-box-demo"
            options={filteredRouteList}
            className="filter-input"
            sx={{
              width: 300,
              "& .MuiOutlinedInput-root": {
                p: 0,
              },
              "& .MuiAutocomplete-tag": {
                bgcolor: "primary.lighter",
                border: "0px solid",
                borderColor: "transparent",
              },
            }}
            renderInput={(params) => (
              <TextField {...params} fullWidth placeholder="Select Route" sx={{
                "& input::placeholder": {
                  fontSize: "14px",
                  opacity:1
                },
                "& input": {
                  fontSize: '14px !important'
                }
              }}/>
            )}
            popupIcon={<ArrowDropDownCircle sx={{color: 'black', width: 20}}/>}
            getOptionLabel={(option) => `${option.name} (${option.id})`}
  renderOption={(props, option) => (
    <li {...props} key={option.id}>
      <p style={{ fontSize: '13px' }}>{option.detail_name}</p>
    </li>
  )}
            onChange={handleRouteSelect}
          />
          <DialogActions style={{marginTop: '250px'}}>
            <Button onClick={handleUpdateRoute} variant="contained" style={{marginRight: '10px'}} color="primary">
              Save
            </Button>
            <Button onClick={handleMergeModalClose} variant="outlined" color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog open={modalOpen} onClose={handleUpdateClose}>
        <DialogTitle>Update Order Address</DialogTitle>
        <DialogContent>
          <label className="pharmacy-field-label">Address</label>
          <input
            type="text"
            className="modal-field"
            name="address"
            placeholder="Address"
            style={{marginBottom: '10px'}}
            value={formState.address}
            onChange={handleInputChange}
            autoComplete="off"
          />
          <DialogActions>
            <Button onClick={handleSaveOrder} variant="contained" style={{marginRight: '10px'}} color="primary">
              Save
            </Button>
            <Button onClick={handleUpdateClose} variant="outlined" color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog open={modalAddressOpen} onClose={handleRouteAddressModalClose}>
          <DialogTitle>Update Route Address</DialogTitle>
          <DialogContent>
            <label className="pharmacy-field-label">Address</label>
            <input
              type="text"
              className="modal-field"
              name="address"
              placeholder="Address"
              style={{marginBottom: '10px'}}
              value={formState.address}
              onChange={handleInputChange}
              autoComplete="off"
            />
            <Button onClick={handleSaveRouteAddress} variant="contained" style={{marginRight: '10px'}} color="primary">
              Save
            </Button>
            <Button onClick={handleRouteAddressModalClose} variant="outlined" color="secondary">
              Cancel
            </Button>
          </DialogContent>
      </Dialog>
      <Dialog open={modalCopayOpen} onClose={handleCopayModalClose}>
        <DialogTitle>Add Copay Details</DialogTitle>
        <DialogContent>
          <label className="pharmacy-field-label">Start Date</label>
          <input
            type="date"
            className="modal-field"
            name="from_date"
            placeholder="Start Date"
            style={{ marginBottom: "10px" }}
            value={formCopayState.from_date}
            onChange={handleInputChange}
            autoComplete="off"
          />
          <label className="pharmacy-field-label">End Date</label>
          <input
            type="date"
            className="modal-field"
            name="to_date"
            placeholder="To Date"
            style={{ marginBottom: "10px" }}
            value={formCopayState.to_date}
            onChange={handleInputChange}
            autoComplete="off"
          />
          <label className="pharmacy-field-label">Copay</label>
          <input
            type="number"
            className="modal-field"
            name="total_copay"
            placeholder="Copay"
            style={{ marginBottom: "10px" }}
            value={formCopayState.total_copay}
            onChange={handleInputChange}
            step="0.01" // Allows decimal numbers
            min="0" // Ensures only non-negative numbers can be entered
            autoComplete="off"
          />
          <Button
            onClick={handleSaveCopay}
            variant="contained"
            style={{ marginRight: "10px" }}
            color="primary"
          >
            Save
          </Button>
          <Button
            onClick={handleCopayModalClose}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default RouteReturnUpdate;