import React, { useEffect } from "react";
import { Chart, Line, Pie } from "react-chartjs-2";
// Import Chart.js auto registration feature
import "chart.js/auto";
import LineChart from "./LineChart";
import PieChart from "./PieChart";
import BarCharts from "./BarCharts";
import OrderSummary from "./OrderSummary";
import DashboardBottom from "./DashboardBottom";
import BarTopContent from "./BarTopContent";
const Dashboard = () => {
  return (
    <div className="flex flex-col gap-5 w-full px-3">
      <OrderSummary />
    </div>
  );
};

export default Dashboard;
