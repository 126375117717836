import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import {
  useJsApiLoader,
  GoogleMap,
  DirectionsRenderer,
  Marker,
  Polyline,
  InfoWindow,
} from "@react-google-maps/api";
import Autocomplete from "react-google-autocomplete";
import {
  Menu,
  MenuItem,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Link } from "react-router-dom";
import CustomLoader from "../../CommonComponents/Loader/CustomLoader";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import { toast } from "react-toastify";

import { ROUTE_DETAIL, ALL_ROUTES, REOPTIMIZED_ROUTE } from "../../../utils/apiUrls";
import { getApiWithAuth, patchApiWithAuth } from "../../../utils/api";

import {
  landline,
  iphone,
  cellphone,
  buildings,
  open_route,
  info,
  userBlack,
} from "../../../assests";

import RouteObject from "../../MiniComponents/RouteObject";
import "./routeDetail.css";
import moment from "moment";

const mapStyles = {
  width: "100%",
  height: "100%",
};

const MAX_WAYPOINTS_PER_REQUEST = 25;

function floatToTime(floatValue) {
  const hours = Math.floor(floatValue / 60);
  const remainingMinutes = floatValue % 60;
  const minutes = Math.floor(remainingMinutes);
  const seconds = Math.round((remainingMinutes % 1) * 60);

  const parts = [];
  if (hours > 0) {
    parts.push(`${hours} hour${hours !== 1 ? "s" : ""}`);
  }
  if (minutes > 0) {
    parts.push(`${minutes} minute${minutes !== 1 ? "s" : ""}`);
  }
  if (seconds > 0) {
    parts.push(`${seconds} second${seconds !== 1 ? "s" : ""}`);
  }

  return parts.join(" ");
}

function modifyDateTime(dateTimeString, addedMinutes) {
  const dateTime = new Date(dateTimeString);
  dateTime.setMinutes(dateTime.getMinutes() + Math.floor(addedMinutes));
  return dateTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
}

function chunkOrders(orders) {
  const chunks = [];
  for (let i = 0; i < orders.length; i += MAX_WAYPOINTS_PER_REQUEST) {
    chunks.push(orders.slice(i, i + MAX_WAYPOINTS_PER_REQUEST));
  }
  return chunks;
}

function RouteReturnDetail() {
  const [loading, setLoading] = useState(false);
  const [directions, setDirections] = useState(null);
  const [allDirections, setAllDirections] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [routeData, setRouteData] = useState(false);
  const [map, setMap] = useState(null);
  const [selectedLegIndex, setSelectedLegIndex] = useState(null);
  const [selectRouteDetail, setSelectRouteDetail] = useState(null);
  const [route, setRoute] = useState(null);
  const [routes, setRoutes] = useState();
  const [routeOrders, setRouteOrders] = useState([]);
  const [allRouteOrders, setAllRouteOrders] = useState([]);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [startToEnd, setStartToEnd] = useState(false);
  const [endAnywhere, setEndAnywhere] = useState(true);
  const [endSpecific, setEndSpecific] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [orderModalOpen, setOrderModalOpen] = useState(false);
  const [order, setOrder] = useState(0);
  const [routeOrderId, setRouteOrderId] = useState(0);
  const [address, setAddress] = useState("");
  const [home, setHome] = useState(null);
  const [formState, setFormState] = useState({
    address: "",
  });

  // Function to handle checkbox changes
  const handleCheckboxChange = (type) => {
    switch (type) {
      case "startToEnd":
        setStartToEnd(!startToEnd);
        setEndAnywhere(false);
        setEndSpecific(false);
        break;
      case "endAnywhere":
        setStartToEnd(false);
        setEndAnywhere(!endAnywhere);
        setEndSpecific(false);
        break;
      case "endSpecific":
        setStartToEnd(false);
        setEndAnywhere(false);
        setEndSpecific(!endSpecific);
        break;
      default:
        break;
    }
  };

  const { rid, copay } = useParams();

  const getRouteDetail = async () => {
    setLoading(true);

    const response = await getApiWithAuth(ROUTE_DETAIL + `?route=${rid}`);
    if (response.data !== undefined) {
      if (copay && copay === "copay") {
        const responseCopay = await getApiWithAuth(
          ALL_ROUTES + `getCopayOrders/?route_id=${rid}`
        );
        setAllRouteOrders(responseCopay.data.data);
      } else {
        setAllRouteOrders(response.data.data.orders);
      }
      setRouteOrders(response.data.data.orders);
      setRoutes(response.data.data.route);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const updateRoute = async (directions) => {
    if (!routeData) {
      const response = await patchApiWithAuth(ALL_ROUTES + `${rid}/`, {route_data: directions});
      if (response.data !== undefined) {
        setRouteData(true)
      } else {
        setRouteData(false)
      }
    }
  }

  const updateRouteAll = async (directions) => {
    if (!routeData) {
      const response = await patchApiWithAuth(ALL_ROUTES + `${rid}/`, {all_route_data: directions});
      if (response.data !== undefined) {
        setRouteData(true)
      } else {
        setRouteData(false)
      }
    }
  }

  const handleSearch = (e) => {
    const filteredOrders = routeOrders.filter(order =>
      order.order.slug.toLowerCase().includes(e.toLowerCase())
    );
    setAllRouteOrders(filteredOrders);
  };

  const reOptimizedRoute = async () => {
    setLoading(true);
    const response = await getApiWithAuth(REOPTIMIZED_ROUTE + `${rid}/`);
    if (response.data !== undefined) {
      getRouteDetail();
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const onLoad = (map) => {
    setMap(map);
  };

  useEffect(() => {
    getRouteDetail();
  }, []);

  useEffect(() => {
    if (map && routeOrders !== undefined && routeOrders.length > 0) {
      // if (routes?.route_data === null) {
      const directionsService = new window.google.maps.DirectionsService();

      setLatitude(routeOrders[0].pharmacy_details.latitude);
      setLongitude(routeOrders[0].pharmacy_details.longitude);

      if (routeOrders.length <= MAX_WAYPOINTS_PER_REQUEST) {
        // If less than or equal to 25, proceed with a single request
        const waypoints = routeOrders.map((order) => ({
          location: {
            lat: parseFloat(order.pharmacy_details.latitude),
            lng: parseFloat(order.pharmacy_details.longitude),
          },
          stopover: true,
        }));
       console.log("latlng231",waypoints)
        const request = {
          origin: routes?.address,
          destination: `${routeOrders[routeOrders.length - 1].pharmacy_details.latitude},${
            routeOrders[routeOrders.length - 1].pharmacy_details.longitude
          }`,
          waypoints,
          travelMode: "DRIVING",
          // optimizeWaypoints: true
        };
      
        directionsService.route(request, (result, status) => {
          if (status === "OK") {
            setDirections(result);
            // updateRoute(result);
          }
        });
      } else {
        // If more than 25 orders, split into multiple requests
        const orderChunks = chunkOrders(routeOrders);
        console.log(orderChunks);
        const directionsArray = [];
  
        // Helper function to route a chunk and return a Promise
        const routeChunk = (chunk, index) => {
          return new Promise((resolve, reject) => {
            const waypoints = chunk.map((order) => ({
              location: {
                lat: parseFloat(order.pharmacy_details.latitude),
                lng: parseFloat(order.pharmacy_details.longitude),
              },
              stopover: true,
            }));
  
            let request;
  
            if (index === 0) {
              request = {
                origin: routes?.address,
                destination: `${chunk[chunk.length - 1].pharmacy_details.latitude},${
                  chunk[chunk.length - 1].pharmacy_details.longitude
                }`,
                waypoints,
                travelMode: "DRIVING",
              };
            } else {
              request = {
                origin: orderChunks[index - 1][0]?.pharmacy_details?.address,
                destination: `${chunk[chunk.length - 1].pharmacy_details.latitude},${
                  chunk[chunk.length - 1].pharmacy_details.longitude
                }`,
                waypoints,
                travelMode: "DRIVING",
              };
            }
  
            directionsService.route(request, (result, status) => {
              if (status === "OK") {
                directionsArray.push(result);
                resolve();
              } else {
                reject(new Error(`Failed to get directions. Status: ${status}`));
              }
            });
          });
        };
  
        // Iterate through each chunk and make separate requests using async/await
        
        const routeChunksAsync = async () => {
          for (let i = 0; i < orderChunks.length; i++) {
            await routeChunk(orderChunks[i], i);
          }
          console.log(directionsArray);
          setAllDirections(directionsArray);
          // updateRouteAll(directionsArray);
        };
  
        routeChunksAsync();
      }
        
      // } else {
      //   if (routeOrders.length <= MAX_WAYPOINTS_PER_REQUEST) {
      //     setDirections(routes?.route_data);
      //   } else {
      //     setAllDirections(routes?.all_route_data)
      //   }
      // }
    }
  }, [map, routeOrders]);
  console.log(directions)


  const onMarkerMouseClose = () => {
    setSelectedLegIndex(null);
    setSelectRouteDetail(null);
    setHome(null);
  };

  const onMarkerMouseClick = (marker, leg) => {
    setSelectedLegIndex(marker);
    if (leg === "Home") {
      setHome(true);
      setSelectRouteDetail(routeOrders[0]);
    } else {
      setHome(false);
      setSelectRouteDetail(
        leg === routeOrders.length ? routeOrders[leg - 1] : routeOrders[leg]
      );
    }
  };

  console.log(directions)

  useEffect(() => {
    if (directions) {
      const geocoder = new window.google.maps.Geocoder();
      const startAddress = directions.routes[0].legs[0].start_address;
      console.log("startAddress",startAddress)
      const lastLeg = directions.routes[0].legs[0];
      console.log("lastLeg",lastLeg)
      geocoder.geocode({ address: startAddress }, (results, status) => {
        if (status === "OK" && results[0].geometry) {
          const position = results[0].geometry.location;
          console.log("position355",position)
          const marker = new window.google.maps.Marker({
            position,
            map,
            title: directions.routes[0].legs[0].start_address,
            icon: {
              url: `${getMarkerIconWithNumber("S", "#FF7733")}`,
              scaledSize: new window.google.maps.Size(32, 32), // Adjust the size as needed
            },
          });

          marker.addListener("click", () => onMarkerMouseClick(marker, "Home"));

          const polylinePath = [
            {
              lat: lastLeg.start_location.lat,
              lng: lastLeg.start_location.lng,
            },
            { lat: position.lat(), lng: position.lng() },
          ];
          console.log("positionlatlong",polylinePath)

          const polylineOptions = {
            path: polylinePath,
            geodesic: true,
            strokeColor: "#00FF00",
            strokeOpacity: 0.5,
            strokeWeight: 10,
          };

          const polyline = new window.google.maps.Polyline(polylineOptions);
          polyline.setMap(map);
        }
      });

      let number = ""
      let markerAddresses = ""

      directions.routes[0].legs.forEach((leg, legIndex) => {
        const endAddress = leg.end_address;
      
        geocoder.geocode({ address: endAddress }, (results, status) => {
          if (status === "OK" && results[0].geometry) {
            const position = results[0].geometry.location;
      
            // Check if the current address is the same as the previous one
            const currentMarkerNumber =
              legIndex + 1 === directions.routes[0].legs.length
                ? "E"
                : `${legIndex + 1}`;

            number = markerAddresses === endAddress ? number + `, ${legIndex + 1}` : `${legIndex + 1}`

            console.log("number",number)
            const marker = new window.google.maps.Marker({
              position,
              map,
              title: endAddress,
              icon: {
                url: // Your marker icon URL
                  legIndex + 1 === directions.routes[0].legs.length
                    ? `${getMarkerIconWithNumber("E", "#FF7733")}`
                    : `${getMarkerIconWithNumber(
                        number,
                        "#FF7733"
                      )}`,
                scaledSize: new window.google.maps.Size(32, 32),
              },
            });
            console.log("marker",marker)
            markerAddresses = endAddress;
            console.log("markerAddresses",markerAddresses)
      
            marker.addListener("click", () =>
              onMarkerMouseClick(marker, legIndex)
            );
      
            if (legIndex < directions.routes[0].legs.length - 1) {
              // Draw polyline connecting the current marker to the next leg's start point
              const nextLeg = directions.routes[0].legs[legIndex + 1];
              console.log("nextLeg",nextLeg)
              const polylinePath = [
                { lat: position.lat(), lng: position.lng() },
                {
                  lat: nextLeg.start_location.lat,
                  lng: nextLeg.start_location.lng,
                },
              ];
              console.log("polylinePath",polylinePath)
      
              const polylineOptions = {
                path: polylinePath,
                geodesic: true,
                strokeColor: "#00FF00",
                strokeOpacity: 0.5,
                strokeWeight: 10,
              };
      
              const polyline = new window.google.maps.Polyline(polylineOptions);
              console.log("polylinepolyline",polyline)
              polyline.setMap(map);
            }
          }
        });
      });
    }
    else {
      if (allDirections.length > 0) {
        allDirections.map((directions, index) => {
          console.log(directions)
          if (index === 0) {
            const geocoder = new window.google.maps.Geocoder();
            const startAddress = directions.routes[0].legs[0].start_address;
            const lastLeg = directions.routes[0].legs[0];
            geocoder.geocode({ address: startAddress }, (results, status) => {
              if (status === "OK" && results[0].geometry) {
                const position = results[0].geometry.location;

                const marker = new window.google.maps.Marker({
                  position,
                  map,
                  title: directions.routes[0].legs[0].start_address,
                  icon: {
                    url: `${getMarkerIconWithNumber("S", "#FF7733")}`,
                    scaledSize: new window.google.maps.Size(32, 32), // Adjust the size as needed
                  },
                });

                marker.addListener("click", () => onMarkerMouseClick(marker, "Home"));

                const polylinePath = [
                  {
                    lat: lastLeg.start_location.lat(),
                    lng: lastLeg.start_location.lng(),
                  },
                  { lat: position.lat(), lng: position.lng() },
                ];

                const polylineOptions = {
                  path: polylinePath,
                  geodesic: true,
                  strokeColor: "#00FF00",
                  strokeOpacity: 0.5,
                  strokeWeight: 10,
                };

                const polyline = new window.google.maps.Polyline(polylineOptions);
                polyline.setMap(map);
              }
            });

            let number = ""
            let markerAddresses = ""

            directions.routes[0].legs.forEach((leg, legIndex) => {
              const endAddress = leg.end_address;
              geocoder.geocode({ address: endAddress }, (results, status) => {
                if (status === "OK" && results[0].geometry) {
                  const position = results[0].geometry.location;

                  number = markerAddresses === endAddress ? number + `, ${legIndex + 1}` : `${legIndex + 1}`

                  const marker = new window.google.maps.Marker({
                    position,
                    map,
                    title: endAddress,
                    icon: {
                      url:
                        legIndex + 1 === directions.routes[0].legs.length
                          ? `${getMarkerIconWithNumber("25", "#FF7733")}`
                          : `${getMarkerIconWithNumber(
                              number,
                              "#FF7733"
                            )}`, // Replace with your marker icon URL
                      scaledSize: new window.google.maps.Size(32, 32), // Adjust the size as needed
                    },
                  });

                  markerAddresses = endAddress;

                  marker.addListener("click", () =>
                    onMarkerMouseClick(marker, (index * 25) + legIndex)
                  );

                  if (legIndex < directions.routes[0].legs.length - 1) {
                    // Draw polyline connecting the current marker to the next leg's start point
                    const nextLeg = directions.routes[0].legs[legIndex + 1];
                    const polylinePath = [
                      { lat: position.lat(), lng: position.lng() },
                      {
                        lat: nextLeg.start_location.lat(),
                        lng: nextLeg.start_location.lng(),
                      },
                    ];

                    const polylineOptions = {
                      path: polylinePath,
                      geodesic: true,
                      strokeColor: "#00FF00",
                      strokeOpacity: 0.5,
                      strokeWeight: 10,
                    };

                    const polyline = new window.google.maps.Polyline(polylineOptions);
                    polyline.setMap(map);
                  }
                }
              });
            });
          } else {
            const geocoder = new window.google.maps.Geocoder();
            const startAddress = directions.routes[0].legs[0].start_address;
            const lastLeg = directions.routes[0].legs[0];

            let number = ""
            let markerAddresses = ""

            directions.routes[0].legs.forEach((leg, legIndex) => {
              const endAddress = leg.end_address;
              geocoder.geocode({ address: endAddress }, (results, status) => {
                if (status === "OK" && results[0].geometry) {
                  const position = results[0].geometry.location;

                  number = markerAddresses === endAddress ? number + `, ${(index*25)+ legIndex + 1}` : `${(index*25)+ legIndex + 1}`

                  const marker = new window.google.maps.Marker({
                    position,
                    map,
                    title: endAddress,
                    icon: {
                      url:
                      legIndex + 1 === directions.routes[0].legs.length && index + 1 === allDirections.length
                          ? `${getMarkerIconWithNumber("E", "#FF7733")}`
                          : `${getMarkerIconWithNumber(
                              number,
                              "#FF7733"
                            )}`, // Replace with your marker icon URL
                      scaledSize: new window.google.maps.Size(32, 32), // Adjust the size as needed
                    },
                  });

                  markerAddresses = endAddress;


                  marker.addListener("click", () =>
                    onMarkerMouseClick(marker, (index * 25) + legIndex)
                  );
                  if (legIndex < directions.routes[0].legs.length - 1) {
                    // Draw polyline connecting the current marker to the next leg's start point
                    const nextLeg = directions.routes[0].legs[legIndex + 1];
                    const polylinePath = [
                      { lat: position.lat(), lng: position.lng() },
                      {
                        lat: nextLeg.start_location.lat(),
                        lng: nextLeg.start_location.lng(),
                      },
                    ];

                    const polylineOptions = {
                      path: polylinePath,
                      geodesic: true,
                      strokeColor: "#00FF00",
                      strokeOpacity: 0.5,
                      strokeWeight: 10,
                    };

                    const polyline = new window.google.maps.Polyline(polylineOptions);
                    polyline.setMap(map);
                  }
                }
              });
            });
          }
        })
      }
    }
  }, [directions, allDirections]);

  const GOOGLE_MAPS_API_KEY = "AIzaSyDWHHyyp0MS94zSt5ONEHeazFnLUDfRrmQ";

  const options = {
    types: ["address"],
    componentRestrictions: { country: "us" },
    bounds: {
      north: 42.0505,  // Northernmost point roughly in CT
      south: 38.9285,  // Southernmost point roughly in NJ
      west: -79.7624,  // Westernmost point roughly in NY
      east: -71.1851   // Easternmost point roughly in CT
    },
    strictBounds: true,
  };

  const handlePlaceSelect = (place) => {
    setFormState({
      ...formState,
      address: place.formatted_address,
    });
    setAddress(place.formatted_address);
  };

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API,
    libraries: ["geometry"],
  });

  const getMarkerIconWithNumber = (number, color) => {
    const svg = `
      <svg width="438" height="350" viewBox="0 0 438 350" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="35"  width="379" height="227.394" fill="${color}" />
        <path d="M189.5 350L111.441 257.726L267.559 257.726L189.5 350Z" fill="${color}"/>
        <text x="40%" y="50%" text-anchor="middle" dominant-baseline="middle" font-size="129" fill="white">${number}</text>
      </svg>
    `;

    return `data:image/svg+xml;base64,${btoa(svg)}`;
  };

  const handleRouteAddressModalOpen = async (id, address) => {
    setModalOpen(true);
    setFormState({
      ...formState,
      address: routes?.address,
    });
    setAddress(routes?.address);
  };

  const handleRouteAddressModalClose = () => {
    setModalOpen(false);
    getRouteDetail();
  };

  const handleRouteOrderingModalOpen = async (number, id) => {
    setOrderModalOpen(true);
    setOrder(number);
    setRouteOrderId(id);
  };

  const handleRouteOrderingModalClose = () => {
    setOrderModalOpen(false);
    setOrder(0);
    setRouteOrderId(0);
    getRouteDetail();
  };

  const handleSaveRouteAddress = async () => {
    setLoading(true);
    const response = await patchApiWithAuth(
      ALL_ROUTES + `${routes?.id}/update_address/`,
      formState
    );
    if (response.status !== 400) {
      toast.success("Route Address Updated Successfully!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      toast.error("Backend Error!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    setModalOpen(false);
    setFormState({
      address: "",
    });
    getRouteDetail();
  };

  const handleSaveRouteOrdering = async () => {
    setLoading(true);
    const formData = {
      number: order,
    };
    const response = await patchApiWithAuth(
      ROUTE_DETAIL + `${routeOrderId}/update_order_number/`,
      formData
    );
    if (response.status !== 400) {
      toast.success("Route Address Updated Successfully!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      toast.error("Backend Error!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    setOrderModalOpen(false);
    getRouteDetail();
  };

  const handleOpenMenu = (event, order) => {
    setAnchorEl(event.currentTarget);
    setRoute(order);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {loading && <CustomLoader />}
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className="routesAction">
            <h3>Route Stops</h3>
            <div className="search-container" style={{color: 'black'}}>
              <label htmlFor="search">Search:</label>
              <input
                type="text"
                id="search"
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Order ID"
                className="search-input"
                autoComplete="off"
                style={{paddingLeft: "5px"}}
              />
            </div>
            <Link to={`/update/route/${rid}`} className="routeEditLink">
              Edit Route
            </Link>
          </div>
          <div className="routesOrderList">
            <div className="routePharmacy">
              <div
                style={{
                  position: "absolute",
                  top: "-10px", // Adjust the top position as needed
                  left: "-10px", // Adjust the left position as needed
                  width: "35px",
                  height: "35px",
                  backgroundColor: "#0B6699",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                <i className="fa fa-home" />
              </div>
              <Grid container spacing={2}>
                <Grid item xs={10} height={80}>
                  <h4>{routes?.name}</h4>
                  <div
                    className="route-order-address"
                    style={{ minWidth: "100%" }}
                  >
                    <img
                      className="route-detail-in-icon"
                      src={buildings}
                      alt="Logo"
                    />
                    <div className="route-addres-div">
                      <h3 className="route-pharmacy-addres-text">
                        {routes?.address}
                      </h3>
                      {/* <div className="address-div2">
                        <h3 className="apt-text">
                          Copay: <span className="copay-in">${order.order.totalcopay}</span>
                        </h3>
                      </div>
                      <div className="address-div2">
                        <p className="collect-text">Collected</p>
                      </div> */}
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <div>
                    <h3
                      style={{ cursor: "pointer" }}
                      onClick={handleRouteAddressModalOpen}
                    >
                      Edit
                    </h3>
                  </div>
                </Grid>
              </Grid>
            </div>
            {allRouteOrders !== undefined &&
              allRouteOrders.length > 0 &&
              allRouteOrders.map((order, i) =>
                copay && copay === "note" ? (
                  order.order.notes !== null &&
                  order.order.notes !== "" && (
                    <div className="routeOrderObject">
                      <div
                        style={{
                          position: "absolute",
                          top: "0px", // Adjust the top position as needed
                          left: "0px", // Adjust the left position as needed
                          width: "25px",
                          height: "25px",
                          backgroundColor: "#0B6699",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        {order.number}
                      </div>
                      {order.status !== "delivered" && <div
                      style={{
                        position: "absolute",
                        top: "0px", // Adjust the top position as needed
                        right: "-2px", // Adjust the left position as needed
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        fontWeight: "bold",
                        cursor: 'pointer'
                      }}
                    >
                        <ModeEditIcon
                          color="primary"
                          onClick={() =>
                            handleRouteOrderingModalOpen(
                              order.number,
                              order.id
                            )
                          }
                        />
                    </div>}
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <div className="route-order-main">
                            <div>
                              <h4>{order.pharmacy_details.name}</h4>
                              {/* <a
                                href={`/detail/order/${order.order.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <h2 className="route-order-id">
                                  Order ID - {order.order.slug}  {order.status === "delivered" ? <CheckCircleIcon style={{color: "green", fontSize: "18px"}}/> : order.status === "failed" && <CancelIcon style={{color: 'red', fontSize: "18px"}} />}
                                </h2>
                              </a> */}
                              <h3 className="cust-name">
                                <img
                                  className="route-detail-in-icon"
                                  src={userBlack}
                                  alt="new Icon"
                                />{" "}
                                {order.pharmacy_details.name}
                              </h3>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                                flexDirection: "column",
                              }}
                            >
                              <div>
                                <img
                                  className="route-detail-in-icon"
                                  src={cellphone}
                                  alt="new Icon"
                                />
                                {order.order?.totalcopay !== 0 && (
                                  <div
                                    className="routeOrderTotalCopay"
                                    style={{ width: "100%" }}
                                  >
                                    <p>Copay: </p>
                                    {/* <p>$ {order.order?.totalcopay.toFixed(2)}</p> */}
                                  </div>
                                )}
                              </div>
                              {order.order.refrigrated && (
                                <div
                                  className="route-urgent-tag refri"
                                  style={{ fontSize: 13, padding: "0px 22px" }}
                                >
                                  Refrigerated
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="route-order-customer">
                            <div className="cust-div">
                            <div className="cust-detail">
                              <div className="detail-div">
                                <img
                                  className="detail-in-icon"
                                  src={cellphone}
                                  alt="Logo"
                                />
                                <h3 className="detail-in-text">
                                  {order.pharmacy_details?.contact_no}
                                </h3>
                              </div>
                              {/* <div className="detail-div">
                                <img
                                  className="detail-in-icon"
                                  src={iphone}
                                  alt="Logo"
                                />
                                <h3 className="detail-in-text">
                                  {order.pharmacy_details?.emergency_contact}
                                </h3>
                              </div> */}
                              <div className="detail-div">
                                <img
                                  className="detail-in-icon"
                                  src={landline}
                                  alt="Logo"
                                />
                                <h3 className="detail-in-text">
                                {order.pharmacy_details?.emergency_contact}
                                </h3>
                              </div>
                            </div>
                            </div>
                          </div>
                          <div
                            className="route-order-address"
                            style={{ minWidth: "100%" }}
                          >
                            <img
                              className="route-detail-in-icon"
                              src={buildings}
                              alt="Logo"
                            />
                            <div className="addres-div">
                              <h3
                                className="addres-text"
                                style={{ display: "flex" }}
                              >
                                {order.pharmacy_details?.location}
                              </h3>
                              {/* <h3 className="apt-text" style={{textAlign: 'right'}}>
                        Apt #: <span className="apt-in">{order.order.apart}</span>
                      </h3> */}
                              {/* <div className="address-div2">
                          <h3 className="apt-text">
                            Copay: <span className="copay-in">${order.order.totalcopay}</span>
                          </h3>
                        </div>
                        <div className="address-div2">
                          <p className="collect-text">Collected</p>
                        </div> */}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <div className="route-note-div">
                            <h3 className="route-note-head">Delivery Note</h3>
                            <p className="route-note-text">
                              {order.order.notes}
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  )
                ) : (
                  <div className="routeOrderObject">
                    <div
                      style={{
                        position: "absolute",
                        top: "0px", // Adjust the top position as needed
                        left: "0px", // Adjust the left position as needed
                        width: "25px",
                        height: "25px",
                        backgroundColor: "#0B6699",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      {order.number}
                    </div>
                    {order.status !== "delivered" && <div
                      style={{
                        position: "absolute",
                        top: "0px", // Adjust the top position as needed
                        right: "-2px", // Adjust the left position as needed
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        fontWeight: "bold",
                        cursor: 'pointer'
                      }}
                    >
                        <ModeEditIcon
                          color="primary"
                          onClick={() =>
                            handleRouteOrderingModalOpen(
                              order.number,
                              order.id
                            )
                          }
                        />
                    </div>}
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <div className="route-order-main">
                          <div>
                            <h4>{order.pharmacy_details.name}</h4>
                              <a
                                href={`/detail/order/${order.order.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <h2 className="route-order-id">
                                  Order ID - {order.order.slug}  {order.status === "delivered" ? <CheckCircleIcon style={{color: "green", fontSize: "18px"}}/> : order.status === "failed" && <CancelIcon style={{color: 'red', fontSize: "18px"}} />}
                                </h2>
                              </a>
                            <h3 className="cust-name">
                              <img
                                  className="route-detail-in-icon"
                                  src={userBlack}
                                  alt="new Icon"
                                />{" "}
                              {order.pharmacy_details.name}
                            </h3>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              flexDirection: "column",
                            }}
                          >
                            <div style={{display: 'flex', alignItems: 'center', marginBottom: '5px'}}>
                              {order.order?.totalcopay !== 0 && (
                                <div
                                  className="routeOrderTotalCopay"
                                  style={{ width: "100%" }}
                                >
                                  <p>Copay: </p>
                                  {/* <p>$ {order.order?.totalcopay.toFixed(2)}</p> */}
                                </div>
                              )}
                              <img
                                className="route-detail-in-icon"
                                src={info}
                                alt="new Icon"
                                style={{marginLeft: "10px"}}
                                onClick={(e) => handleOpenMenu(e, order)}
                              />
                            </div>
                            {order.order.refrigrated && (
                              <div
                                className="route-urgent-tag refri"
                                style={{ fontSize: 13, padding: "0px 22px" }}
                              >
                                Refrigerated
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="route-order-customer">
                          <div className="cust-div">
                          <div className="cust-detail">
                              <div className="detail-div">
                                <img
                                  className="detail-in-icon"
                                  src={cellphone}
                                  alt="Logo"
                                />
                                <h3 className="detail-in-text">
                                  {order.pharmacy_details?.contact_no}
                                </h3>
                              </div>
                              {/* <div className="detail-div">
                                <img
                                  className="detail-in-icon"
                                  src={iphone}
                                  alt="Logo"
                                />
                                <h3 className="detail-in-text">
                                  {order.pharmacy_details?.emergency_contact}
                                </h3>
                              </div> */}
                              <div className="detail-div">
                                <img
                                  className="detail-in-icon"
                                  src={landline}
                                  alt="Logo"
                                />
                                <h3 className="detail-in-text">
                                {order.pharmacy_details?.emergency_contact}
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="route-order-address"
                          style={{ minWidth: "100%" }}
                        >
                          <img
                            className="route-detail-in-icon"
                            src={buildings}
                            alt="Logo"
                          />
                          <div className="addres-div">
                            <h3
                              className="addres-text"
                              style={{ display: "flex" }}
                            >
                              {order.pharmacy_details.location}
                            </h3>
                            {/* <h3 className="apt-text" style={{textAlign: 'right'}}>
                        Apt #: <span className="apt-in">{order.order.apart}</span>
                      </h3> */}
                            {/* <div className="address-div2">
                          <h3 className="apt-text">
                            Copay: <span className="copay-in">${order.order.totalcopay}</span>
                          </h3>
                        </div>
                        <div className="address-div2">
                          <p className="collect-text">Collected</p>
                        </div> */}
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className="route-note-div">
                          <h3 className="route-note-head">Delivery Note</h3>
                          <p className="route-note-text">{order.order.notes}</p>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                )
              )}
          </div>
        </Grid>
        <Grid item xs={8}>
          <div style={{ width: "100%", height: "90vh" }}>
            {isLoaded && !loadError && (
              <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={14}
                center={{ lat: latitude, lng: longitude }}
                onLoad={onLoad}
              >
                {allDirections.length > 0 ? 
                  allDirections.map((directions) => (
                    <DirectionsRenderer
                      directions={directions}
                      options={{
                        suppressMarkers: true,
                        polylineOptions: {
                          strokeColor: "#00FF00", // Green color
                          strokeOpacity: 0.5,
                          strokeWeight: 10, // Adjust the width of the line
                        },
                      }}
                    />
                  ))
                  :
                  <DirectionsRenderer
                    directions={directions}
                    options={{
                      suppressMarkers: true,
                      polylineOptions: {
                        strokeColor: "#00FF00", // Green color
                        strokeOpacity: 0.5,
                        strokeWeight: 10, // Adjust the width of the line
                      },
                    }}
                  />
                }
                

                {/* {directions && directions?.routes?.length > 0 && (
                  <Marker
                    position={{
                      lat: directions?.routes[0]?.legs[0]?.start_location.lat(),
                      lng: directions?.routes[0]?.legs[0]?.start_location.lng(),
                    }}
                    onClick={() => handleMarkerClick("driver")}
                    icon={{
                      url: "https://img.icons8.com/ios-filled/50/driver.png", // Replace with your marker icon URL
                      scaledSize: new window.google.maps.Size(32, 32), // Adjust the size as needed
                    }}
                  >
                    {selectedLegIndex === "driver" && (
                      <InfoWindow onCloseClick={handleInfoWindowClose}>
                        <div>
                          <h3>Driver</h3>
                          <p>
                            Address:{" "}
                            {directions?.routes[0]?.legs[0]?.start_address}
                          </p>
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                )} */}

                {selectedLegIndex && (
                  <div
                    style={{ width: "400px", backgroundColor: "transparent" }}
                  >
                    <InfoWindow
                      position={selectedLegIndex.getPosition()}
                      onCloseClick={onMarkerMouseClose}
                    >
                      {home ? (
                        <div>
                          <p>
                            <span style={{ fontWeight: 700 }}>Address: </span>
                            {selectRouteDetail.order.address}
                          </p>
                          {routes?.routeStatus ===
                          "In Progress" ? (
                            <p>
                              <span style={{ fontWeight: 700 }}>
                                Planned Arrival Time:{" "}
                              </span>
                              {modifyDateTime(
                                routes?.startTime,
                                0.0
                              )}
                            </p>
                          ) : (
                            <p>
                              <span style={{ fontWeight: 700 }}>
                                Planned Arrival Time:{" "}
                              </span>
                              Not Started Yet
                            </p>
                          )}
                          {/* <p><span style={{fontWeight: 700}}>Time to Next: </span>{floatToTime(selectRouteDetail.estimatedTimeCal)}</p> */}
                        </div>
                      ) : (
                        <div>
                          <p>
                            <span style={{ fontWeight: 700 }}>Order Id: </span>
                            {selectRouteDetail.order.slug}
                          </p>
                          <p>
                            <span style={{ fontWeight: 700 }}>Name: </span>
                            {selectRouteDetail.order.name}
                          </p>
                          <p>
                            <span style={{ fontWeight: 700 }}>Address: </span>
                            {selectRouteDetail.order.address}
                          </p>
                          {routes?.routeStatus ===
                          "In Progress" ? (
                            <p>
                              <span style={{ fontWeight: 700 }}>
                                Planned Arrival Time:{" "}
                              </span>
                              {modifyDateTime(
                                routes?.startTime,
                                selectRouteDetail.estimatedTimeCal
                              )}
                            </p>
                          ) : routes?.routeStatus ===
                          "Done" ?
                          (
                            <>
                              <p>
                                <span style={{ fontWeight: 700 }}>
                                  Planned Arrival Time:{" "}
                                </span>
                                {modifyDateTime(
                                  routes?.startTime,
                                  selectRouteDetail.estimatedTimeCal
                                )}

                              </p>
                              <p>
                                <span style={{ fontWeight: 700 }}>
                                  Delivery Time:{" "}
                                </span>
                                {moment(selectRouteDetail.order.deliverAt).format("hh:mm A")}
                              </p>
                            </>
                          )
                          : (
                            <p>
                              <span style={{ fontWeight: 700 }}>
                                Planned Arrival Time:{" "}
                              </span>
                              {floatToTime(selectRouteDetail.estimatedTimeCal)}{" "}
                              (after start)
                            </p>
                          )
                          }
                          {/* <p><span style={{fontWeight: 700}}>Time to Next: </span>{floatToTime(selectRouteDetail.estimatedTimeCal)}</p> */}
                        </div>
                      )}
                    </InfoWindow>
                  </div>
                )}

                {/* Add markers with popups */}
                {/* {directions &&
                  directions?.routes?.length > 0 &&
                  directions?.routes[0]?.legs?.map(
                    (leg, legIndex) =>
                      legIndex !== routeOrders.length && (
                        <Marker
                          key={legIndex}
                          position={{
                            lat: leg.end_location.lat(),
                            lng: leg.end_location.lng(),
                          }}
                          onClick={() => handleMarkerClick(legIndex)}
                          icon={{
                            url:
                              legIndex + 1 === routeOrders.length
                                ? `${getMarkerIconWithNumber("E")}`
                                : `${getMarkerIconWithNumber(legIndex + 1)}`, // Replace with your marker icon URL
                            scaledSize: new window.google.maps.Size(32, 32), // Adjust the size as needed
                          }}
                        >
                          {selectedLegIndex === legIndex && (
                            <InfoWindow onCloseClick={handleInfoWindowClose}>
                              <div>
                                <h3>Leg {legIndex + 1}</h3>
                                <p>Estimated time: {leg.duration.text}</p>
                                <p>Address: {leg.end_address}</p>
                              </div>
                            </InfoWindow>
                          )}
                        </Marker>
                      )
                  )} */}
                {/* {directions && directions?.routes?.length > 0 && (
                  <>
                  {directions?.routes[0]?.legs[0]?.steps?.map((step, index) => (
                    <Marker
                      key={index}
                      position={{
                        lat: step.end_location.lat(),
                        lng: step.end_location.lng(),
                      }}
                      icon={{
                            path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW, // Arrow symbol
                            scale: 4, // Size of the arrow
                            fillColor: 'blue', // Arrow color
                            fillOpacity: 1.0,
                            strokeWeight: 0, // No border
                          }}
                    />
                  ))}
                  </>
                )} */}
              </GoogleMap>
            )}
            {loadError && <div>Error loading Google Maps</div>}
          </div>
        </Grid>
      </Grid>
      <div className="RouteMapToggles">
        <div style={{ display: "flex", gap: 5 }} onClick={() => reOptimizedRoute()}>
          <label className="label">Re-Optimize Route</label>
          <img src={open_route} alt="open_route" style={{ width: 12 }} />
        </div>

        <br />

        {/* <label className="label">
          Start & End At Same Location
          <input
            type="checkbox"
            checked={startToEnd}
            autoComplete="off"
            style={{ marginLeft: "5px" }}
            onChange={() => !startToEnd && handleCheckboxChange("startToEnd")}
          />
        </label>
        <br />
        <label className="label">
          End At Any Location
          <input
            type="checkbox"
            checked={endAnywhere}
            autoComplete="off"
            style={{ marginLeft: "5px" }}
            onChange={() => !endAnywhere && handleCheckboxChange("endAnywhere")}
          />
        </label>
        <br />
        <label className="label">
          End At Specific Stop (Choose Stop #)
          <input
            type="checkbox"
            checked={endSpecific}
            autoComplete="off"
            style={{ marginLeft: "5px" }}
            onChange={() => !endSpecific && handleCheckboxChange("endSpecific")}
          />
        </label> */}
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            backgroundColor: 'black',
            color: 'white',
            padding: '0 10px',
            minWidth: '300px'
          },
        }}
      >
        <h2 style={{textDecoration: "underline", fontWeight: "700"}}>Detail</h2>
        <h2 style={{textDecoration: "underline", fontWeight: "400"}}>Order ID: {route?.order?.slug}</h2>
        <div style={{fontWeight: "500", display: 'flex', justifyContent: "space-between"}}>
          <h2>ETA</h2>
          <h2>{
            routes?.startTime !== null ?
            modifyDateTime(
              routes?.startTime,
              route?.estimatedTimeCal
            )
            :
            `${floatToTime(route?.estimatedTimeCal)} after start`
            }
          </h2>
        </div>
        <div style={{fontWeight: "500", display: 'flex', justifyContent: "space-between"}}>
          <h2>Distance</h2>
          <h2>{(parseFloat(route?.distanceCal) * 0.621371).toFixed(2)} mi</h2>
        </div>
        <div style={{fontWeight: "500", display: 'flex', justifyContent: "space-between"}}>
          <h2>Started At</h2>
          <h2>{routes?.startTime !== null ? moment(routes?.startTime).format('hh:mm A'): "Not Started"}</h2>
        </div>
        <div style={{fontWeight: "500", display: 'flex', justifyContent: "space-between"}}>
          <h2>Geo Location</h2>
          <h2>{parseFloat(route?.order?.latitude).toFixed(7)}, {parseFloat(route?.order?.longitude).toFixed(7)}</h2>
        </div>
        <div style={{fontWeight: "500", display: 'flex', justifyContent: "space-between"}}>
          <h2>{route?.status === 'failed' ? "Failed" : "Delivered At"}</h2>
          <h2>{route?.status === 'delivered' ? moment(route?.order?.deliverAt).format('hh:mm A'): route?.status === 'failed' ? moment(route?.updated_at).format('hh:mm A') : "Not Delivered"}</h2>
        </div>
        {route?.status === "delivered" &&
        <div style={{fontWeight: "500", display: 'flex', justifyContent: "space-between"}}>
          
          {route?.order?.driver_name !== "" ? 
            <>
              <h2>Copay Collected By: </h2>
              <h2>{route?.order?.driver_name}</h2>
            </> : route?.driverComment !== "" &&
            <>
              <h2>Copay Collected By: </h2>
              <h2>{route?.driverComment}</h2>
            </>
          }
          {route?.order?.receiverName !== "" ? 
          <>
            <h2>Copay Received By: </h2>
            <h2>{route?.order?.receiverName}</h2>
          </> : route?.receiverName !== "" && 
          <>
            <h2>Copay Received By: </h2>
            <h2>{route?.receiverName}</h2>
          </>}
          {route?.order?.waivedBy !== "" ? 
          <>
            <h2>Copay Waived By: </h2>
            <h2>{route?.order?.waivedBy}</h2>
          </> : route?.waivedBy !== "" && 
          <>
            <h2>Copay Waived By: </h2>
            <h2>{route?.waivedBy}</h2>
          </>}
        </div>}
        {route?.status === 'delivered' &&
        <div style={{fontWeight: "500", display: 'flex', justifyContent: "space-between"}}>
          <h2>Signature</h2>
          <h2>{route?.eSignature? "Done" : "False"}</h2>
        </div>
        }
        <div style={{fontWeight: "500", display: 'flex', justifyContent: "space-between"}}>
          <h2>Delivery Images</h2>
          <h2>{route?.order?.ordermaster_delivery?.length}</h2>
        </div>
        {route?.order?.failed && route.order.failed.length > 0 && (
  <div style={{ fontWeight: "500", display: 'flex', justifyContent: "space-between" }}>
    <h2>Failed reason</h2>
    <h2>{route.order.failed[route.order.failed.length - 1]?.comment}</h2>
  </div>
)}

      </Menu>
      <Dialog open={modalOpen} onClose={handleRouteAddressModalClose}>
        <DialogTitle>Update Route Address</DialogTitle>
        <DialogContent>
          <label className="pharmacy-field-label">Address</label>
          {/* <input
            type="text"
            className="modal-field"
            name="address"
            placeholder="Address"
            style={{marginBottom: '10px'}}
            value={formState.address}
            onChange={handleInputChange}
          /> */}
          <Autocomplete
            style={{ zIndex: 1500 }}
            apiKey={GOOGLE_MAPS_API_KEY}
            onPlaceSelected={handlePlaceSelect}
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                type="text"
                name="address"
                id="address"
                className="order-field"
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
            )}
            // style={{
            //   border: '0px',
            //   width: '100%',
            //   borderRadius: '5px',
            //   font: 'inherit',
            //   letterSpacing: 'inherit',
            //   color: 'currentColor',
            //   boxSizing: 'content-box',
            //   background: 'none',
            //   height: '1.4375em',
            //   margin: 0,
            //   display: 'block',
            //   minWidth: 0,
            //   animationName: 'mui-auto-fill-cancel',
            //   animationDuration: '10ms',
            //   padding: '10.5px 14px 10.5px 12px'
            // }}
            className="order-field"
            inputProps={{
              style: {
                "&:hover": {
                  borderColor: "#69c0ff",
                },
                "&:active": {
                  borderColor: "#69c0ff",
                },
                "&:focus": {
                  borderColor: "#69c0ff",
                },
              },
            }}
            options={options}
          />
          <Button
            onClick={handleSaveRouteAddress}
            variant="contained"
            style={{ marginRight: "10px" }}
            color="primary"
          >
            Save
          </Button>
          <Button
            onClick={handleRouteAddressModalClose}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={orderModalOpen} onClose={handleRouteOrderingModalClose}>
        <DialogTitle>Update Route Ordering</DialogTitle>
        <DialogContent>
          <label className="pharmacy-field-label">Order</label>
          <select
            className="modal-field"
            name="number"
            placeholder="Order"
            style={{ marginBottom: "10px" }}
            value={order}
            onChange={(e) => setOrder(e.target.value)}
          >
            <option value="">Select Order</option>
            {/* Create options based on the order length */}
            {Array.from({ length: routeOrders.length }, (_, index) => (
              <option key={index + 1} value={index + 1}>
                {index + 1}
              </option>
            ))}
          </select>
          <Button
            onClick={handleSaveRouteOrdering}
            variant="contained"
            style={{ marginRight: "10px" }}
            color="primary"
          >
            Save
          </Button>
          <Button
            onClick={handleRouteOrderingModalClose}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default RouteReturnDetail;
