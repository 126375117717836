import { Avatar } from "@mui/material";
import {
  userBlack,
} from "../../../../assests";

import { Badge, IconButton } from "@mui/material";

export default function SMSCard({ sms, unreadCounts }) {
  return (
    <div className="TicketCard rounded-sm border border-[#D9D9D9] p-md">
      <div className="grid grid-cols-12 gap-4">
        <div className="flex flex-col justify-around gap-md md:col-span-3 lg:col-span-8">
          <div className="flex justify-between">
            <div className="flex flex-col gap-[2px]">
              <div className="flex gap-sm items-center">
                <Avatar>U</Avatar>
                <Badge badgeContent={unreadCounts} color="error">
                  <p className="text-[20px] font-[700]">{sms[0]?.order?.name ? sms[0]?.order?.name : "Unknown"}</p>
                </Badge>
              </div>
              
              <p className="text-[20px] font-[700] underline ">
                Order ID {sms[0]?.order?.slug ? sms[0]?.order?.slug : "N/A"}
              </p>
              <div className="flex gap-sm items-center">
                <img width="20px" height="auto" src={userBlack} alt="user" />
                <p className="text-[15px] font-[700]">Agent {sms[0]?.agent_name}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-[2px] items-end justify-center md:col-span-2 lg:col-span-4">
          <div className="text-red font-[700] rounded-xs py-xs px-md">
            <div className="flex items-center gap-sm">
              <p className="text-sm font-[700]" style={{ color: 'red' }}>
                {sms[0]?.type}
              </p>
            </div>
          </div>
          <div className="text-[14px] font-[400]">
            <strong style={{ marginRight: '0.5rem' }}>Created At :</strong>
            {sms[0]?.created_at?.split("T")[0]} {sms[0]?.created_at?.split("T")[1]?.split(".")[0]}
          </div>
          <p className="text-[14px] font-[400]">
            Client Number : {sms[sms?.length - 1]?.clientNumber}
            <br />
            Own Number : {sms[sms?.length - 1]?.callingNumber}
          </p>
          <p className="text-[14px] font-[400] flex gap-sm">
            {/* <BiCheckDouble color="#2757FF" /> Recieved */}
          </p>
        </div>
      </div>
    </div>
  );
}
