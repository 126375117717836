import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import InputMask from "react-input-mask";
import { useNavigate, useParams } from "react-router";
import { ALL_PHARMACY_LIST, ALL_USERS } from "../../../utils/apiUrls";
import { getApiWithAuth, postApiWithAuth } from "../../../utils/api";

import { toast } from "react-toastify";
import "./addPharmacy.css";

function AddPharmacyEmployee() {
  const navigate = useNavigate();
  const { pid } = useParams();

  const [backendimg, setbackendimg] = useState(
    "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
  );

  let currentDate = new Date();
  let tenYearsAgo = new Date(
    currentDate.setFullYear(currentDate.getFullYear() - 10)
  );

  const [Fronts, setFronts] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [pharmacyDetail, setPharmacyDetail] = useState({});
  const [personalDetail, setPersonalDetail] = useState({
    firstName: pharmacyDetail?.name,
    lastName: "Employee",
    address: "",
    dateOfBirth: tenYearsAgo.toISOString().split("T")[0],
    cellNumber: "",
    isActive: true,
    logo: "",
    email: "",
    type: "PharmacyEmployee",
    username: "",
    password: "",
    show_report: pharmacyDetail?.show_report,
    show_team: pharmacyDetail?.show_team,
  });

  const handlePersonalInputChange = (event) => {
    const { name, value } = event.target;
    console.log("namevalue", name, value);
    setPersonalDetail({
      ...personalDetail,
      [name]: value,
    });
  };
  const handlePersonalInputChangeChecked = (event) => {
    const { name, checked } = event.target;
    console.log("namevalue", name, checked);
    setPersonalDetail((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const getPharmacy = async () => {
    const response = await getApiWithAuth(ALL_PHARMACY_LIST + `/${pid}`);
    if (response.data !== undefined) {
      setPharmacyDetail(response.data.data);
    } else {
      console.log("Error");
    }
  };

  useEffect(() => {
    getPharmacy();
  }, []);

  useEffect(() => {
    const pharmacyId = localStorage.getItem("pharmacyId");
    if (pid !== undefined) {
      if (parseInt(pid) !== parseInt(pharmacyId)) {
        navigate("/404");
      }
    }
  }, [pid]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("personalDetail", personalDetail);
    const updatedPersonalDetail = new FormData();
    updatedPersonalDetail.append("first_name", pharmacyDetail.name);
    updatedPersonalDetail.append("last_name", personalDetail.lastName);
    updatedPersonalDetail.append("address", pharmacyDetail.location);
    updatedPersonalDetail.append("contact", personalDetail.cellNumber);
    updatedPersonalDetail.append("email", personalDetail.email);
    updatedPersonalDetail.append("username", personalDetail.username);
    updatedPersonalDetail.append("show_report", personalDetail.show_report);
    updatedPersonalDetail.append("show_team", personalDetail.show_team);
    updatedPersonalDetail.append("pharmacy_id", pid);

    updatedPersonalDetail.append("type", personalDetail.type);
    updatedPersonalDetail.append("password", personalDetail.password);
    updatedPersonalDetail.append("profile_pic", personalDetail.logo);
    updatedPersonalDetail.append("is_active", personalDetail.isActive);
    updatedPersonalDetail.append("action", "update_details");

    const responsePersonal = await postApiWithAuth(
      ALL_USERS,
      updatedPersonalDetail
    );

    if (responsePersonal.data.data !== undefined) {
      toast.success("Account created successfully", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      navigate(`/pharmacy/${pid}/list/employee`);
    } else {
      toast.error(responsePersonal.data.password[0], {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleEditClickk = () => {
    // Trigger the hidden file input when EditIcon is clicked
    document.getElementById("imageInputt").click();
  };

  const handleImageUpload3 = (event) => {
    // const file = event.target.files[0];
    // setFrontImage(file);
    const fileObject = event.target.files[0];

    setPersonalDetail({
      ...personalDetail,
      logo: fileObject,
    });
    // Extract the file data from the object
    const { name, size, type, lastModified } = fileObject;

    // Create a Blob from the file data
    const fileBlob = new Blob([fileObject], { type });

    // Create a File instance
    const file = new File([fileBlob], name, { lastModified });

    if (file) {
      const reader = new FileReader();
      // Read the contents of the image file as a data URL
      reader.onloadend = () => {
        // Set the image URL in the state
        setFronts(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <Grid container className="form-grid">
        <Grid item xs={3}>
          <div className="form-sec-head-facility">Account Credentials</div>
        </Grid>{" "}
        <Grid item className="drive-grid ml-5" xs={7}></Grid>
        <Grid item className="drive-grid " xs={2}>
          {/* Hidden file input triggered by the EditIcon click */}
          <input
            autoComplete="off"
            type="file"
            id="imageInputt"
            style={{ display: "none" }}
            accept="image/*"
            onChange={handleImageUpload3}
          />
          {Fronts ? (
            <img
              src={Fronts}
              style={{ borderRadius: "100%", height: "50px", width: "50px" }}
              className="id-image"
              alt="Image 1"
            />
          ) : (
            <img
              src={backendimg}
              style={{ borderRadius: "100%", height: "50px", width: "50px" }}
              className="id-image"
              alt="Image 1"
            />
          )}{" "}
          <div className="img-icon" onClick={handleEditClickk}>
            {/* Render your EditIcon component here */}
            <EditIcon />
          </div>
        </Grid>
        <Grid item className="drive-grid "></Grid>
      </Grid>
      <Grid container className="form-grid" spacing={2}>
        {/* First Row */}

        <Grid item className="drive-grid" xs={3}>
          <label className="driver-field-label">Type</label>
          <select
            className="driver-field"
            name="type"
            value={personalDetail.type || ""} // Added a fallback to empty string to handle null or undefined
            onChange={handlePersonalInputChange}
          >
            <option value="PharmacyOwner">PharmacyOwner</option>
            <option value="PharmacyEmployee">PharmacyEmployee</option>
          </select>
        </Grid>

        <Grid item className="drive-grid" xs={3}>
          <label className="driver-field-label">Pharmacy Name</label>
          <input
            autoComplete="off"
            type="text"
            className="facility-field"
            name="type"
            placeholder="Pharmacy Type"
            value={pharmacyDetail.name}
            disabled
          />
        </Grid>
        <Grid item className="drive-grid" xs={6}>
          <label className="driver-field-label">Pharmacy Address</label>
          <input
            autoComplete="off"
            type="text"
            className="facility-field"
            name="type"
            placeholder="Pharmacy Type"
            value={pharmacyDetail.location}
            disabled
          />
        </Grid>

        {/* <Grid item className="drive-grid" xs={3}>
          <label className="facility-field-label">First Name</label>
          <input
            autoComplete="off"
            type="text"
            className="facility-field"
            name="firstName"
            placeholder="First Name"
            value={personalDetail.firstName}
            onChange={handlePersonalInputChange}
          />
        </Grid>
        <Grid item className="drive-grid" xs={3}>
          <label className="facility-field-label">Last Name</label>
          <input
            autoComplete="off"
            typtype="text"
            className="facility-field"
            name="lastName"
            placeholder="Last Name"
            value={personalDetail.lastName}
            onChange={handlePersonalInputChange}
          />
        </Grid> */}
        <Grid item className="drive-grid" xs={3}>
          <label className="facility-field-label">User Name</label>
          <input
            autoComplete="off"
            type="text"
            className="facility-field"
            name="username"
            placeholder="User Name"
            value={personalDetail.username}
            onChange={handlePersonalInputChange}
          />
        </Grid>
        <Grid item className="drive-grid" xs={3}>
          <label className="facility-field-label">Phone Number</label>
          <InputMask
            mask="(999) 999-9999" // Adjust this mask as needed
            value={personalDetail.cellNumber}
            onChange={handlePersonalInputChange}
          >
            {() => (
              <input
                autoComplete="off"
                type="text" // Change to text to accommodate the mask
                className="facility-field"
                name="cellNumber"
                placeholder="Cell Number"
              />
            )}
          </InputMask>
        </Grid>
        <Grid item className="drive-grid" xs={3}>
          <label className="facility-field-label">Email</label>
          <input
            autoComplete="off"
            type="email"
            className="facility-field"
            name="email"
            placeholder="Email"
            value={personalDetail.email}
            onChange={handlePersonalInputChange}
          />
        </Grid>
        <Grid item className="drive-grid" xs={3} md={3}>
          <label className="facility-field-label">Password</label>
          <div className="password-input-container">
            <input
              autoComplete="off"
              type={showPassword ? "text" : "password"}
              className="facility-field"
              name="password"
              style={{ borderRadius: "13px" }}
              placeholder="Password"
              value={personalDetail.password}
              onChange={handlePersonalInputChange}
            />
            <button
              className="show-hide-password"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
            </button>
          </div>
        </Grid>
        <Grid item className="drive-grid" xs={3}>
          <label className="facility-field-label">Show Team</label>
          <input
            autoComplete="off"
            type="checkbox"
            className="facility-field small-checkbox"
            name="show_team"
            checked={personalDetail.show_team}
            onChange={handlePersonalInputChangeChecked}
          />
        </Grid>
        <Grid item className="drive-grid" xs={3}>
          <label className="facility-field-label">Show Report</label>
          <input
            autoComplete="off"
            type="checkbox"
            className="facility-field small-checkbox"
            name="show_report"
            checked={personalDetail.show_report}
            onChange={handlePersonalInputChangeChecked}
          />
        </Grid>

        {/* <Grid item className="drive-grid" xs={6}>
          <label className="facility-field-label">Address</label>
          <input
            autoComplete="off"
            type="text"
            className="facility-field"
            name="address"
            placeholder="Address"
            value={personalDetail.address}
            onChange={handlePersonalInputChange}
          />
        </Grid> */}

        {/* Second Row */}
        {/* <Grid item className="drive-grid" xs={3}>
          <label className="facility-field-label">Date of Birth</label>
          <input
            autoComplete="off"
            type="date"
            className="facility-field"
            name="dateOfBirth"
            placeholder="YYYY/MM/DD"
            value={personalDetail.dateOfBirth}
            onChange={handlePersonalInputChange}
          />
        </Grid> */}
        {/* <Grid item className="drive-grid" xs={3}>
          <label className="facility-field-label">Phone Number</label>
          <input
            autoComplete="off"
            type="number"
            className="facility-field"
            name="phoneNumber"
            placeholder="Phone Number"
            value={personalDetail.phoneNumber}
            onChange={handlePersonalInputChange}
          />
        </Grid> */}
      </Grid>

      <div className="facility-btn">
        {/* <button type="button" className="del-facility">Delete Account</button> */}
        <button type="submit" onClick={handleSubmit} className="save-facility">
          Save Details
        </button>
      </div>
    </div>
  );
}

export default AddPharmacyEmployee;
